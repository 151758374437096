import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

const InputBPMPI = (props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <input type="hidden" {...props} />;
};

// Doc: https://braspag.github.io/manual/integracao-javascript#passo-3-mapeamento-de-classes

// DADOS DE PARAMETRIZAÇÃO
export const AcessTokenBPMPI = ({ value }) => {
  return (
    <InputBPMPI
      name="accessToken"
      className="bpmpi_accesstoken"
      value={value}
    />
  );
};

export const AuthBPMPI = ({ value }) => {
  return <InputBPMPI name="authEnabled" className="bpmpi_auth" value={value} />;
};

// DADOS DE PEDIDO

export const OrderNumberBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_ordernumber" value={value} />;
};

export const CurrencyBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_currency" value={value} />;
};

export const TotalAmountBPMPI = ({ value }) => {
  return (
    <InputBPMPI name="amount" className="bpmpi_totalamount" value={value} />
  );
};

export const InstallmentsBPMPI = ({ value }) => {
  return (
    <InputBPMPI
      name="installments"
      className="bpmpi_installments"
      value={value}
    />
  );
};

export const PaymentMethodBPMPI = ({ value }) => {
  return (
    <InputBPMPI
      name="paymentMethod"
      className="bpmpi_paymentmethod"
      value={value}
    />
  );
};

export const CardNumberBPMPI = ({ value }) => {
  return (
    <InputBPMPI name="cardNumber" className="bpmpi_cardnumber" value={value} />
  );
};

export const CardExpMonthBPMPI = ({ value }) => {
  return (
    <InputBPMPI
      name="expMonth"
      className="bpmpi_cardexpirationmonth"
      value={value}
    />
  );
};

export const CardExpYearBPMPI = ({ value }) => {
  return (
    <InputBPMPI
      name="expYear"
      className="bpmpi_cardexpirationyear"
      value={value}
    />
  );
};

// DADOS DAS CARACTERÍSTICAS DO PEDIDO

export const OrderRecorrenciaBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_order_recurrence" value={value} />;
};

export const OrderProductCodeBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_order_productcode" value={value} />;
};

export const MerchantUrlBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_merchant_url" value={value} />;
};

export const OrderModoTransacaoBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_transaction_mode" value={value} />;
};

// DADOS DE ENDEREÇO DE COBRANÇA

export const EndCobrancaNomeContatoBPMPI = ({ value }) => {
  return (
    <>
      {/* class descrita na documentação */}
      <InputBPMPI className="bpmpi_billto_contactname" value={value} />
      {/* class descrita no exemplo da doc. */}
      <InputBPMPI className="bpmpi_billto_name" value={value} />
    </>
  );
};

export const EndCobrancaTelefoneBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_billto_phonenumber" value={value} />;
};

export const EndCobrancaEmailBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_billto_email" value={value} />;
};

export const EndCobrancaRuaeNumeroBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_billto_street1" value={value} />;
};

export const EndCobrancaComplementoeBairroBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_billto_street2" value={value} />;
};

export const EndCobrancaCidadeBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_billto_city" value={value} />;
};

export const EndCobrancaEstadoBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_billto_state" value={value} />;
};

export const EndCobrancaCEPBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_billto_zipcode" value={value} />;
};

export const EndCobrancaPaisBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_billto_country" value={value} />;
};

// DADOS DE ENDEREÇO DE ENTREGA

export const EndEntregaIgualCobrancaBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_shipto_sameasbillto" value={value} />;
};

// DADOS DO CARRINHO DE COMPRAS
export const ItemCarrinhoDescricaoBPMPI = ({ index, value }) => {
  return (
    <InputBPMPI className={`bpmpi_cart_${index}_description`} value={value} />
  );
};

export const ItemCarrinhoNomeBPMPI = ({ index, value }) => {
  return <InputBPMPI className={`bpmpi_cart_${index}_name`} value={value} />;
};

export const ItemCarrinhoSKUBPMPI = ({ index, value }) => {
  return <InputBPMPI className={`bpmpi_cart_${index}_sku`} value={value} />;
};

export const ItemCarrinhoQuantidadeBPMPI = ({ index, value }) => {
  return (
    <InputBPMPI className={`bpmpi_cart_${index}_quantity`} value={value} />
  );
};

export const ItemCarrinhoPrecoUnitBPMPI = ({ index, value }) => {
  return (
    <InputBPMPI className={`bpmpi_cart_${index}_unitprice`} value={value} />
  );
};

// DADOS DO USUÁRIO

export const UsuarioConvidadoBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_useraccount_guest" value={value} />;
};

export const UsuarioDtCriadoBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_useraccount_createddate" value={value} />;
};

export const UsuarioDtAtualizadoBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_useraccount_changeddate" value={value} />;
};

export const UsuarioMetodoAutenticacaoBPMPI = ({ value }) => {
  return (
    <InputBPMPI
      className="bpmpi_useraccount_authenticationmethod"
      value={value}
    />
  );
};

export const UsuarioProtocoloAutenticacaoBPMPI = ({ value }) => {
  return (
    <InputBPMPI
      className="bpmpi_useraccount_authenticationprotocol"
      value={value}
    />
  );
};

export const UsuarioTimestampLoginBPMPI = ({ value }) => {
  return (
    <InputBPMPI
      className="bpmpi_useraccount_authenticationtimestamp"
      value={value}
    />
  );
};

// DADOS DO DISPOSITIVO

export const DispositivoIPBPMPI = ({ value }) => {
  return <InputBPMPI className="bpmpi_device_ipaddress" value={value} />;
};

AcessTokenBPMPI.propTypes = { value: PropTypes.string };
AuthBPMPI.propTypes = { value: PropTypes.string };
OrderNumberBPMPI.propTypes = { value: PropTypes.string };
CurrencyBPMPI.propTypes = { value: PropTypes.string };
TotalAmountBPMPI.propTypes = { value: PropTypes.string };
InstallmentsBPMPI.propTypes = { value: PropTypes.string };
PaymentMethodBPMPI.propTypes = { value: PropTypes.string };
CardNumberBPMPI.propTypes = { value: PropTypes.string };
CardExpMonthBPMPI.propTypes = { value: PropTypes.string };
CardExpYearBPMPI.propTypes = { value: PropTypes.string };
OrderProductCodeBPMPI.propTypes = { value: PropTypes.string };
MerchantUrlBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaNomeContatoBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaTelefoneBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaEmailBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaRuaeNumeroBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaComplementoeBairroBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaCidadeBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaEstadoBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaCEPBPMPI.propTypes = { value: PropTypes.string };
EndCobrancaPaisBPMPI.propTypes = { value: PropTypes.string };

AcessTokenBPMPI.defaultProps = { value: '' };
AuthBPMPI.defaultProps = { value: '' };
OrderNumberBPMPI.defaultProps = { value: '' };
CurrencyBPMPI.defaultProps = { value: '' };
TotalAmountBPMPI.defaultProps = { value: '' };
InstallmentsBPMPI.defaultProps = { value: '' };
PaymentMethodBPMPI.defaultProps = { value: '' };
CardNumberBPMPI.defaultProps = { value: '' };
CardExpMonthBPMPI.defaultProps = { value: '' };
CardExpYearBPMPI.defaultProps = { value: '' };
OrderProductCodeBPMPI.defaultProps = { value: '' };
MerchantUrlBPMPI.defaultProps = { value: '' };
EndCobrancaNomeContatoBPMPI.defaultProps = { value: '' };
EndCobrancaTelefoneBPMPI.defaultProps = { value: '' };
EndCobrancaEmailBPMPI.defaultProps = { value: '' };
EndCobrancaRuaeNumeroBPMPI.defaultProps = { value: '' };
EndCobrancaComplementoeBairroBPMPI.defaultProps = { value: '' };
EndCobrancaCidadeBPMPI.defaultProps = { value: '' };
EndCobrancaEstadoBPMPI.defaultProps = { value: '' };
EndCobrancaCEPBPMPI.defaultProps = { value: '' };
EndCobrancaPaisBPMPI.defaultProps = { value: '' };

export default InputBPMPI;
