import React from 'react';
import { Col, Form, Input, Row } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import BandeiraCartaoField from '../BandeiraCartaoField';
import NumeroCartaoField from '../NumeroCartaoField';
import ValidadeCartaoField from '../ValidadeCartaoField';

const propTypes = {
  /* eslint-disable-next-line react/forbid-prop-types */
  form: PropTypes.object.isRequired
};

const CartaoForm = ({ form }) => {
  return (
    <Row gutter={16}>
      <Col xs={24} md={6}>
        <NumeroCartaoField form={form} />
      </Col>

      <Col xs={24} md={6}>
        <BandeiraCartaoField />
      </Col>

      <Col xs={24} md={12}>
        <ValidadeCartaoField />
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label="Nome do titular"
          name={['cartao', 'titular']}
          rules={[{ message: 'Campo obrigatório.', required: true }]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label="CPF ou CNPJ do titular"
          name={['cartao', 'cnpjoucpf']}
          rules={[
            {
              message: 'Campo obrigatório.',
              required: true
            },
            {
              message: 'O campo precisa ter 11 ou 14 dígitos.',
              pattern: /^(\d{11}|\d{14})$/
            }
          ]}
        >
          <Input maxLength={14} inputmode="numeric" pattern="[0-9]*" />
        </Form.Item>
      </Col>
    </Row>
  );
};

CartaoForm.propTypes = propTypes;

export default CartaoForm;
