import { useEffect, useState } from 'react';

import { apiDelivery } from 'services/api';

const useCupomFiscal = (idpedido) => {
  const [cupomFiscal, setCupomFiscal] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiDelivery
      .get(`/controle/pedido/${idpedido}/cupom/`)
      .then((response) => response.data)
      .then(({ html }) => {
        setCupomFiscal(html);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [cupomFiscal, loading, error];
};

export default useCupomFiscal;
