import React, { useState } from 'react';
import { Col, Form, InputNumber, Row, Select } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { formatarParaReal } from './utils';

const { Option } = Select;

const propTypes = {
  valorTotal: PropTypes.number.isRequired
};

const defaultProps = {};

const TROCO_NAO = 0;
const TROCO_SIM = 1;

const TrocoField = ({ valorTotal }) => {
  const [isTrocoSolicitado, setTrocoSolicitado] = useState(false);

  const validarValorTroco = (_, valorTroco) => {
    if (valorTotal > valorTroco) {
      /* eslint-disable-next-line prefer-promise-reject-errors */
      return Promise.reject(
        `Adicione um valor de troco maior que ${formatarParaReal(valorTotal)}.`
      );
    }
    return Promise.resolve();
  };

  const verificarSeTrocoIsSolicitado = (opcaoPorTroco) => {
    setTrocoSolicitado(opcaoPorTroco === TROCO_SIM);
  };

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <Form.Item label="Troco">
          <Select
            defaultValue={TROCO_NAO}
            onChange={verificarSeTrocoIsSolicitado}
          >
            <Option value={TROCO_NAO}>Não</Option>
            <Option value={TROCO_SIM}>Sim</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        {isTrocoSolicitado ? (
          <Form.Item
            label="Valor do pagamento em dinheiro"
            name="troco_para"
            rules={[
              { message: 'Campo obrigatório.', required: true },
              { validator: validarValorTroco }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        ) : (
          <Form.Item label="Valor do pagamento em dinheiro">
            <InputNumber disabled style={{ width: '100%' }} />
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};

TrocoField.propTypes = propTypes;
TrocoField.defaultProps = defaultProps;

export default TrocoField;
