import './Acompanhe.css';

import moment from 'moment';

import React, { useEffect, useState } from 'react';
import { Spin, Divider } from 'antd';
import { useParams } from 'react-router-dom';

import BotaoContinuarComprando from 'components/Common/BotaoContinuarComprando';

import usePedido from 'hooks/usePedido';

import StatusPedido, { ordernarPorDataHoraDec } from 'utils/Pedido';

import AcompanheContatoEstabelecimento from './AcompanheContatoEstabelecimento';
import AcompanheDetalhe from './AcompanheDetalhe';
import AcompanheTimeline from './AcompanheTimeline';
import AvisoEsperandoPagamentoDebito from './AvisoEsperandoPagamentoDebito';
import AvisoEsperandoPagamentoPix from './AvisoEsperandoPagamentoPix';
import CupomFiscal from './CupomFiscal';

let interval = null;
const INTERVALO_POLLING = 15 * 1000; // em mseg

function Endereco({ endereco, tipo }) {
  if (!endereco) {
    return null;
  }

  return (
    <>
      <h3>
        {tipo === 'togo' && 'Retirar no estabelecimento'}
        {tipo === 'delivery' && 'Endereço de entrega'}
      </h3>
      <p>
        {`${endereco.rua}, ${endereco.numero}, ${endereco.bairro}`}
        {endereco.complemento && ` - ${endereco.complemento}`}
      </p>
    </>
  );
}

export default function PedidoAcompanhe() {
  const { id } = useParams();
  const [
    dadosPedido,
    dadosPedidoLoading,
    error,
    getPedido,
    setDadosPedidoLoading
  ] = usePedido(id);
  const [endereco, setEndereco] = useState(null);
  const [mostrarMaisDetalhes, setMostrarMaisDetalhes] = useState(false);
  const [atualizado, setAtualizado] = useState(false);
  const [statusPedidoRecente, setStatusPedidoRecente] = useState();

  const iniciarPollingPedido = () => {
    pararPollingPedido();
    getPedido(id);
  };
  const pararPollingPedido = () => {
    interval && clearInterval(interval);
  };

  useEffect(() => {
    pararPollingPedido();
    if (dadosPedido) {
      setEndereco(dadosPedido.pedido.cliente.endereco);
      dadosPedido.historico.sort(ordernarPorDataHoraDec);
      const ultimoHistoricoPedido =
        dadosPedido.historico[dadosPedido.historico.length - 1];
      const status = ultimoHistoricoPedido.status;
      setStatusPedidoRecente(status);
      if (
        status !== StatusPedido.Concluido &&
        status !== StatusPedido.Cancelado
      ) {
        setAtualizado(true);
        interval = setInterval(iniciarPollingPedido, INTERVALO_POLLING);
      }
    }
    return pararPollingPedido;
  }, [dadosPedido]);

  useEffect(() => {
    setDadosPedidoLoading(true);
    iniciarPollingPedido();
  }, []);

  const pedidoPgtoPendente =
    statusPedidoRecente === StatusPedido.PgtoPendente &&
    dadosPedido?.transacoes[0]?.url;

  const pedidoPgtoPendentePix =
    statusPedidoRecente === StatusPedido.PgtoPendente &&
    dadosPedido?.pedido?.pagamentos[0].formapgto_sttipopgto === 200;

  const isPedidoPronto = dadosPedido?.historico.some(
    ({ status }) => status >= StatusPedido.Pronto
  );

  return (
    <div className="timeline-background">
      <Spin spinning={dadosPedidoLoading}>
        {dadosPedido && (
          <div>
            <h3>
              {dadosPedido.estabelecimento.apelidooufantasia ||
                dadosPedido.estabelecimento.nomeourazaosocial}
            </h3>

            <BotaoContinuarComprando
              deliveryestabelecimento={dadosPedido?.deliveryestabelecimento}
            />

            <Divider />

            <Endereco
              endereco={
                dadosPedido.pedido.endereco_togo ||
                dadosPedido.pedido.cliente.endereco
              }
              tipo={dadosPedido.pedido.tipo}
            />

            <AcompanheContatoEstabelecimento
              telefones={dadosPedido.estabelecimento.fone_set}
            />

            {dadosPedido.historico[dadosPedido.historico.length - 1].status ===
              StatusPedido.Entregue && !atualizado ? (
              <div style={{ marginBottom: 20 }}>
                <AcompanheDetalhe
                  pedido={dadosPedido.pedido}
                  data_pedido={moment(dadosPedido.historico[0].datahora).format(
                    'LLLL'
                  )}
                />
              </div>
            ) : (
              <>
                <p
                  onClick={() => {
                    setMostrarMaisDetalhes((d) => !d);
                  }}
                  style={{ cursor: 'pointer' }}
                  className="pedido-detalhe"
                >
                  {`${
                    mostrarMaisDetalhes ? 'Menos' : 'Mais'
                  } detalhes do Pedido #${dadosPedido.referencia}`}
                </p>
                {mostrarMaisDetalhes && (
                  <div style={{ marginBottom: 20 }}>
                    <AcompanheDetalhe
                      pedido={dadosPedido.pedido}
                      data_pedido={moment(
                        dadosPedido.historico[0].datahora
                      ).format('LLLL')}
                    />
                  </div>
                )}

                {pedidoPgtoPendente && (
                  <AvisoEsperandoPagamentoDebito
                    transacoes={dadosPedido.transacoes}
                    onTransacaoFinalizada={iniciarPollingPedido}
                  />
                )}

                {pedidoPgtoPendentePix && (
                  <AvisoEsperandoPagamentoPix
                    transacoes={dadosPedido.transacoes}
                  />
                )}

                <div>
                  <AcompanheTimeline dadosPedido={dadosPedido} />
                </div>
              </>
            )}
          </div>
        )}
      </Spin>
      {isPedidoPronto && <CupomFiscal idpedido={id} />}
    </div>
  );
}
