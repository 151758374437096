import React from 'react';
import { Alert, Typography } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { formatarTelefone } from './utils';

const propTypes = {
  telefones: PropTypes.arrayOf(
    PropTypes.shape({
      numero: PropTypes.string
    })
  )
};

const defaultProps = {
  telefones: []
};

const { Text } = Typography;

const AcompanheContatoEstabelecimento = ({ telefones }) => {
  if (telefones.length === 0) {
    return (
      <Alert
        message="O estabelecimento não disponibilizou telefone para contato."
        showIcon
        style={{ marginBottom: 16 }}
        type="warning"
      />
    );
  }

  const obterNumero = (telefone) => {
    const numeroFormatado = formatarTelefone(telefone.numero);
    return <a href={`tel:${numeroFormatado}`}>{numeroFormatado}</a>
  }

  const linksTelefones = (telefonesContato) =>
    telefonesContato.map(obterNumero);

  return (
    <div style={{ marginBottom: 16 }}>
      <h3>Telefone(s) para contato com o estabelecimento</h3>
      {linksTelefones(telefones).map((linkTelefone, i) => [
          i > 0 && " | ",
          linkTelefone
      ])}
    </div>
  );
};

AcompanheContatoEstabelecimento.propTypes = propTypes;
AcompanheContatoEstabelecimento.defaultProps = defaultProps;

export default AcompanheContatoEstabelecimento;
