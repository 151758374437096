import React from 'react';
import { Typography } from 'antd';

const { Paragraph, Text } = Typography;

const CardapioProdutoOpcoes = ({ produto }) => {
  if (!Array.isArray(produto.grupoopcoes)) {
    return null;
  }
  return (
    <Paragraph>
      {produto.grupoopcoes.map((grupoopcao) => {
        const {
          descricao: descricaoGrupoOpcao,
          opcoes,
          stprecopraticado
        } = grupoopcao;

        if (opcoes.every((opcao) => opcao.quantidade === 0)) {
          return null;
        }

        const quantidadeOpcoes =
          stprecopraticado >= 1 && stprecopraticado <= 3
            ? opcoes.reduce((total, opcao) => total + opcao.quantidade, 0)
            : null;

        return (
          <div>
            <Text>{descricaoGrupoOpcao}</Text>
            {opcoes
              .filter((opcao) => opcao.quantidade)
              .map((opcao) => {
                const { descricao, idopcao, quantidade } = opcao;
                return (
                  <div style={{ marginLeft: 16 }}>
                    <Text key={idopcao} type="secondary">
                      {quantidadeOpcoes
                        ? `(${quantidade}/${quantidadeOpcoes})`
                        : quantidade}
                      x {descricao}
                    </Text>
                  </div>
                );
              })}
          </div>
        );
      })}
    </Paragraph>
  );
};

export default CardapioProdutoOpcoes;
