import { Button, Card, Empty, Result, Spin, message } from 'antd';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { apiDelivery } from 'services/api';
import { svgLogoEAD } from 'assets';
import { tratarErro } from 'utils/tratarErros';

export default function AcessoEmailConfirmado() {
  const { clienteUuid, token } = useParams();
  const [loading, setLoading] = useState(true);
  const [ok, setOk] = useState(false);

  useEffect(() => {
    setLoading(true);
    const enviaConfirmacao = async () => {
      try {
        await apiDelivery.post(
          `/controle/cliente/${clienteUuid}/confirmar/${token}/`
        );
        setOk(true);
      } catch (err) {
        const errorMsg = tratarErro(err);
        message.error(errorMsg);
        setLoading(false);
        setOk(false);
      }
      setLoading(false);
    };
    enviaConfirmacao();
  }, [clienteUuid, token]);

  return (
    <div style={{ paddingTop: 30}} >
      <Card style={{ background: '#fff' }}>
        <div className="logo">
          <img alt="logo" src={svgLogoEAD} />
        </div>
        <div style={{ textAlign: 'center', minHeigth: 50 }}>
          <Spin spinning={loading} tip="Confirmando..." />
        </div>
        {loading ? null : ok ? (
          <Result
            status="success"
            title="Tudo certo!! "
            subTitle="Já pode explorar e pedir o que tem de melhor na sua cidade para delivery!"
            extra={[
              <Link to="/login">
                <Button type="primary" key="console">
                  Ir para o Login
                </Button>
              </Link>
            ]}
          />
        ) : (
          <Result
            status="warning"
            title="Algo deu errado... "
            extra={[
              <Link to="/login">
                <Button type="primary" key="console">
                  Começar de novo
                </Button>
              </Link>
            ]}
          />
        )}
      </Card>
    </div>
  );
}
