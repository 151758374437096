import {
  Button,
  Form,
  Input,
  notification,
  Drawer,
  Modal,
  Typography,
  Tag,
  Avatar,
  Divider
} from 'antd';
import { MinusOutlined, PlusOutlined, CameraOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import {
  obterValorAfetaTudo,
  obterValorMonetario,
  obterValorProduto
} from 'services/precos';
import { STPRECOPRATICADO_KIT } from 'services/precos/constants';

import {
  adicionarItem,
  editarItem,
  inicializarCarrinho,
  useCarrinhoContext
} from 'contexts/CarrinhoContext';
import PropTypes from 'prop-types';
import useDeviceWidth from 'hooks/useDeviceWidth';

import { capitalizeFLetter } from 'utils/normalize';
import { isProdutoValido } from './utils';

import GrupoOpcao from './GrupoOpcao';
import PrecoProduto from '../PrecoProduto';

const propTypes = {
  indice: PropTypes.number,
  produto: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

const defaultProps = {
  indice: null,
  produto: null,
  visible: false
};

const { TextArea } = Input;
const { Paragraph, Title } = Typography;

const fieldLayout = {
  wrapperCol: {
    xs: {
      span: 24
    },
    md: {
      span: 20
    }
  }
};

const ProdutoDrawer = ({
  areasEntrega,
  estabelecimento,
  indice,
  produto,
  visible,
  onClose,
  onAnimationEnded
}) => {
  const [grupoOpcoes, setGrupoOpcoes] = useState([]);
  const { carrinho, carrinhoDispatcher } = useCarrinhoContext();
  const [observacoes, setObservacoes] = useState('');
  const [quantidade, setQuantidade] = useState(1);
  const [width] = useDeviceWidth();

  useEffect(() => {
    if (produto) {
      const { grupoopcoes } = produto;
      setObservacoes(produto.observacoes || '');
      setQuantidade(produto.quantidade || 1);

      Array.isArray(grupoopcoes) && setGrupoOpcoes(grupoopcoes);
    }
  }, [produto]);

  const produto_valido = isProdutoValido({ grupoopcoes: grupoOpcoes });

  if (!produto) return null;

  const { grupoopcoes, stindisponivel, ...dadosProduto } = produto;
  const style_btn_adicionar =
    !produto_valido || stindisponivel === 1
      ? {
          backgroundColor: '#f37a83',
          borderColor: '#f37a83'
        }
      : {};

  const confirmar = () => {
    if (stindisponivel === 1) {
      notification['warning']({
        key: 'item_indisponivel',
        message: 'Produto indisponível',
        description: 'No momento o produto está indisponível.'
      });
      return;
    }

    if (!produto_valido) {
      notification['warning']({
        key: 'item_obrigatorio',
        message: 'Itens Obrigatórios',
        description:
          'Você precisa escolher todos os itens obrigatórios para adicionar o produto.'
      });
      return;
    }

    const item = {
      ...dadosProduto,
      grupoopcoes: grupoOpcoes,
      observacoes,
      quantidade
    };
    if (!carrinho.estabelecimento) {
      carrinhoDispatcher(
        inicializarCarrinho({ ...estabelecimento, areasEntrega }, item)
      );
      onClose();
      return;
    }
    if (
      !carrinho.estabelecimento ||
      carrinho.estabelecimento.id !== estabelecimento.id
    ) {
      Modal.confirm({
        cancelText: 'Cancelar',
        content:
          'Você só pode adicionar itens de um estabelecimento por vez. Deseja esvaziar o carrinho e adicionar este produto?',
        okText: 'Esvaziar carrinho e adicionar',
        title: 'Aviso',
        onClose: () => null,
        onOk: () => {
          carrinhoDispatcher(
            inicializarCarrinho({ ...estabelecimento, areasEntrega }, item)
          );
          onClose();
        }
      });
      return;
    }
    carrinhoDispatcher(
      indice !== null ? editarItem(item, indice) : adicionarItem(item)
    );
    onClose();
  };

  const alterarGrupoOpcoes = (indice, grupoOpcaoAtualizado) => {
    setGrupoOpcoes((grupoOpcoesAtual) =>
      grupoOpcoesAtual.map((grupoOpcao, i) => {
        return i === indice ? grupoOpcaoAtualizado : grupoOpcao;
      })
    );
  };

  const precoTotal = obterValorMonetario(
    obterValorProduto({
      ...produto,
      quantidade,
      grupoopcoes: grupoOpcoes
    })
  );
  const valorAfetaTudo = obterValorAfetaTudo(grupoOpcoes);

  const possuiGrupoOpcaoKit = grupoOpcoes.some(
    (grupoOpcao) => grupoOpcao.stprecopraticado === STPRECOPRATICADO_KIT
  );

  const BtnQtd = () => {
    return (
      <div>
        <Button
          icon={<MinusOutlined />}
          style={{ margin: 8, color: 'red' }}
          onClick={() => setQuantidade((qtd) => (qtd === 1 ? qtd : qtd - 1))}
        />

        <span style={{ fontSize: 18, margin: '0 8px' }}>{quantidade}</span>

        <Button
          icon={<PlusOutlined />}
          style={{ margin: 8, color: 'red' }}
          onClick={() => setQuantidade((qtd) => qtd + 1)}
        />
      </div>
    );
  };

  const DrawerFooter = () => {
    const btnText = () => {
      if (produto.stindisponivel === 1) return 'Indisponível';
      return indice !== null ? 'Editar' : 'Adicionar';
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline'
        }}
      >
        <BtnQtd />
        <Button
          key="confirmar"
          type="primary"
          onClick={confirmar}
          style={{ height: 50, flexGrow: '1', ...style_btn_adicionar }}
        >
          {btnText()} {precoTotal}
        </Button>
      </div>
    );
  };

  if (!produto) return null;

  return (
    <Drawer
      afterVisibleChange={(visible) => {
        onAnimationEnded && onAnimationEnded(visible);
        if (visible) return;
        setObservacoes(produto.observacoes || '');
        setQuantidade(produto.quantidade || 1);

        if (indice === null) {
          setGrupoOpcoes(
            grupoOpcoes.map((grupoOpcao) => {
              const { opcoes } = grupoOpcao;
              return {
                ...grupoOpcao,
                opcoes: opcoes.map((opcao) => ({ ...opcao, quantidade: 0 }))
              };
            })
          );
        }
      }}
      destroyOnClose
      footer={<DrawerFooter />}
      title={<Title level={4}>{capitalizeFLetter(produto.descricao)}</Title>}
      visible={visible}
      width={width < 728 ? '100%' : '50%'}
      height="100%"
      onClose={onClose}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: '1',
            flexDirection: 'column'
          }}
        >
          <Paragraph
            style={{ maxWidth: '80%' }}
            ellipsis={{ rows: 2, expandable: true }}
          >
            {produto.detalhe || 'Produto sem descrição.'}
          </Paragraph>

          {!possuiGrupoOpcaoKit && (
            <div style={{ fontSize: 18 }}>
              <PrecoProduto
                preco={produto?.preco}
                precooferta={produto?.precooferta}
              />
            </div>
          )}
          {produto.stindisponivel === 1 && (
            <div>
              <Tag color="red">INDISPONÍVEL</Tag>
            </div>
          )}
        </div>
        <div>
          <Avatar
            src={
              produto.imagem
                ? `${
                    process.env.REACT_APP_API_URL_EAR || 'http://localhost:8005'
                  }${produto.imagem}`
                : null
            }
            icon={<CameraOutlined />}
            size={width < 768 ? 96 : 192}
            shape="square"
            style={{ margin: 0, marginBottom: 16 }}
          />
        </div>
      </div>

      {grupoOpcoes.map((grupoopcao, indice) => (
        <div key={grupoopcao.idgrupoopcao}>
          <Divider />
          <GrupoOpcao
            key={grupoopcao.idgrupoopcao}
            grupoopcao={grupoopcao}
            produto={produto}
            onAtualizarGrupo={(grupoOpcaoAtualizado) => {
              alterarGrupoOpcoes(indice, grupoOpcaoAtualizado);
            }}
            valorAfetaTudo={valorAfetaTudo}
          />
        </div>
      ))}

      <Form.Item {...fieldLayout} label="Comentário">
        <TextArea
          placeholder="Exemplo: Sem verdura, sem mostarda etc."
          rows={width < 728 ? 2 : 4}
          style={{ resize: 'none' }}
          value={observacoes}
          onChange={(e) => setObservacoes(e.target.value)}
        />
      </Form.Item>
    </Drawer>
  );
};

ProdutoDrawer.propTypes = propTypes;
ProdutoDrawer.defaultProps = defaultProps;

export default ProdutoDrawer;
