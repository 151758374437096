import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

import {
  EndCobrancaNomeContatoBPMPI,
  EndCobrancaTelefoneBPMPI,
  EndCobrancaEmailBPMPI,
  EndCobrancaRuaeNumeroBPMPI,
  EndCobrancaComplementoeBairroBPMPI,
  EndCobrancaCidadeBPMPI,
  EndCobrancaEstadoBPMPI,
  EndCobrancaCEPBPMPI,
  EndCobrancaPaisBPMPI,
  EndEntregaIgualCobrancaBPMPI
} from 'utils/InputBPMPI';

const BPMpi3DSEndereco = ({ nome, telefone, email, endereco }) => {
  return (
    <div>
      <EndCobrancaNomeContatoBPMPI value={nome} />
      <EndCobrancaTelefoneBPMPI value={telefone} />
      <EndCobrancaEmailBPMPI value={email} />
      <EndCobrancaRuaeNumeroBPMPI
        value={`${endereco?.rua || ''} ${endereco?.numero || ''}`}
      />
      <EndCobrancaComplementoeBairroBPMPI
        value={`${endereco?.complemento || ''} ${endereco?.bairro || ''}`}
      />
      <EndCobrancaCidadeBPMPI value={endereco?.cidade || ''} />
      <EndCobrancaEstadoBPMPI value={endereco?.uf || ''} />
      <EndCobrancaCEPBPMPI value={(endereco?.cep || '').replace(/\D/g, '')} />
      <EndCobrancaPaisBPMPI value="BR" />
      {endereco?.cep && <EndEntregaIgualCobrancaBPMPI value="true" />}
    </div>
  );
};

BPMpi3DSEndereco.propTypes = {
  nome: PropTypes.string,
  telefone: PropTypes.string,
  email: PropTypes.string,
  endereco: PropTypes.object
};

BPMpi3DSEndereco.defaultProps = {
  nome: '',
  telefone: '',
  email: '',
  endereco: {}
};

export default BPMpi3DSEndereco;
