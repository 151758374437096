import React, { createContext, useContext, useEffect, useReducer } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';

const INITIAL_STATE = {
  categoria: [],
  categoria__in: '',
  cidade: null,
  uf: null
};

export const FiltroEstabelecimentoContext = createContext();

export const KEY_FILTRO_ESTAB = 'filtro_estabelecimento';

export const FILTRAR_CATEGORIA = 'FILTRAR_CATEGORIA';
export const FILTRAR_CIDADE_UF = 'FILTRAR_CIDADE_UF';
export const LIMPAR_FILTRO = 'LIMPAR_FILTRO';

export const filtrarCategoria = (categoria) => ({ type: FILTRAR_CATEGORIA, categoria });
export const filtrarCidadeUf = (cidade, uf) => ({ type: FILTRAR_CIDADE_UF, cidade, uf });
export const limparFiltro = () => ({ type: LIMPAR_FILTRO });

export const filtroEstabelecimentoReducer = (state, action) => {
  switch (action.type) {
    case FILTRAR_CATEGORIA:
      return {
        ...state,
        categoria: action.categoria,
        categoria__in: action.categoria.join(',')
      };
    case FILTRAR_CIDADE_UF:
      return { ...state, cidade: action.cidade, uf: action.uf };
    case LIMPAR_FILTRO:
      return INITIAL_STATE;
    default:
      return state;
  }
};

const FiltroEstabelecimentoProvider = (props) => {
  const [filtroEstabSalvo, saveFiltroEstab] = useLocalStorage(KEY_FILTRO_ESTAB, INITIAL_STATE);

  const [filtroEstabelecimento, filtroEstabDispatcher] = useReducer(
    filtroEstabelecimentoReducer,
    filtroEstabSalvo
  );
  useEffect(() => {
    saveFiltroEstab(filtroEstabelecimento);
  }, [filtroEstabelecimento]);
  return (
    <FiltroEstabelecimentoContext.Provider
      value={{ filtroEstabelecimento, filtroEstabDispatcher }}
      {...props}
    />
  );
};

const useFiltroEstabelecimentoContext = () => useContext(FiltroEstabelecimentoContext);

export { FiltroEstabelecimentoProvider, useFiltroEstabelecimentoContext };
