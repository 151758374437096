import React from 'react';
import { Pagination } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const propTypes = {
  quantidade: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

const defaultProps = {};

const DEFAULT_CURRENT_PAGE = 1;
/* FIXME: encontrar uma forma de retirar a duplicação em usePedidosConcluidos.js */
const DEFAULT_PAGE_SIZE = 10;

const PedidosConcluidosPaginador = ({ quantidade, onChange }) => {
  return (
    <Pagination
      defaultCurrent={DEFAULT_CURRENT_PAGE}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      total={quantidade}
      onChange={onChange}
    />
  );
};

PedidosConcluidosPaginador.propTypes = propTypes;
PedidosConcluidosPaginador.defaultProps = defaultProps;

export default PedidosConcluidosPaginador;
