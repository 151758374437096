import React, { createContext, useReducer, useContext, useEffect } from 'react';
import useCliente2 from 'hooks/useCliente2';
import usePedidos from 'hooks/usePedidos';
import { KEY_ACCESS_TOKEN } from './AuthContext';

export const ClienteContext = createContext();

const initialState = {
  cliente: null,
  pedidos: []
};

export const SET_CLIENTE = 'SET_CLIENTE';
export const CLEAR_CLIENTE = 'CLEAR_CLIENTE';
export const SET_PEDIDOS = 'SET_PEDIDOS';

export function setCliente(cliente) {
  return { type: SET_CLIENTE, cliente };
}

export function clearCliente() {
  return { type: CLEAR_CLIENTE };
}

export function setPedidos(pedidos) {
  return { type: SET_PEDIDOS, pedidos };
}

export function clienteReducer(state, action) {
  switch (action.type) {
    case SET_CLIENTE:
      return {
        ...state,
        cliente: action.cliente
      };
    case CLEAR_CLIENTE:
      return initialState;
    case SET_PEDIDOS:
      return {
        ...state,
        pedidos: action.pedidos
      };
    default:
      return state;
  }
}

function ClienteProvider(props) {
  const [cliente, dispatch] = useReducer(clienteReducer, initialState);

  const clienteData = { cliente, clienteDispatcher: dispatch };
  const [_cliente, , getCliente] = useCliente2();
  const [pedidos, , , getPedidos] = usePedidos();

  useEffect(() => {
    if (!cliente.cliente && localStorage.getItem(KEY_ACCESS_TOKEN)) {
      getCliente();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem(KEY_ACCESS_TOKEN) && _cliente) {
      dispatch(setCliente(_cliente));
    }
  }, [_cliente, dispatch]);

  useEffect(() => {
    if (cliente.cliente) {
      getPedidos();
    }
  }, [cliente.cliente]);

  useEffect(() => {
    dispatch(setPedidos(pedidos));
  }, [pedidos]);

  return <ClienteContext.Provider value={clienteData} {...props} />;
}

function useClienteContext() {
  return useContext(ClienteContext);
}

export { ClienteProvider, useClienteContext };
