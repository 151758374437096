import React, { useEffect, useState } from 'react';
import { Button, message, Result } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { apiDelivery } from 'services/api';
import StatusPedido from 'utils/Pedido';

import ModalAutenticadorPagamentoDebito from '../../FinalizarPedido/components/PagarOnlineForm/ModalAutenticadorPagamentoDebito';

import useValidacao from '../../FinalizarPedido/Validacao/useValidacao';

const propTypes = {
  transacoes: PropTypes.arrayOf(
    PropTypes.shape({
      deliveryestabelecimento: PropTypes.string,
      pedido: PropTypes.string,
      url: PropTypes.string
    })
  ),
  onTransacaoFinalizada: PropTypes.func
};

const defaultProps = {
  transacoes: null
};

const AvisoEsperandoPagamentoDebito = ({
  transacoes,
  onTransacaoFinalizada
}) => {
  const [transacao] = transacoes;
  const [urlAutenticacao, setUrlAutenticacao] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const validacao = useValidacao();

  useEffect(() => {
    setTimeout(() => {
      onBtnClicked();
    });
  }, []);

  const _onTransacaoFinalizada = () =>
    onTransacaoFinalizada && onTransacaoFinalizada();

  const verificarStatusPagamento = async (callback) => {
    try {
      const {
        data: { status }
      } = await apiDelivery.get(
        `/api/estabelecimento/${transacao?.deliveryestabelecimento}/status/${transacao?.pedido}/`
      );
      const pagamentoOk = status === StatusPedido.Sincronizado;
      const pagamentoFalhou =
        status === StatusPedido.Cancelado ||
        status === StatusPedido.CancelamentoSincronizado;

      if (pagamentoFalhou) {
        message.error('Autenticação negada para o pagamento em débito.');
      }
      if (pagamentoOk || pagamentoFalhou) {
        setModalVisible(false);
        setUrlAutenticacao('');
      }
    } catch (erro) {
      console.log('erro: ', erro);
      validacao.processarErro(erro);
    } finally {
      callback && callback();
    }
  };

  const onBtnClicked = () => {
    setUrlAutenticacao(transacao?.url);
    setModalVisible(true);
    verificarStatusPagamento();
  };

  return (
    <Result
      extra={
        <>
          <Button onClick={onBtnClicked}>
            Clique para efetuar o pagamento
          </Button>
          <ModalAutenticadorPagamentoDebito
            urlAutenticacao={urlAutenticacao}
            onCancel={() => {
              setModalVisible(false);
              verificarStatusPagamento(_onTransacaoFinalizada);
            }}
            onUrlCallbackCarregada={() =>
              verificarStatusPagamento(_onTransacaoFinalizada)
            }
            visible={modalVisible}
          />
        </>
      }
      status="warning"
      style={{ marginBottom: 16 }}
      title="Pagamento em débito pendente"
    />
  );
};

AvisoEsperandoPagamentoDebito.propTypes = propTypes;
AvisoEsperandoPagamentoDebito.defaultProps = defaultProps;

const isTransacoesIguais = (prevProps, nextProps) => {
  const [transacaoAntiga] = prevProps.transacoes;
  const [transacaoNova] = nextProps.transacoes;
  return (
    transacaoAntiga.deliveryestabelecimento ===
      transacaoNova.deliveryestabelecimento &&
    transacaoAntiga.url === transacaoNova.url
  );
};

export default React.memo(AvisoEsperandoPagamentoDebito, isTransacoesIguais);
