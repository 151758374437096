import React from 'react';
import { capitalizeFLetter } from 'utils/normalize';

export default function Endereco({ endereco }) {
  if (!endereco || (!endereco.rua && !endereco.bairro)) {
    return <span>Sem endereço cadastrado</span>;
  }

  return (
    <span>
      {`${capitalizeFLetter(endereco.rua)}, ${
        endereco.numero
      } - ${capitalizeFLetter(endereco.bairro)}`}
      {endereco.cidade && (
        <>
          {endereco.cidade.descricao &&
            ` | ${capitalizeFLetter(endereco.cidade.descricao)}`}
          {endereco.cidade &&
            endereco.cidade.uf &&
            ` - ${endereco.cidade.uf.sigla}`}
        </>
      )}
    </span>
  );
}
