import React, { useState, useEffect } from 'react';

import {
  Form,
  Row,
  Col,
  Input,
  Modal,
  message,
  Spin,
  Button,
  Steps,
  InputNumber,
  Card
} from 'antd';

import {
  LoadingOutlined,
  DownOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';

import axios from 'axios';

import useDeviceWidth from 'hooks/useDeviceWidth';

import useEnderecos from 'hooks/useEnderecos';
import { Link } from 'react-router-dom';
import {
  useEnderecoContext,
  setEndereco,
  clearEndereco
} from 'contexts/EnderecoContext';
import { useAuthContext } from 'contexts/AuthContext';
import {
  useFiltroEstabelecimentoContext,
  filtrarCidadeUf
} from 'contexts/FiltroEstabelecimentoContext';
import { apiDelivery } from 'services/api';
import DeliveryEnderecosSelected from './DeliveryEnderecosSelected';

export default function FormEnderecoSelecionado() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [cepLoading, setCepLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [width] = useDeviceWidth();

  const { auth } = useAuthContext();
  const {
    endereco: enderecoSelecionado,
    enderecoDispatcher
  } = useEnderecoContext();
  const { filtroEstabDispatcher } = useFiltroEstabelecimentoContext();

  const [visible, setVisible] = useState(false);
  const [hideFormFields, setHideFormFields] = useState(false);

  const [current, setCurrent] = useState(0);

  const [enderecos, enderecoLoading, , { getEnderecos }] = useEnderecos();

  useEffect(() => {
    if (!enderecoSelecionado) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [enderecoSelecionado]);

  const getCep = (cep) => {
    if (cep === '') {
      return;
    }
    setCepLoading(true);
    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.data)
      .then((endereco) => {
        if (endereco.erro) {
          setHideFormFields(false);
          form.resetFields();
          message.warn(`O endereço com o CEP ${cep} não foi encontrado.`);
          return;
        }
        const { bairro, localidade, logradouro, uf } = endereco;
        setHideFormFields(true);
        form.setFieldsValue({
          bairro,
          cidade: localidade,
          rua: logradouro,
          uf
        });
      })
      .catch(() => message.error('Erro ao obter o endereço.'))
      .finally(() => setCepLoading(false));
  };

  const { bairro, cidade, rua, uf } = form.getFieldsValue([
    'bairro',
    'cidade',
    'rua',
    'uf'
  ]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = async (dados) => {
    try {
      const result = await form.validateFields(dados);

      const form_cep = form.getFieldValue('cep');
      const novo_endereco = {
        ...result,
        cep: form_cep
      };

      if (auth.isLoggedIn) {
        try {
          await apiDelivery.post('/controle/endereco/', novo_endereco);
        } catch (e) {
          try {
            let msg_erro = '';
            e.response.data.non_field_errors.forEach(
              (erro) => (msg_erro += `${erro}\n`)
            );
            message.error(msg_erro);
          } catch (e2) {
            message.error(
              'Não foi possível cadastrar o endereço, verifique sua cidade, estado e bairro, e tente novamente!'
            );
            console.error(e2);
          }
          return;
        }
      }

      enderecoDispatcher(setEndereco(novo_endereco));
      filtroEstabDispatcher(filtrarCidadeUf(result.cidade, result.uf));
      setShowForm(false);
      form.resetFields();
    } catch (error) {
      console.error(error);
    }
  };

  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  function soNumeros(d) {
    return d.replace(/\D/g, '');
  }

  function cep(d) {
    const a = soNumeros(d);
    return form.setFieldsValue({ cep: a.replace(/^(\d{5})(\d)/, '$1-$2') });
  }

  const steps = [
    {
      title: 'CEP',

      content: (
        <>
          <Row align="center">
            <Col xs={8} md={8}>
              <Form.Item
                name="cep"
                placeholder="Digite o CEP."
                rules={[
                  {
                    message: 'Campo obrigatório.',
                    required: true
                  }
                ]}
                onBlur={(e) => {
                  if (
                    form.getFieldValue('cep') &&
                    form.getFieldValue('cep').toString().length <= 9
                  ) {
                    setHideFormFields(true);
                    getCep(e.target.value);
                    next();
                  }
                }}
              >
                <Input
                  onChange={(e) => cep(e.target.value)}
                  style={{ width: '100%' }}
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Endereço',
      content: (
        <>
          {width < 728 ? (
            <Row gutter={[16, 16]} align="center">
              <div
                hidden={!hideFormFields}
              >{`${rua}, ${bairro} - ${cidade} - ${uf}`}</div>
            </Row>
          ) : (
            ''
          )}
          <Row gutter={16}>
            <Col xs={12} md={6}>
              <Form.Item
                label="Número"
                name="numero"
                rules={[{ message: 'Campo obrigatório.', required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {width < 728 ? (
              <Col xs={12} md={6}>
                <Form.Item
                  label="Complemento"
                  name="complemento"
                  rules={[{ message: 'Campo obrigatório.', required: false }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : (
              ''
            )}

            <Col xs={24} md={12}>
              <Form.Item
                hidden={hideFormFields && width < 728 && rua !== ''}
                disabled={!hideFormFields}
                label="Rua"
                name="rua"
                rules={[{ message: 'Campo obrigatório.', required: true }]}
              >
                <Input
                  disabled={cepLoading}
                  suffix={cepLoading ? <LoadingOutlined /> : null}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                hidden={hideFormFields && width < 728 && bairro !== ''}
                disabled={!hideFormFields}
                label="Bairro"
                name="bairro"
                rules={[{ message: 'Campo obrigatório.', required: true }]}
              >
                <Input
                  disabled={cepLoading}
                  suffix={cepLoading ? <LoadingOutlined /> : null}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                hidden={hideFormFields && width < 728 && cidade !== ''}
                disabled={hideFormFields}
                label="Cidade"
                name="cidade"
                rules={[{ message: 'Campo obrigatório.', required: true }]}
              >
                <Input
                  disabled={cepLoading}
                  suffix={cepLoading ? <LoadingOutlined /> : null}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                hidden={hideFormFields && width < 728 && uf !== ''}
                disabled
                label="UF"
                name="uf"
                rules={[
                  { message: 'Sigla da UF deve ter duas letras', len: 2 },
                  { message: 'Campo obrigatório.', required: true }
                ]}
              >
                <Input
                  disabled={cepLoading}
                  suffix={cepLoading ? <LoadingOutlined /> : null}
                />
              </Form.Item>
            </Col>

            {width > 728 ? (
              <Col xs={12} md={12}>
                <Form.Item
                  label="Complemento"
                  name="complemento"
                  rules={[{ message: 'Campo obrigatório.', required: false }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : (
              ''
            )}

            <Col xs={24}>
              <Form.Item
                label="Referência"
                name="detalhe"
                rules={[{ message: 'Campo obrigatório.', required: false }]}
              >
                <TextArea
                  rows={width < 728 ? 2 : 4}
                  style={{ resize: 'none' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )
    }
  ];

  const { Step } = Steps;

  const BtnEntrar = () => {
    if (auth.isLoggedIn) {
      return null;
    }

    return (
      <Row align="center" gutter={[16, 16]}>
        <Link to="/login">
          <Button type="primary" block size="middle">
            Entre ou cadastre-se
          </Button>
        </Link>
      </Row>
    );
  };

  return (
    <>
      <div>
        <div
          id="modalEndereco"
          className="ant-card-bordered ant-card-hoverable"
          style={{
            width: width < 768 ? 195 : 300,
            backgroundColor: 'white',
            border: '1px solid #ddd',
            lineHeight: 'normal',
            padding: 8,
            borderRadius: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => {
            setVisible(true);
            getEnderecos();
          }}
        >
          <DownOutlined style={{ marginRight: 6 }} />
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {enderecoSelecionado
              ? `${enderecoSelecionado.rua}, ${enderecoSelecionado.numero} - ${enderecoSelecionado.bairro}`
              : 'Selecionar Endereço '}
          </span>
        </div>
      </div>

      <Modal
        zIndex={100}
        footer={null}
        title="Escolha o endereço de entrega"
        visible={visible}
        onCancel={handleCancel}
      >
        {auth.isLoggedIn && enderecos.length > 0 ? (
          <Spin spinning={enderecoLoading}>
            <div style={{ marginBottom: 16 }}>
              {!showForm ? (
                <Button type="primary" onClick={() => setShowForm(true)} block>
                  Novo
                </Button>
              ) : (
                <Button danger onClick={() => setShowForm(false)} block>
                  Voltar
                </Button>
              )}
            </div>

            {showForm ? (
              <Form
                form={form}
                initialValues={{
                  complemento: '',
                  referencia: ''
                }}
                layout="vertical"
                name="endereco-form"
                onFinish={handleSubmit}
              >
                <BtnEntrar />

                <div>
                  <Steps style={{ flexDirection: 'row' }} current={current}>
                    {steps.map((item) => (
                      <Step key={item.title} title={item.title} />
                    ))}
                  </Steps>

                  <div className="steps-content">{steps[current].content}</div>
                  <div
                    className="steps-action"
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    {current < steps.length - 1 && (
                      <Button type="primary" onClick={() => next()}>
                        Próximo
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button type="primary" onClick={() => handleSubmit()}>
                        Confirmar
                      </Button>
                    )}
                    {current > 0 && (
                      <Button style={{ marginLeft: 16 }} onClick={() => prev()}>
                        Anterior
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            ) : (
              enderecos.map((endereco) => (
                <DeliveryEnderecosSelected
                  endereco={endereco}
                  enderecoLoading={false}
                  onSuccess={getEnderecos}
                />
              ))
            )}
          </Spin>
        ) : (
          <Spin spinning={loading}>
            {enderecoSelecionado ? (
              <div style={{ marginBottom: 16 }}>
                <div>
                  {`Endereço atual: ${enderecoSelecionado.rua}, ${enderecoSelecionado.numero} - ${enderecoSelecionado.bairro}`}

                  <Button
                    style={{ marginLeft: 16 }}
                    size="small"
                    type="primary"
                    onClick={() => {
                      enderecoDispatcher(clearEndereco());
                      form.resetFields();
                      setCurrent(0);
                    }}
                  >
                    Limpar
                  </Button>
                </div>
              </div>
            ) : (
              ''
            )}

            <Form
              form={form}
              initialValues={{
                complemento: '',
                referencia: ''
              }}
              layout="vertical"
              name="endereco-form"
              onFinish={handleSubmit}
            >
              <BtnEntrar />
              <div>
                <Steps style={{ flexDirection: 'row' }} current={current}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>

                <div className="steps-content">{steps[current].content}</div>
                <div
                  className="steps-action"
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                      Próximo
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => handleSubmit()}>
                      Confirmar
                    </Button>
                  )}
                  {current > 0 && (
                    <Button style={{ marginLeft: 16 }} onClick={() => prev()}>
                      Anterior
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </Spin>
        )}
      </Modal>
    </>
  );
}
