import { Decimal } from 'decimal.js';

import { obterValorProduto } from './obterValorProduto';

export const obterValorSubTotal = (produtos) => {
  return parseFloat(
    produtos.reduce((total, produto) => {
      return new Decimal(total).add(obterValorProduto(produto));
    }, 0)
  );
};
