import React, { useEffect, useRef, useState } from 'react';
import { Modal, Spin } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const propTypes = {
  urlAutenticacao: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onUrlCallbackCarregada: PropTypes.func,
  visible: PropTypes.bool
};

const defaultProps = {
  urlAutenticacao: '',
  visible: false
};

const ModalAutenticadorPagamentoDebito = ({ urlAutenticacao, onCancel, onUrlCallbackCarregada, visible }) => {
  const iframeAutenticacaoRef = useRef(null);
  const [
    isCarregandoPaginaAutenticacao,
    setCarregandoPaginaAutenticacao
  ] = useState(true);

  useEffect(() => {
    const terminarLoading = (e) => { 
      setCarregandoPaginaAutenticacao(false);
      removerEventoListener();
    };
    const removerEventoListener = () => { 
      iframeAutenticacaoRef.current && iframeAutenticacaoRef.current.removeEventListener('load', terminarLoading); 
    };

    if (iframeAutenticacaoRef.current && visible) {
      iframeAutenticacaoRef.current.addEventListener('load', terminarLoading);
    }
    return removerEventoListener;
  }, [iframeAutenticacaoRef.current, visible]);

  useEffect(() => {
    function urlCallbackCarregadaListener(event) {
      if (event?.data?.urlCallbackCarregada) {
        onUrlCallbackCarregada && onUrlCallbackCarregada();
        removerMessageListener();
      } 
    }

    const adicionarMessageListener = () => window.addEventListener('message', urlCallbackCarregadaListener);
    const removerMessageListener = () => window.removeEventListener("message", urlCallbackCarregadaListener);

    if (iframeAutenticacaoRef.current) {
      if (visible) {
        adicionarMessageListener();
      } else {
        removerMessageListener();
      }
    }

    return removerMessageListener;
  }, [iframeAutenticacaoRef.current, visible]);

  const iframeStyle = {
    height: 384,
    width: '100%'
  };

  return (
    <Modal
      centered
      footer={null}
      forceRender
      maskClosable={false}
      title="Autenticação para o pagamento em débito"
      visible={visible}
      width={1200}
      onCancel={onCancel}
    >
      <Spin
        spinning={isCarregandoPaginaAutenticacao}
        tip="Aguarde enquanto a página de autenticação é carregada."
      >
        <iframe
          frameBorder="0"
          ref={iframeAutenticacaoRef}
          src={urlAutenticacao}
          style={iframeStyle}
          title="Autenticação do pagamento em débito"
        />
      </Spin>
    </Modal>
  );
};

ModalAutenticadorPagamentoDebito.propTypes = propTypes;
ModalAutenticadorPagamentoDebito.defaultProps = defaultProps;

export default ModalAutenticadorPagamentoDebito;
