import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

import { truncarValor } from 'services/precos';

import './ValorAjusteFeedback.css';

const { Text } = Typography;

const propTypes = {
  opcao: PropTypes.shape({
    stpercentualafetaproduto: PropTypes.number,
    valorafetaproduto: PropTypes.number
  }).isRequired
};

const ValorAjuste = ({ opcao }) => {
  const { stpercentualafetaproduto, valorafetaproduto } = opcao;
  const valorTruncado = truncarValor(Math.abs(valorafetaproduto));

  if (stpercentualafetaproduto === 0) {
    return <>R$ {valorTruncado}</>;
  }
  return <>{valorTruncado}%</>;
};

const ValorAjusteFeedback = ({ opcao }) => {
  if (opcao.valorafetaproduto === 0) {
    return null;
  }

  return (
    <div
      className="valor-ajuste-feedback__container"
      data-testid="valor-ajuste-feedback"
    >
      <Text type="warning">
        {opcao.valorafetaproduto > 0 ? (
          <>
            Acrescenta <ValorAjuste opcao={opcao} /> ao valor do produto.
          </>
        ) : (
          <>
            Desconta <ValorAjuste opcao={opcao} /> do valor do produto.
          </>
        )}
      </Text>
    </div>
  );
};

ValorAjusteFeedback.propTypes = propTypes;

export default ValorAjusteFeedback;
