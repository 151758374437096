import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';

import { apiDelivery } from 'services/api';

import useDeviceWidth from 'hooks/useDeviceWidth';

import { setEndereco, useEnderecoContext } from 'contexts/EnderecoContext';
import {
  useFiltroEstabelecimentoContext,
  filtrarCidadeUf
} from 'contexts/FiltroEstabelecimentoContext';

const { Meta } = Card;
const { TextArea } = Input;

const DeliveryEnderecosSelected = ({
  endereco,
  onSuccess,
  enderecoLoading
}) => {
  const [width] = useDeviceWidth();
  const [form] = Form.useForm();
  const [cpfLoading, setCpfLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { enderecoDispatcher } = useEnderecoContext();
  const { filtroEstabDispatcher } = useFiltroEstabelecimentoContext();

  useEffect(() => {
    if (endereco) {
      form.setFieldsValue(endereco);
    }
  }, [endereco, form]);

  const getCep = (cep) => {
    if (cep === '') {
      return;
    }
    setCpfLoading(true);
    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.data)
      .then((endereco) => {
        if (endereco.erro) {
          message.warn(`O endereço com o CEP ${cep} não foi encontrado.`);
          return;
        }
        const { bairro, localidade, logradouro, uf } = endereco;
        form.setFieldsValue({
          bairro,
          cidade: localidade,
          rua: logradouro,
          uf
        });
      })
      .catch(() => message.error('Erro ao obter o endereço.'))
      .finally(() => setCpfLoading(false));
  };

  const closeModal = () => setVisible(false);

  const atualizarEndereco = (dados) =>
    apiDelivery.put(`/controle/endereco/${endereco.id}/`, dados);

  const criarEndereco = (dados) =>
    apiDelivery.post('/controle/endereco/', dados);

  // const removerEndereco = () => {
  //   setDeleteLoading(true);
  //   apiDelivery
  //     .delete(`/controle/endereco/${endereco.id}/`)
  //     .then(() => onSuccess && onSuccess())
  //     .then(() => message.success('Endereço removido com sucesso.'))
  //     .catch(() => message.error('Erro ao tentar excluir o endereço.'))
  //     .finally(() => setDeleteLoading(false));
  // };

  const handleSubmit = (dados) => {
    const dadosEndereco = { idtipoendereco: 1, ...dados };
    setLoading(true);
    (endereco ? atualizarEndereco(dadosEndereco) : criarEndereco(dadosEndereco))
      .then(() => closeModal())
      .then(() => onSuccess && onSuccess())
      .then(() => message.success('Endereço salvo com sucesso.'))
      .then(() => !endereco && form.resetFields())
      .catch(() => message.error('Erro ao tentar salvar o endereço.'))
      .finally(() => setLoading(false));
  };

  const handleEnderecoSelecionado = () => {
    enderecoDispatcher(setEndereco(endereco));
    filtroEstabDispatcher(filtrarCidadeUf(endereco.cidade, endereco.uf));
  };

  const styleEllipsisOverflow = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  };

  return (
    <>
      <Spin spinning={deleteLoading || enderecoLoading}>
        <Card
          hoverable
          style={{ marginBottom: 16 }}
          onClick={endereco && handleEnderecoSelecionado}
        >
          <div>
            {endereco ? (
              <Meta
                title={
                  <div style={styleEllipsisOverflow}>
                    {`${endereco.rua}, ${endereco.numero}, ${endereco.bairro}`}
                  </div>
                }
                description={
                  <>
                    {endereco.complemento && (
                      <div style={styleEllipsisOverflow}>
                        {endereco.complemento}
                      </div>
                    )}
                    <div style={styleEllipsisOverflow}>
                      {endereco.cep} {endereco.cidade}
                    </div>
                  </>
                }
              />
            ) : (
              ''
            )}
          </div>
        </Card>
      </Spin>

      <Modal
        centered={width < 768}
        footer={[
          <Button key="salvar" type="primary" onClick={() => form.submit()}>
            Salvar
          </Button>
        ]}
        forceRender
        title="Dados do endereço"
        visible={visible}
        width={width * 0.9}
        onCancel={closeModal}
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Row gutter={16}>
              <Col xs={24} md={6}>
                <Form.Item
                  label="CEP"
                  name="cep"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                  onBlur={(e) => getCep(e.target.value)}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="Número"
                  name="numero"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label="Rua"
                  name="rua"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input
                    disabled={cpfLoading}
                    suffix={cpfLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="Bairro"
                  name="bairro"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input
                    disabled={cpfLoading}
                    suffix={cpfLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="Cidade"
                  name="cidade"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input
                    disabled={cpfLoading}
                    suffix={cpfLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="UF"
                  name="uf"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input
                    disabled={cpfLoading}
                    suffix={cpfLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="Complemento"
                  name="complemento"
                  rules={[{ message: 'Campo obrigatório.', required: false }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  label="Referência"
                  name="detalhe"
                  rules={[{ message: 'Campo obrigatório.', required: false }]}
                >
                  <TextArea rows={4} style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default DeliveryEnderecosSelected;
