export const STPRECOPRATICADO = {
  Normal: 0,
  MenorOpcao: 1,
  MediaOpcao: 2,
  MaiorOpcao: 3,
  DesprezaOpcao: 4,
  Kit: 10
};

export const LISTA_STPRECOPRATICADO_FRACIONADO = [
  STPRECOPRATICADO.MenorOpcao,
  STPRECOPRATICADO.MediaOpcao,
  STPRECOPRATICADO.MaiorOpcao
];
