import React from 'react';
import { CreditCardOutlined } from '@ant-design/icons';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { obterImagemBandeira } from './utils';

const propTypes = {
  bandeira: PropTypes.string.isRequired
};

const defaultProps = {};

const ImagemBandeiraCartao = ({ bandeira }) => {
  const imagemBandeira = obterImagemBandeira(bandeira);

  if (!imagemBandeira) {
    return <CreditCardOutlined style={{ fontSize: 32 }} />;
  }
  return (
    <img
      alt={bandeira.toUpperCase()}
      src={imagemBandeira}
      style={{ width: 32 }}
    />
  );
};

ImagemBandeiraCartao.propTypes = propTypes;
ImagemBandeiraCartao.defaultProps = defaultProps;

export default ImagemBandeiraCartao;
