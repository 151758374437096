import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';

const AcessoRedirectRoute = ({ children, ...props }) => {
  const { auth } = useAuthContext();

  if (auth.isLoggedIn === true) {
    const pathname = auth.redirect ? auth.redirect : '/';
    return <Redirect to={pathname} />;
  }

  return <Route {...props}>{children}</Route>;
};

export default AcessoRedirectRoute;
