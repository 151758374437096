import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  message,
  notification,
  Spin,
  Typography
} from 'antd';
import './Acesso.css';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

import logo from 'assets/logo_ead.svg';

import useCliente2 from 'hooks/useCliente2';

import { apiDelivery } from 'services/api';
import { tratarErro } from 'utils/tratarErros';
import { setAvatar } from 'components/Common/Usuario/Avatar';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import useDeviceWidth from 'hooks/useDeviceWidth';
import {
  loginFail,
  loginSuccess,
  setAuthTokens,
  useAuthContext,
  KEY_ACCESS_TOKEN
} from 'contexts/AuthContext';
import { useEnderecoContext } from 'contexts/EnderecoContext';
import { useClienteContext, setCliente } from 'contexts/ClienteContext';

import {
  useFiltroEstabelecimentoContext,
  filtrarCidadeUf
} from 'contexts/FiltroEstabelecimentoContext';
import AcessoFooter from './AcessoFooter';

const { Title } = Typography;

const AcessoLogin = () => {
  const [form] = Form.useForm();
  const [loginLoading, setLoginLoading] = useState(false);
  const [entrarComEmail, setEntrarComEmail] = useState(false);
  const { authDispatcher } = useAuthContext();
  const { clienteDispatcher } = useClienteContext();
  const {
    endereco: enderecoSelecionado
    // enderecoDispatcher
  } = useEnderecoContext();
  const { filtroEstabDispatcher } = useFiltroEstabelecimentoContext();
  const [deviceWidth] = useDeviceWidth();

  const [_cliente, loading, getCliente] = useCliente2();

  const handleSubmit = async (values) => {
    setLoginLoading(true);
    try {
      const result = await apiDelivery.post('/controle/token/', values);
      const { access, refresh } = result.data;

      authDispatcher(setAuthTokens(access, refresh));
      getCliente();
    } catch (err) {
      authDispatcher(loginFail(err));
      const errorMsg = tratarErro(err);
      message.error(errorMsg);
    } finally {
      setLoginLoading(false);
    }
  };

  useEffect(() => {
    const init = () => {
      async function setarEnderecoEstadoGlobal() {
        const lista_end = _cliente.clienteendereco_set || [];

        if (enderecoSelecionado && !enderecoSelecionado.id) {
          try {
            const { data } = await apiDelivery.post(
              '/controle/endereco/',
              enderecoSelecionado
            );

            lista_end.push(data);

            // enderecoDispatcher(setEndereco(data));
            filtroEstabDispatcher(filtrarCidadeUf(data.cidade, data.uf));
          } catch (err) {
            console.error(err);
          }
        }
      }

      if (localStorage.getItem(KEY_ACCESS_TOKEN) && _cliente) {
        clienteDispatcher(setCliente(_cliente));
        setarEnderecoEstadoGlobal();
        authDispatcher(loginSuccess());
      }
    };

    init();
  }, [_cliente]);

  function abrirNotificacaoErro() {
    notification.error({
      message: 'Não consegui dessa vez',
      description: 'Vamos tentar de novo?'
    });
  }

  const responseSocialAuth = async (response, social_backend) => {
    console.log(response);
    setLoginLoading(true);
    try {
      const {
        data: { access, refresh }
      } = await apiDelivery.post(
        `/controle/cliente/social/${social_backend}/`,
        {
          ...response,
          access_token: response.accessToken
        }
      );
      authDispatcher(setAuthTokens(access, refresh));
      getCliente();

      let imgUrl;

      try {
        if (social_backend === 'google-oauth2') {
          imgUrl = response.profileObj.imageUrl;
        } else if (social_backend === 'facebook') {
          imgUrl = response.picture.data.url;
        }
      } catch (erro) {
        console.log(erro);
        imgUrl = null;
      }

      setAvatar(imgUrl);
    } catch (error) {
      console.log(error);
      abrirNotificacaoErro();
    } finally {
      setLoginLoading(false);
    }
  };

  const isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Instagram') > -1
    );
  };

  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isiOS = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isFbApp = isFacebookApp();

  const propsComponentFB = () => {
    let props = {
      disableMobileRedirect: true
    };

    if (isFbApp) {
      props = {
        disableMobileRedirect: false,
        isMobile: true,
        redirectUri: process.env.REACT_APP_FB_REDIRECT_URL
      };
    }

    return props;
  };

  const propsFB = propsComponentFB();

  const FBComponente = () => {
    if (isiOS && isFbApp) {
      return null;
    }

    return (
      <Form.Item>
        <FacebookLogin
          {...propsFB}
          size="small"
          language="pt"
          buttonStyle={{ width: '100%', height: 32, fontSize: 12 }}
          className="ant-btn"
          textButton="Facebook"
          appId={process.env.REACT_APP_FB_API_ID}
          fields="name,email,picture"
          scope="public_profile,email"
          callback={(res) => responseSocialAuth(res, 'facebook')}
          icon="fa-facebook"
        />
      </Form.Item>
    );
  };

  return (
    <>
      <Form className="form" form={form} onFinish={handleSubmit}>
        <Spin spinning={loginLoading}>
          <Link to="/">
            <div className="logo">
              <img alt="logo" src={logo} />
            </div>
          </Link>

          <Title level={4}>Entre ou cadastre-se com</Title>

          {entrarComEmail && (
            <>
              <Form.Item
                name="email"
                rules={[
                  { message: 'O email é obrigatório.', required: true },
                  { message: 'Digite um email válido.', type: 'email' }
                ]}
              >
                <Input
                  placeholder="Email"
                  prefix={<UserOutlined />}
                  onPaste={() => {
                    setTimeout(() => {
                      form.setFieldsValue({ email: '' });
                    });
                    message.info('Você precisa digitar o email.');
                  }}
                />
              </Form.Item>
              <Form.Item
                name="senha"
                rules={[{ message: 'A senha é obrigatória.', required: true }]}
              >
                <Input.Password placeholder="Senha" prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  htmlType="submit"
                  loading={loginLoading}
                  type="primary"
                >
                  Entrar
                </Button>
              </Form.Item>
            </>
          )}

          <Form.Item>
            <GoogleLogin
              className="btn-google ant-btn ant-btn-block"
              style={{ width: '100%', height: 32, fontSize: 12 }}
              clientId="613848851700-kue8jav2papeikjqp2ng9pc6gbfa33vj.apps.googleusercontent.com"
              buttonText="Google"
              onSuccess={(res) => responseSocialAuth(res, 'google-oauth2')}
              // onFailure={abrirNotificacaoErro}
              cookiePolicy="single_host_origin"
            />
          </Form.Item>

          <FBComponente />

          {!entrarComEmail && (
            <Form.Item>
              <Button
                block
                type="primary"
                onClick={() => {
                  setEntrarComEmail(true);
                }}
              >
                Email
              </Button>
            </Form.Item>
          )}

          <Form.Item>
            <div style={{ textAlign: 'center' }}>
              Ainda não tem cadastro?
              <br />
              <Link to="/cadastro">Cadastre-se</Link>
              <br />
              <br />
              <Link to="/recuperar">Esqueci minha senha.</Link>
              <br />
              <br />
              Ao entrar na plataforma você concorda com os{' '}
              <Link to="/terms">termos de uso</Link>.
            </div>
          </Form.Item>
        </Spin>
      </Form>

      <AcessoFooter />
    </>
  );
};

export default AcessoLogin;
