import React from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const propTypes = {
  pedido: PropTypes.shape({
    itens: PropTypes.arrayOf(
      PropTypes.shape({
        descricao: PropTypes.string,
        quantidade: PropTypes.number
      })
    )
  }).isRequired
};

const defaultProps = {};

const obterPrimeiroProdutoPedido = (pedido) => pedido.itens[0];

const obterDescricaoItensReduzida = (itens) => {
  const qtdProdutosAlemPrimeiro = itens.length - 1;
  if (qtdProdutosAlemPrimeiro === 0) {
    return '';
  }
  return `e mais ${qtdProdutosAlemPrimeiro} ${
    qtdProdutosAlemPrimeiro > 1 ? 'itens' : 'item'
  }`;
};

const PedidoCardDescricaoItens = ({ pedido }) => {
  const primeiroProduto = obterPrimeiroProdutoPedido(pedido);
  const { descricao: descricaoPrimeiroProduto, quantidade } = primeiroProduto;
  const descricaoItensReduzida = obterDescricaoItensReduzida(pedido.itens);
  return (
    /* eslint-disable-next-line react/jsx-fragments */
    <React.Fragment>
      <p>
        {`${quantidade}x ${descricaoPrimeiroProduto} ${descricaoItensReduzida}`}
      </p>
    </React.Fragment>
  );
};

PedidoCardDescricaoItens.propTypes = propTypes;
PedidoCardDescricaoItens.defaultProps = defaultProps;

export default PedidoCardDescricaoItens;
