import { useEffect, useState } from 'react';

import { apiDelivery } from 'services/api';

const defaultSerializer = (formaPagamento) => {
  const {
    descricao,
    idformapgto_str: idformapgto,
    sttipopgto
  } = formaPagamento;
  return { descricao, idformapgto, sttipopgto };
};

const useFormasPagamento = (
  idestabelecimento,
  serializer = defaultSerializer
) => {
  const [error, setError] = useState(false);
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFormasPagamento = (idestabelecimentoSelecionado) => {
    setLoading(true);
    apiDelivery
      .get(`/api/estabelecimento/${idestabelecimentoSelecionado}/formapgto/`)
      .then((response) => response.data)
      .then((_formasPagamento) => _formasPagamento.map(serializer))
      .then(setFormasPagamento)
      .catch((err) => {
        /* eslint-disable-next-line no-console */
        console.log(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (idestabelecimento) {
      getFormasPagamento(idestabelecimento);
    }
  }, [idestabelecimento, serializer]);

  return [formasPagamento, loading, error, { getFormasPagamento }];
};

export default useFormasPagamento;
