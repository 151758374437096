import React from 'react';
import { Form, Select } from 'antd';

import { BANDEIRAS_CARTAO_DELIVERY } from '../../../constants';

const { Option } = Select;

const BandeiraCartaoField = () => (
  <Form.Item
    label="Bandeira"
    name={['cartao', 'bandeira']}
    rules={[{ message: 'Campo obrigatório.', required: true }]}
  >
    <Select style={{ width: '100%' }}>
      {BANDEIRAS_CARTAO_DELIVERY.map((bandeira) => (
        <Option key={bandeira.valor} value={bandeira.valor}>
          {bandeira.descricao}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

export default BandeiraCartaoField;
