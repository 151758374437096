import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Typography,
  message
} from 'antd';

import { apiDelivery } from 'services/api';

import TelefoneInput from 'components/Common/Telefone/Input';

import useCliente from 'hooks/useCliente';
import useCartoes from 'hooks/useCartoes';
import useEnderecos from 'hooks/useEnderecos';

import ClienteEndereco from './Endereco';
import ClienteCartao from './Cartao';

const { Title } = Typography;

const ContentCliente = () => {
  const [form] = Form.useForm();
  const [cliente, clienteLoading, clienteError] = useCliente();
  const [cartoes, cartoesLoading, cartoesError, { getCartoes }] = useCartoes();
  const [
    enderecos,
    enderecosLoading,
    enderecosError,
    { getEnderecos }
  ] = useEnderecos();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { deliveryclienteendereco_set } = cliente;

  useEffect(() => {
    const { id, idcliente, ...dadosCliente } = cliente;
    form.setFieldsValue(dadosCliente);
  }, [cliente, form]);

  const atualizar = (dados) => {
    setLoading(true);
    apiDelivery
      .patch(`/controle/cliente/${cliente.id}/`, dados)
      .then(() => message.success('Dados atualizados com sucesso.'))
      .catch(() => message.error('Erro ao atualizar os dados.'))
      .finally(() => setLoading(false));
  };

  if (clienteError) {
    return (
      <Alert
        type="error"
        message="Erro ao tentar obter os dados. Tente novamente recarregando a página."
      />
    );
  }

  const confirmarExclusao = () => {
    Modal.confirm({
      cancelText: 'Cancelar',
      content: 'Você deseja excluir a sua conta?',
      okText: 'Sim, excluir',
      title: 'Confimação de exclusão de conta',
      onClose: () => null,
      onOk: () => {
        setLoading(true);

        apiDelivery
          .delete(`/controle/cliente/${cliente.id}/`)
          .then(() => {
            message.success('Conta excluída com sucesso.');
          })
          .then(() => {
            history.push('/logout');
          })
          .catch(() => {
            message.error('Erro ao tentar excluir a conta.');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  return (
    <>
      <Spin spinning={loading || clienteLoading}>
        <Title level={2}>Dados do cadastro</Title>
        <Form
          form={form}
          layout="vertical"
          name="cliente-form"
          onFinish={atualizar}
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Nome e sobrenome"
                name="nomeourazaosocial"
                rules={[
                  {
                    message: 'Campo obrigatório.',
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="CPF"
                name="cnpjoucpf"
                rules={[
                  {
                    message: 'O CPF precisa ter 11 dígitos.',
                    pattern: /^\d{11}$/
                  }
                ]}
              >
                <Input maxLength={11} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    message: 'Campo obrigatório.',
                    required: true
                  },
                  {
                    message: 'Digite um email válido.',
                    type: 'email'
                  }
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Telefone"
                name="telefone"
                rules={[
                  {
                    type: 'number',
                    min: 1000000000,
                    message:
                      'Telefone deve ter o DDD e 8 dígitos (fixo) ou 9 dígitos (celular)!'
                  },
                  { required: true, message: 'Telefone é obrigatório' }
                ]}
              >
                <TelefoneInput />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              md={{ offset: 6, span: 12 }}
              lg={{ offset: 8, span: 8 }}
            >
              <Form.Item>
                <Button
                  block
                  htmlType="submit"
                  size="large"
                  style={{ marginTop: 16 }}
                >
                  Atualizar
                </Button>

                <Button
                  block
                  htmlType="button"
                  size="large"
                  style={{ marginTop: 16 }}
                  type="primary"
                  onClick={confirmarExclusao}
                >
                  Excluir Conta
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>

      <Title level={4}>Endereços</Title>

      <Spin spinning={enderecosLoading}>
        <ClienteEndereco onSuccess={getEnderecos} />

        {enderecos.map((endereco) => (
          <ClienteEndereco
            key={endereco.id}
            endereco={endereco}
            onSuccess={getEnderecos}
          />
        ))}
      </Spin>

      <Title level={4}>Cartões</Title>

      <Spin spinning={cartoesLoading}>
        <ClienteCartao onSuccess={getCartoes} />
        {cartoes.map((cartao) => (
          <ClienteCartao
            key={cartao.id}
            cartao={cartao}
            onSuccess={getCartoes}
          />
        ))}
      </Spin>
    </>
  );
};

export default ContentCliente;
