import React, { useState } from 'react';
import {
  Alert,
  Card,
  Button,
  Drawer,
  Form,
  Input,
  notification,
  Popconfirm,
  Tag,
  Typography
} from 'antd';
import { CheckCircleOutlined, CloseCircleFilled } from '@ant-design/icons';

import useDeviceWidth from 'hooks/useDeviceWidth';
import { useCarrinhoContext, setCupomDesconto } from 'contexts/CarrinhoContext';
import { apiDelivery } from 'services/api';

function CupomDrawer({ onClose, visible }) {
  const [erro, setErro] = useState(null);
  const [loading, setLoading] = useState(false);

  const { carrinho, carrinhoDispatcher } = useCarrinhoContext();
  const { estabelecimento, cupom } = carrinho;

  const [form] = Form.useForm();
  const [deviceWidth] = useDeviceWidth();

  const CupomCarrinho = () => {
    if (!cupom) return null;

    function removerCupomDesconto() {
      carrinhoDispatcher(setCupomDesconto(null));
    }

    return (
      <div>
        <Card size="small" style={{ marginBottom: 16 }}>
          <div>
            <Tag
              style={{ fontSize: 24, padding: 6 }}
              icon={<CheckCircleOutlined />}
              color="#87d068"
            >
              {cupom.codigo}
            </Tag>
            <div>
              {!cupom.ehpercentual && 'R$ '}
              {cupom.desconto}
              {cupom.ehpercentual && '%'}
              {' de desconto para os produtos. '}
              {cupom.limitedesconto > 0 && (
                <span>
                  <br />
                  {`Limitado à R$ ${cupom.limitedesconto}.`}
                </span>
              )}
            </div>
          </div>
        </Card>
        <Popconfirm
          title="Remover o cupom de desconto?"
          onConfirm={removerCupomDesconto}
          okText="Sim"
          cancelText="Não"
        >
          <Button danger icon={<CloseCircleFilled />} block>
            Remover
          </Button>
        </Popconfirm>
      </div>
    );
  };

  function onCloseDrawer() {
    form.resetFields();
    setErro(null);
    onClose && onClose();
  }

  const handleSubmit = async (data) => {
    const newData = { ...data, deliveryestabelecimento: estabelecimento.id };
    setErro(null);
    setLoading(true);
    try {
      const result = await apiDelivery.post(`/api/cupom/validar/`, newData);

      notification.success({
        message: 'Cupom de Desconto',
        description: 'Cupom aplicado!',
        duration: 5
      });

      carrinhoDispatcher(setCupomDesconto(result.data));
      onCloseDrawer();
    } catch (error) {
      console.error(error);
      setErro(error.response.data.error);
    }
    setLoading(false);
  };

  const DrawerContent = () => {
    if (cupom) return <CupomCarrinho />;
    return (
      <Form name="cupom-form" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="cupom"
          label="Código do Cupom"
          rules={[
            {
              message: 'Insira um código.',
              required: true
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Button
          block={deviceWidth < 768}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Inserir
        </Button>
      </Form>
    );
  };

  return (
    <Drawer
      width={deviceWidth < 768 ? deviceWidth : 512}
      visible={visible}
      onClose={onCloseDrawer}
    >
      <Typography.Title>Cupom de Desconto</Typography.Title>
      <DrawerContent />
      <div style={{ marginTop: 16 }}>
        {erro && <Alert type="error" message={erro} />}
      </div>
    </Drawer>
  );
}

export default CupomDrawer;
