import React from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import BotaoContinuarComprando from 'components/Common/BotaoContinuarComprando';

import useDeviceWidth from 'hooks/useDeviceWidth';

const propTypes = {
  deliveryestabelecimento: PropTypes.string,
  onRedirect: PropTypes.func
};

const defaultProps = {
  deliveryestabelecimento: null,
  onRedirect: null
};

const BotaoContinuarComprandoMobile = ({
  deliveryestabelecimento,
  onRedirect
}) => {
  const [width] = useDeviceWidth();

  if (width > 576) {
    return null;
  }

  return (
    <BotaoContinuarComprando
      deliveryestabelecimento={deliveryestabelecimento}
      onRedirect={onRedirect}
    />
  );
};

BotaoContinuarComprandoMobile.propTypes = propTypes;
BotaoContinuarComprandoMobile.defaultProps = defaultProps;

export default BotaoContinuarComprandoMobile;
