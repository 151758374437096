import React, { useEffect, useState } from 'react';
import { Form, Radio, Typography } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { obterTipoCartaoPeloSttipopgto } from './utils';

import {
  STTIPOPGTO_PIX,
  VALOR_TIPO_CREDITO,
  VALOR_TIPO_DEBITO
} from '../../../constants';

const { Text } = Typography;

const propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  formasPagamento: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string,
      idformapgto: PropTypes.string
    })
  )
};

const defaultProps = {
  formasPagamento: []
};

const TipoCartaoField = ({ form, formasPagamento }) => {
  const [isCampoDesabilitado, setCampoDesabilitado] = useState(false);
  const [isDebitoSelecionado, setDebitoSelecionado] = useState(false);

  useEffect(() => {
    const isNotFormaPgtoPix = (formaPagamento) => {
      return formaPagamento.sttipopgto !== STTIPOPGTO_PIX;
    };
    const formasPagamentoCartao = formasPagamento.filter(isNotFormaPgtoPix);

    if (formasPagamentoCartao.length === 1) {
      const [{ sttipopgto }] = formasPagamentoCartao;
      const tipo = obterTipoCartaoPeloSttipopgto(sttipopgto);
      form.setFieldsValue({
        cartao: { tipo }
      });
      setCampoDesabilitado(true);
      setDebitoSelecionado(tipo === VALOR_TIPO_DEBITO);
    } else if (formasPagamentoCartao.length > 1) {
      /* FIXME: problema com a validação do campo após a inserção do atributo help */
      /* torna a opção crédito como padrão */
      form.setFieldsValue({
        cartao: { tipo: VALOR_TIPO_CREDITO }
      });
    }
  }, [form, formasPagamento]);

  const verificarSeDebitoSelecionado = (event) => {
    setDebitoSelecionado(event.target.value === VALOR_TIPO_DEBITO);
  };

  return (
    <Form.Item
      help={
        isDebitoSelecionado && (
          <Text type="danger">
            O pagamento com débito aceita somente as bandeiras Mastercard e
            Visa.
          </Text>
        )
      }
      label="Tipo"
      name={['cartao', 'tipo']}
      rules={[{ message: 'Campo obrigatório.', required: true }]}
    >
      <Radio.Group
        disabled={isCampoDesabilitado}
        onChange={verificarSeDebitoSelecionado}
      >
        <Radio value={VALOR_TIPO_CREDITO}>Crédito</Radio>
        <Radio value={VALOR_TIPO_DEBITO}>Débito</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

TipoCartaoField.propTypes = propTypes;
TipoCartaoField.defaultProps = defaultProps;

export default TipoCartaoField;
