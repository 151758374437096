import React from 'react';
import { Alert, Col, Row, Typography } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import CopiarQRCodePixAreaTransferencia from './CopiarQRCodePixAreaTransferencia';

import useDeviceCheck from './useDeviceCheck';

const { Paragraph } = Typography;

const propTypes = {
  imagemQrcode: PropTypes.string.isRequired,
  qrcode: PropTypes.string.isRequired
};

const PagarOnlinePixQRCode = ({ imagemQrcode, qrcode }) => {
  const isMobileDevice = useDeviceCheck();

  if (!imagemQrcode || !qrcode) {
    return (
      <Alert
        message="Houve um problema ao tentar obter o QRCode para o pagamento."
        showIcon
        style={{ marginBottom: 16 }}
        type="error"
      />
    );
  }

  return (
    <Row>
      <Col xs={{ span: 24 }} md={{ offset: 9, span: 6 }}>
        <CopiarQRCodePixAreaTransferencia qrcode={qrcode} />
      </Col>

      {!isMobileDevice && (
        <Col xs={{ span: 24 }} md={{ offset: 9, span: 6 }}>
          <Paragraph>Ou escaneie o QRCode para pagar:</Paragraph>
          <img alt="imagem-qrcode" src={imagemQrcode} width="100%" />
        </Col>
      )}
    </Row>
  );
};

PagarOnlinePixQRCode.propTypes = propTypes;

export default PagarOnlinePixQRCode;
