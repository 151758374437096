import { apiDelivery } from 'services/api';
import { useEffect, useState } from 'react';

const defaultSerializer = (endereco) => {
  const {
    bairro,
    cep,
    cidade,
    complemento,
    detalhe,
    id,
    idtipoendereco,
    numero,
    rua,
    uf
  } = endereco;
  return {
    bairro,
    cep,
    cidade,
    complemento,
    detalhe,
    id,
    idtipoendereco,
    numero,
    rua,
    uf
  };
};

const useEnderecos = (serializer = defaultSerializer) => {
  const [enderecos, setEnderecos] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getEnderecos = () => {
    setLoading(true);
    apiDelivery
      .get('/controle/endereco/')
      .then((response) => response.data)
      .then((enderecos) => enderecos.map(serializer))
      .then(setEnderecos)
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(getEnderecos, [serializer]);

  return [enderecos, loading, error, { getEnderecos }];
};

export default useEnderecos;
