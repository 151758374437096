import './Acesso.css';

import { Button, Form, Input, Spin, Typography, message } from 'antd';
import { CheckOutlined, LeftOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useState } from 'react';

import AcessoFooter from './AcessoFooter';
import { apiDelivery } from 'services/api';
import { svgLogoEAD } from 'assets';
import { tratarErro } from 'utils/tratarErros';

export default function AcessoNovaSenha() {
  const { clienteUuid, token } = useParams();
  const { Text } = Typography;
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = async (values) => {
    setLoading(true);
    const { senha } = values;
    try {
      await apiDelivery.post(
        `/controle/cliente/${clienteUuid}/resetar-senha/${token}/`,
        { senha }
      );
      message.success(`Senha alterada com sucesso!`, 3);
      history.push({
        pathname: '/login'
      });
    } catch (err) {
      const errorMsg = tratarErro(err);
      message.error(errorMsg);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <div style={{ position: 'fixed', top: 32, left: 32 }}>
        <Link to="/login">
          <Button icon={<LeftOutlined />} shape="circle" size="large" />
        </Link>
      </div>
      <Form onFinish={handleSubmit} className="form">
        <Spin spinning={loading}>
          <div className="logo">
            <img alt="logo" src={svgLogoEAD} />
          </div>

          <div style={{ textAlign: 'center', marginBottom: '16px' }}>
            <Text type="secondary">Cadastrar nova senha</Text>
          </div>

          <Form.Item
            name="senha"
            hasFeedback
            rules={[
              { message: 'A senha é obrigatória.', required: true },
              { message: 'Mínimo de 4 caracteres.', min: 4, type: 'string' }
            ]}
          >
            <Input.Password
              autoComplete="off"
              placeholder="Senha"
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={['senha']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Por favor, confirme sua senha!'
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('senha') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('As duas senhas não combinam!');
                }
              })
            ]}
          >
            <Input.Password
              prefix={<CheckOutlined />}
              placeholder="Confirmar senha"
            />
          </Form.Item>

          <Form.Item>
            <Button block htmlType="submit" type="primary">
              Alterar
            </Button>
          </Form.Item>
          <div style={{ textAlign: 'center' }}>
            Não quer alterar? Volte para <Link to="/login">Login</Link>
          </div>
        </Spin>
      </Form>

      <AcessoFooter />
    </>
  );
}