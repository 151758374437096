import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import useCupomFiscal from './useCupomFiscal';

const propTypes = {
  idpedido: PropTypes.string.isRequired
};

const defaultProps = {};

const CupomFiscal = ({ idpedido }) => {
  const [cupomFiscal, loading, error] = useCupomFiscal(idpedido);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (cupomFiscal) {
      document.getElementById('cupom-fiscal').innerHTML = cupomFiscal;
    }
  }, [cupomFiscal]);

  const abrirModal = () => {
    setVisible(true);
  };

  const fecharModal = () => {
    setVisible(false);
  };

  if (error) {
    return (
      <Alert
        message="Não foi possível exibir o cupom do pedido. Tente novamente recarregando a página."
        showIcon
        type="error"
      />
    );
  }

  return (
    <>
      <Button loading={loading} type="primary" onClick={abrirModal}>
        Exibir cupom do pedido
      </Button>

      <Modal
        centered
        footer={null}
        forceRender
        title="Cupom fiscal do pedido"
        width={800}
        visible={visible}
        onCancel={fecharModal}
      >
        <div id="cupom-fiscal" />
      </Modal>
    </>
  );
};

CupomFiscal.propTypes = propTypes;
CupomFiscal.defaultProps = defaultProps;

export default CupomFiscal;
