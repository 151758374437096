import './Acesso.css';

import {
  Button,
  Form,
  Input,
  Spin,
  Typography,
  message,
  notification
} from 'antd';
import {
  CheckOutlined,
  LeftOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import TelefoneInput from 'components/Common/Telefone/Input';
import { apiDelivery } from 'services/api';
import logo from 'assets/logo_ead.svg';
import { tratarErro } from 'utils/tratarErros';
import AcessoFooter from './AcessoFooter';

const url = '/controle/cliente/';
export default function AcessoCadastro() {
  const [form] = Form.useForm();
  const { Text } = Typography;
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const key = `open${Date.now()}`;

  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        notification.close(key);
        return history.push({
          pathname: '/login'
        });
      }}
    >
      Ir para Login
    </Button>
  );

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await apiDelivery.post(url, values, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json'
        }
      });

      notification.open({
        message: 'Sucesso!!',
        duration: 30,
        description: `Um email de confirmação foi enviado para ${values.email}!`,
        btn,
        key
      });
    } catch (err) {
      const errorMsg = tratarErro(err, 'email');
      message.error(errorMsg);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <div style={{ position: 'fixed', top: 32, left: 32 }}>
        <Link to="/login">
          <Button icon={<LeftOutlined />} shape="circle" size="large" />
        </Link>
      </div>

      <Form className="form-cadastro" form={form} onFinish={handleSubmit}>
        <Spin spinning={loading}>
          <div className="logo">
            <img alt="logo" src={logo} />
          </div>
          <div style={{ alignItems: 'center' }}>
            <Text type="secondary">Preencha os dados se cadastrar</Text>
          </div>
          <Form.Item
            name="nomeourazaosocial"
            rules={[{ message: 'Seu nome é obrigatório.', required: true }]}
          >
            <Input
              autoComplete="off"
              placeholder="Nome e Sobrenome"
              prefix={<UserOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { message: 'O email é obrigatório.', required: true },
              { message: 'Digite um email válido.', type: 'email' }
            ]}
          >
            <Input
              autoComplete="off"
              placeholder="Email"
              prefix={<MailOutlined />}
              onPaste={() => {
                setTimeout(() => {
                  form.setFieldsValue({ email: '' });
                });
                message.info('Você precisa digitar o email.');
              }}
            />
          </Form.Item>
          <Form.Item
            name="telefone"
            rules={[
              {
                type: 'number',
                min: 1000000000,
                message: 'Telefone deve ter o DDD e 8 dígitos (fixo) ou 9 dígitos (celular)!'
              },
              { required: true, message: 'Telefone é obrigatório' }
            ]}
          >
            <TelefoneInput
              allowClear
              autoComplete="off"
              prefix={<PhoneOutlined />}
            />
          </Form.Item>

          <Form.Item
            name="senha"
            hasFeedback
            rules={[
              { message: 'A senha é obrigatória.', required: true },
              { message: 'Mínimo de 4 caracteres.', min: 4, type: 'string' }
            ]}
          >
            <Input.Password
              autoComplete="off"
              placeholder="Senha"
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={['senha']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Por favor, confirme sua senha!'
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('senha') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('As duas senhas não combinam!');
                }
              })
            ]}
          >
            <Input.Password
              prefix={<CheckOutlined />}
              placeholder="Confirmar senha"
            />
          </Form.Item>

          <Form.Item>
            <Button block htmlType="submit" type="primary">
              Cadastrar
            </Button>
          </Form.Item>
          <div style={{ textAlign: 'center' }}>
            Já tem cadastro? Faça <Link to="/login">Login</Link>
          </div>
        </Spin>
      </Form>
      <AcessoFooter />
    </>
  );
}
