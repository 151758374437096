import React from 'react';
import { Button } from 'antd';

import LgpdModal from '../Modal';

import usePoliticaCookies from './usePoliticaCookies';

import './LgpdBanner.css';

const Banner = () => {
  const [isCookiesAceitos, setCookiesComoAceitos] = usePoliticaCookies();

  if (isCookiesAceitos) {
    return null;
  }

  const aceitarCookies = () => {
    setCookiesComoAceitos();
  };

  return (
    <div className="lgpd-banner">
      <div className="lgpd-banner-description">
        <p className="lgpd-banner-description-text">
          Utilizamos cookies para melhorar a sua experiência no sistema
          EADelivery. Os cookies que usamos são necessários para o funcionamento
          do sistema. Para acessar nosso sistema é necessário aceitar sua
          utilização.
        </p>
      </div>

      <div className="lgpd-banner-action">
        <Button style={{ marginRight: 16 }} onClick={aceitarCookies}>
          Ok
        </Button>
        <LgpdModal />
      </div>
    </div>
  );
};

export default Banner;
