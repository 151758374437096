import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Input, Switch, Radio, Row } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import CartaoForm from './CartaoForm';
import CartoesSalvosList from './CartoesSalvosList';
import ImagemSeloSegurancaCielo from './ImagemSeloSegurancaCielo';
import TipoCartaoField from './TipoCartaoField';

const propTypes = {
  cartoes: PropTypes.arrayOf(
    PropTypes.shape({
      token: PropTypes.string
    })
  ),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  formasPagamento: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string,
      idformapgto: PropTypes.string
    })
  )
};

const defaultProps = {
  cartoes: [],
  formasPagamento: []
};

const PagarOnlineCartaoForm = ({ cartoes, form, formasPagamento }) => {
  const [
    tokenCartaoSalvoSelecionado,
    setTokenCartaoSalvoSelecionado
  ] = useState('');

  const autoSelecionarPrimeiroCartaoSalvo = () => {
    if (cartoes.length > 0) {
      const [primeiroCartaoSalvo] = cartoes;
      form.setFieldsValue({
        cartao: {
          token: primeiroCartaoSalvo?.token,
          bandeira: primeiroCartaoSalvo?.bandeira || null
        }
      });
      setTokenCartaoSalvoSelecionado(primeiroCartaoSalvo?.token);
    }
  };

  const definirTokenSelecionadoEResetarCampoCvv = (event) => {
    const tokenCartaoSelecionado = event.target.value;
    setTokenCartaoSalvoSelecionado(tokenCartaoSelecionado);
    const cartaoSelecionado = cartoes.find(
      (cartao) => cartao.token === tokenCartaoSelecionado
    );
    /* FIXME: isso é uma forma de efeito colateral */
    form.setFieldsValue({
      cartao: {
        cvv: null,
        bandeira: cartaoSelecionado?.bandeira || null
      }
    });
  };

  useEffect(autoSelecionarPrimeiroCartaoSalvo, [cartoes]);

  return (
    /* eslint-disable-next-line react/jsx-fragments */
    <Fragment>
      {cartoes.length > 0 && (
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Cartões salvos" name={['cartao', 'token']}>
              <Radio.Group
                style={{ width: '100%' }}
                onChange={definirTokenSelecionadoEResetarCampoCvv}
              >
                <CartoesSalvosList cartoes={cartoes} />
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}

      {tokenCartaoSalvoSelecionado !== '' && (
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <TipoCartaoField form={form} formasPagamento={formasPagamento} />
          </Col>

          <Form.Item hidden name={['cartao', 'bandeira']}>
            <Input />
          </Form.Item>

          <Col xs={24} md={12}>
            <Form.Item
              label="CVV"
              name={['cartao', 'cvv']}
              rules={[
                {
                  message: 'Campo obrigatório.',
                  required: true
                },
                {
                  message: 'Digite um número com 3 ou 4 dígitos.',
                  pattern: /^\d{3,4}$/
                }
              ]}
            >
              <Input maxLength={4} inputmode="numeric" pattern="[0-9]*" />
            </Form.Item>
          </Col>
        </Row>
      )}

      {tokenCartaoSalvoSelecionado === '' && (
        /* eslint-disable-next-line react/jsx-fragments */
        <Fragment>
          <CartaoForm form={form} />

          <Row gutter={16}>
            <Col xs={24} md={12}>
              <TipoCartaoField form={form} formasPagamento={formasPagamento} />
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="CVV"
                name={['cartao', 'cvv']}
                rules={[
                  {
                    message: 'Campo obrigatório.',
                    required: true
                  },
                  {
                    message: 'Digite um número com 3 ou 4 dígitos.',
                    pattern: /^\d{3,4}$/
                  }
                ]}
              >
                <Input maxLength={4} inputmode="numeric" pattern="[0-9]*" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Guardar as informações para compras futuras"
                name={['cartao', 'salvar']}
                valueProp="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      )}

      <ImagemSeloSegurancaCielo />
    </Fragment>
  );
};

PagarOnlineCartaoForm.propTypes = propTypes;
PagarOnlineCartaoForm.defaultProps = defaultProps;

export default PagarOnlineCartaoForm;
