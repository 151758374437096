import React, { useCallback, useState, useEffect } from 'react';

import { Alert, Button, Card, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import {
  obterValorMonetario,
  obterValorProduto,
  obterValorCupomDesconto,
  obterValorTotal
} from 'services/precos';
import CardapioProdutoOpcoes from 'components/Delivery/Layout/Content/Cardapio/Produto/Opcoes';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { multiplicar_decimal } from 'utils/decimal';
import {
  ItemCarrinhoDescricaoBPMPI,
  ItemCarrinhoNomeBPMPI,
  ItemCarrinhoSKUBPMPI,
  ItemCarrinhoQuantidadeBPMPI,
  ItemCarrinhoPrecoUnitBPMPI,
  TotalAmountBPMPI
} from 'utils/InputBPMPI';

import CupomDrawer from './Cupom/Drawer';

const { Meta } = Card;
const { Paragraph } = Typography;

const FinalizarPedidoResumo = ({
  cupom,
  enderecoEstabelecimento,
  enderecoCliente,
  itens,
  onContinuarComprandoClicked,
  subtotal,
  taxaentrega,
  tipoEntrega,
  title,
  abono
}) => {
  const [cupomDrawerVisible, setCupomDrawerVisible] = useState(false);
  const [valorTotal, setValorTotal] = useState(0);
  const [deviceWidth] = useDeviceWidth();

  const EnderecoParagraph = () => {
    let header = 'Escolha o tipo de entrega.';
    if (tipoEntrega.delivery) {
      header = 'Pedido vai ser entregue em:';
    } else if (tipoEntrega.togo) {
      header = 'Retirar pedido em:';
    }

    const Endereco = () => {
      if (tipoEntrega.delivery) {
        if (enderecoCliente && taxaentrega) {
          return (
            <>
              {`${enderecoCliente.rua}, ${enderecoCliente.numero} - ${enderecoCliente.bairro}`}
              {enderecoCliente.complemento &&
                ` - ${enderecoCliente.complemento}`}
            </>
          );
        }
        return 'Escolha um endereço válido para entrega';
      }
      if (tipoEntrega.togo && enderecoEstabelecimento) {
        return `${enderecoEstabelecimento.rua}, ${enderecoEstabelecimento.numero} - ${enderecoEstabelecimento.bairro}`;
      }
      return null;
    };

    const Cidade = () => {
      if (tipoEntrega.delivery && enderecoCliente && taxaentrega) {
        return `${enderecoCliente.cidade} - ${enderecoCliente.uf}`;
      }

      if (tipoEntrega.togo && enderecoEstabelecimento) {
        const { cidade } = enderecoEstabelecimento;
        if (cidade) {
          return `${cidade.descricao} - ${cidade.uf.descricao}`;
        }
      }

      return null;
    };

    const Content = () => {
      if (!tipoEntrega.delivery && !tipoEntrega.togo) {
        return null;
      }

      return (
        <>
          <br />
          <span>
            <Endereco /> <br /> <Cidade />
          </span>
        </>
      );
    };

    return (
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <span>{header}</span>
        <Content />
      </div>
    );
  };

  const ResumoItem = ({ index, item }) => {
    const valorProduto = obterValorProduto(item);
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <div>
          <span>
            {item.quantidade}x {item.descricao}
          </span>
          <ItemCarrinhoNomeBPMPI index={index} value={item.descricao} />
          <ItemCarrinhoDescricaoBPMPI index={index} value={item.detalhe || ''} />
          <ItemCarrinhoQuantidadeBPMPI index={index} value={item.quantidade} />
          <ItemCarrinhoSKUBPMPI index={index} value={item.idproduto} />

          <div style={{ paddingLeft: 16 }}>
            <CardapioProdutoOpcoes produto={item} />
          </div>
          {item.observacoes && <div>Observações: {item.observacoes}</div>}
        </div>
        <div>
          <div>{obterValorMonetario(valorProduto)}</div>
          <ItemCarrinhoPrecoUnitBPMPI
            index={index}
            value={multiplicar_decimal(valorProduto / (item?.quantidade || 1), 100)}
          />
        </div>
      </div>
    );
  };

  const valorCupomDesconto = obterValorCupomDesconto(cupom, subtotal);

  const checkAbono = typeof abono === 'number' && subtotal >= abono;

  const TextTaxaEntrega = useCallback(() => {
    if (tipoEntrega.delivery) {
      if (taxaentrega === null) {
        return 'Escolha o endereço de entrega';
      }
      if (checkAbono) {
        return <del>{obterValorMonetario(taxaentrega)}</del>;
      }
      return obterValorMonetario(taxaentrega);
    }

    if (tipoEntrega.togo) {
      return 'Retirar no estabelecimento';
    }

    return 'Escolha o tipo de entrega';
  }, [tipoEntrega, taxaentrega, subtotal]);

  useEffect(() => {
    let taxa_entrega = 0;

    if (taxaentrega && tipoEntrega.delivery) taxa_entrega = taxaentrega || 0;

    const total = checkAbono
      ? obterValorTotal(subtotal, 0, valorCupomDesconto)
      : obterValorTotal(subtotal, taxa_entrega, valorCupomDesconto);

    setValorTotal(total);
  }, [taxaentrega, subtotal, tipoEntrega, valorCupomDesconto]);

  return (
    <>
      <Card size="small" style={{ marginBottom: 16, marginTop: 16 }}>
        <Meta title={title} />

        <EnderecoParagraph />
        <hr />

        <Paragraph>
          {itens.map((item, index) => (
            <ResumoItem index={index + 1} item={item} />
          ))}
        </Paragraph>

        <Button
          block={deviceWidth < 768}
          style={{ fontWeight: 'bold' }}
          type="link"
          onClick={onContinuarComprandoClicked}
        >
          Continuar comprando
        </Button>

        <hr />
        {/* {typeof taxaentrega === 'number' && ( */}
        {/* <> */}

        {abono !== 0 && typeof abono === 'number' ? (
          subtotal <= abono ? (
            <Alert
              type="success"
              message={`Entrega Grátis com o mínimo de ${obterValorMonetario(
                abono
              )}!`}
            />
          ) : (
            <Alert type="success" message={`Entrega grátis!`} />
          )
        ) : null}

        <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Subtotal:</div>
          <div>{obterValorMonetario(subtotal)} </div>
        </Paragraph>

        {cupom && (
          <Paragraph
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>Cupom de desconto:</div>
            <div>{`- ${obterValorMonetario(valorCupomDesconto)}`}</div>
          </Paragraph>
        )}

        <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Taxa de entrega:</div>
          <div>
            <TextTaxaEntrega />
            {/* {taxaentrega === 0 ? 'Grátis' : obterValorMonetario(taxaentrega)} */}
          </div>
        </Paragraph>

        <Paragraph style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <strong>Total:</strong>
          </div>
          <div>
            {obterValorMonetario(valorTotal)}
            <TotalAmountBPMPI value={multiplicar_decimal(valorTotal, 100)} />
          </div>
        </Paragraph>
      </Card>

      <Button
        style={{
          display: deviceWidth < 768 ? 'flex' : '',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        block={deviceWidth < 768}
        type="primary"
        size="large"
        onClick={() => setCupomDrawerVisible(true)}
      >
        Cupom de desconto
        <RightOutlined />
      </Button>

      <CupomDrawer
        visible={cupomDrawerVisible}
        onClose={() => setCupomDrawerVisible(false)}
      />
    </>
  );
};

export default FinalizarPedidoResumo;
