import 'App.css';
import 'moment/locale/pt-br';

import React, { useEffect } from 'react';
import { message, ConfigProvider } from 'antd';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ptBR from 'antd/es/locale/pt_BR';
import {
  AcessoCadastro,
  AcessoEmailConfirmado,
  AcessoLogin,
  AcessoLogout,
  AcessoNovaSenha,
  AcessoRecuperarSenha
} from 'components/Acesso';
import Delivery from 'components/Delivery';
import AcessoRedirectRoute from 'components/Acesso/AcessoRedirectRoute';

import {
  AuthProvider,
  KEY_ACCESS_TOKEN,
  KEY_REFRESH_TOKEN
} from 'contexts/AuthContext';
import {
  FiltroEstabelecimentoProvider,
  KEY_FILTRO_ESTAB
} from 'contexts/FiltroEstabelecimentoContext';
import { EnderecoProvider, KEY_ENDERECO } from 'contexts/EnderecoContext';
import { KEY_CARRINHO } from 'contexts/CarrinhoContext';
import { ClienteProvider } from 'contexts/ClienteContext';
import Privacidade from 'components/Docs/Privacidade';
import Termos from 'components/Docs/Termos';
import AguardandoPgto from 'components/AguardandoPgto';

import NaoEncontrado from 'components/Delivery/NaoEncontrado';

const App = () => {
  const KEY_VERSAO_APP = 'versao';
  const VERSAO_APP = '1';

  function limparLocalStorageVersao() {
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    localStorage.removeItem(KEY_REFRESH_TOKEN);
    localStorage.removeItem(KEY_ENDERECO);
    localStorage.removeItem(KEY_FILTRO_ESTAB);
    localStorage.removeItem(KEY_CARRINHO);
  }

  useEffect(() => {
    let limpar = false;
    if (KEY_VERSAO_APP in localStorage) {
      if (localStorage.getItem(KEY_VERSAO_APP) !== VERSAO_APP) {
        limpar = true;
        message.info(
          'Nosso site foi atualizado, talvez você tenha que fazer login novamente'
        );
      }
    } else {
      limpar = true;
    }

    if (limpar) {
      limparLocalStorageVersao();
      localStorage.setItem(KEY_VERSAO_APP, VERSAO_APP);
    }
  }, []);

  return (
    <div className="app">
      <ConfigProvider locale={ptBR}>
        <AuthProvider>
          <ClienteProvider>
            <FiltroEstabelecimentoProvider>
              <EnderecoProvider>
                <Router>
                  <Switch>
                    <Route exact path="/">
                      <NaoEncontrado />
                    </Route>

                    <Route path="/privacy">
                      <Privacidade />
                    </Route>

                    <Route path="/terms">
                      <Termos />
                    </Route>

                    <Route path="/aguardando-pagamento">
                      <AguardandoPgto />
                    </Route>

                    <AcessoRedirectRoute path="/login">
                      <AcessoLogin />
                    </AcessoRedirectRoute>

                    <AcessoRedirectRoute path="/recuperar">
                      <AcessoRecuperarSenha />
                    </AcessoRedirectRoute>

                    <AcessoRedirectRoute path="/cadastro">
                      <AcessoCadastro />
                    </AcessoRedirectRoute>

                    <Route path="/resetar-senha/:clienteUuid/:token">
                      <AcessoNovaSenha />
                    </Route>

                    <Route path="/confirma/:clienteUuid/:token">
                      <AcessoEmailConfirmado />
                    </Route>

                    <Route path="/logout">
                      <AcessoLogout />
                    </Route>

                    <Route path="/">
                      <Delivery />
                    </Route>
                  </Switch>
                </Router>
              </EnderecoProvider>
            </FiltroEstabelecimentoProvider>
          </ClienteProvider>
        </AuthProvider>
      </ConfigProvider>
    </div>
  );
};

export default App;
