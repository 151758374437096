import {
  STTIPOPGTO_EAPAY_CREDITO,
  STTIPOPGTO_EAPAY_DEBITO,
  VALOR_TIPO_CREDITO,
  VALOR_TIPO_DEBITO,
  VALOR_BANDEIRA_MASTER,
  VALOR_BANDEIRA_VISA
} from '../../../constants';

export const obterTipoCartaoPeloSttipopgto = (sttipopgto) => {
  if (sttipopgto === STTIPOPGTO_EAPAY_CREDITO) {
    return VALOR_TIPO_CREDITO;
  }

  if (sttipopgto === STTIPOPGTO_EAPAY_DEBITO) {
    return VALOR_TIPO_DEBITO;
  }

  /* TODO: adicionar uma mensagem de erro */
  throw new Error();
};

class BandeiraException extends Error {
  constructor(message) {
    super(message);
    this.name = 'BandeiraException';
  }
}

export const validarBandeiraDebito = (dadosCartao) => {
  if (!dadosCartao) return;

  if (dadosCartao?.tipo === VALOR_TIPO_DEBITO) {
    const BANDEIRAS_PERMITIDAS_DEBITO = [
      VALOR_BANDEIRA_MASTER,
      VALOR_BANDEIRA_VISA
    ];

    if (dadosCartao?.bandeira && !BANDEIRAS_PERMITIDAS_DEBITO.includes(dadosCartao?.bandeira))
      throw new BandeiraException(
        `Para débito, por favor, utilize Mastercard ou Visa.`
      );
  }
};
