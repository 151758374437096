import React, { useEffect, useState } from 'react';
import { message, Result } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import PagarOnlinePixQRCode from '../../FinalizarPedido/components/PagarOnlineForm/PagarOnlinePixForm/PagarOnlinePixQRCode';

import useStatusPgto, {
  PgtoNaoEfetuadoError
} from '../../FinalizarPedido/useStatusPgto';

const propTypes = {
  transacoes: PropTypes.arrayOf(
    PropTypes.shape({
      imagem_qrcode: PropTypes.string,
      qrcode: PropTypes.string
    })
  ).isRequired
};

const AvisoEsperandoPagamentoPix = ({ transacoes }) => {
  const [isTransacaoFinalizada, setTransacaoFinalizada] = useState(false);
  const [transacao] = transacoes;
  const { deliveryestabelecimento, imagem_qrcode, pedido, qrcode } = transacao;
  const [checarStatusPgtoPoll, interromperPollStatusPgto] = useStatusPgto(
    deliveryestabelecimento
  );

  const notificarPgtoEfetuado = () => {
    message.success('Pagamento efetuado com sucesso.', 10);
  };

  const notificarErroPgto = () => {
    message.error('Pagamento não efetuado.', 10);
  };

  const interromperPollStatusPgtoAoDesmontarComponente = () => {
    return () => {
      interromperPollStatusPgto();
    };
  };

  useEffect(interromperPollStatusPgtoAoDesmontarComponente);

  useEffect(() => {
    if (pedido) {
      checarStatusPgtoPoll(pedido)
        .then(notificarPgtoEfetuado)
        .catch((error) => {
          if (error instanceof PgtoNaoEfetuadoError) {
            notificarErroPgto();
          }
        })
        .finally(() => {
          setTransacaoFinalizada(true);
        });
    }
  }, [pedido]);

  if (isTransacaoFinalizada) {
    return null;
  }

  return (
    <Result
      extra={
        <PagarOnlinePixQRCode imagemQrcode={imagem_qrcode} qrcode={qrcode} />
      }
      status="warning"
      style={{ marginBottom: 16 }}
      title="Pagamento com PIX pendente"
    />
  );
};

AvisoEsperandoPagamentoPix.propTypes = propTypes;

const isTransacoesIguais = (prevProps, nextProps) => {
  const [transacaoAntiga] = prevProps.transacoes;
  const [transacaoNova] = nextProps.transacoes;
  return (
    transacaoAntiga.deliveryestabelecimento ===
      transacaoNova.deliveryestabelecimento &&
    transacaoAntiga.pedido === transacaoNova.pedido
  );
};

export default React.memo(AvisoEsperandoPagamentoPix, isTransacoesIguais);
