import React, { Fragment, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import TrocoField from '../TrocoField';

import { STTIPOPGTO_DINHEIRO } from '../../../constants';

const { Option } = Select;

const propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  formasPagamento: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string,
      idformapgto: PropTypes.string
    })
  ),
  valorTotal: PropTypes.number.isRequired
};

const defaultProps = {
  formasPagamento: []
};

const FormasPagamentoField = ({ form, formasPagamento, valorTotal }) => {
  const [
    isDinheiroComoFormaPagamento,
    setDinheiroComoFormaPagamento
  ] = useState(false);
  const [isCampoDesabilitado, setCampoDesabilitado] = useState(false);

  const verificarFormaPagamentoSelecionadaIsDinheiro = (
    idFormaPgtoSelecionada
  ) => {
    const formaPagamentoSelecionada = formasPagamento.find(
      (formaPagamento) => formaPagamento.idformapgto === idFormaPgtoSelecionada
    );
    setDinheiroComoFormaPagamento(
      formaPagamentoSelecionada.sttipopgto === STTIPOPGTO_DINHEIRO
    );
  };

  useEffect(() => {
    if (formasPagamento.length === 1) {
      const [{ idformapgto, sttipopgto }] = formasPagamento;
      form.setFieldsValue({ formapgto_id: idformapgto });
      setDinheiroComoFormaPagamento(sttipopgto === STTIPOPGTO_DINHEIRO);
      setCampoDesabilitado(true);
    } else {
      form.setFieldsValue({ formapgto_id: null });
    }
  }, [form, formasPagamento]);

  return (
    /* eslint-disable-next-line react/jsx-fragments */
    <Fragment>
      <Form.Item
        label="Forma de pagamento"
        name="formapgto_id"
        rules={[{ message: 'Campo obrigatório.', required: true }]}
      >
        <Select
          disabled={isCampoDesabilitado}
          style={{ width: '100%' }}
          onChange={verificarFormaPagamentoSelecionadaIsDinheiro}
        >
          {formasPagamento.map((formaPagamento) => {
            const { descricao, idformapgto } = formaPagamento;
            return (
              <Option key={idformapgto} value={idformapgto}>
                {descricao}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      {isDinheiroComoFormaPagamento && <TrocoField valorTotal={valorTotal} />}
    </Fragment>
  );
};

FormasPagamentoField.propTypes = propTypes;
FormasPagamentoField.defaultProps = defaultProps;

export default FormasPagamentoField;
