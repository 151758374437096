import React from 'react';

import { Tag, Typography } from 'antd';

/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { obterValorMonetario, obterValorOpcao } from 'services/precos';

const { Text } = Typography;

const propTypes = {
  isPrecoOpcaoPraticado: PropTypes.bool,
  opcao: PropTypes.shape({
    preco: PropTypes.number,
    precooferta: PropTypes.number
  }).isRequired,
  valorAfetaTudo: PropTypes.number
};

const defaultProps = {
  isPrecoOpcaoPraticado: false,
  valorAfetaTudo: 0
};

const PrecoOpcao = ({ isPrecoOpcaoPraticado, opcao, valorAfetaTudo }) => {
  if (!opcao?.preco && !opcao?.precooferta) {
    return <span />;
  }

  const isPrecoOfertaDefinido =
    typeof opcao?.precooferta === 'number' && opcao?.precooferta > 0;

  return (
    <div data-testid="preco-oferta">
      {isPrecoOfertaDefinido && (
        <Text
          data-testid="preco-oferta-valor-original"
          delete
          style={{ marginRight: 8 }}
        >
          {obterValorMonetario(
            obterValorOpcao({ ...opcao, precooferta: null }, valorAfetaTudo)
          )}
        </Text>
      )}

      <Text style={isPrecoOfertaDefinido ? { color: '#52c41a' } : null}>
        {isPrecoOpcaoPraticado && '+ '}
        {obterValorMonetario(obterValorOpcao(opcao, valorAfetaTudo))}
      </Text>

      {isPrecoOfertaDefinido && (
        <Tag color="green" style={{ marginLeft: 8 }}>
          OFERTA
        </Tag>
      )}
    </div>
  );
};

PrecoOpcao.propTypes = propTypes;
PrecoOpcao.defaultProps = defaultProps;

export default PrecoOpcao;
