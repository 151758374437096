import React from 'react';
import { InputNumber } from 'antd';
import PropTypes from 'prop-types';

function TelefoneInput({ formatarFixoECelular, ...props }) {
  return (
    <InputNumber
      {...props}
      className="sem-handler"
      style={{ width: '100%' }}
      placeholder="(99) 99999-9999"
      type="tel"
      formatter={(value) => {
        let new_digitos = value;
        if (typeof value === 'string') {
          const digitos = value.match(/\d+/g) || [];
          new_digitos = digitos.join('');
        }
        if (formatarFixoECelular) {
          if (new_digitos.length > 10) {
            return `(${new_digitos.slice(0, 2)}) ${new_digitos.slice(
              2,
              7
            )}-${new_digitos.slice(7, 11)}`;
          }
          if (new_digitos.length > 7) {
            return `(${new_digitos.slice(0, 2)}) ${new_digitos.slice(
              2,
              6
            )}-${new_digitos.slice(6, 10)}`;
          }
        } else if (new_digitos.length > 7) {
          return `(${new_digitos.slice(0, 2)}) ${new_digitos.slice(
            2,
            7
          )}-${new_digitos.slice(7, 11)}`;
        }
        if (new_digitos.length > 2) {
          return `(${new_digitos.slice(0, 2)}) ${new_digitos.slice(2)}`;
        }
        if (new_digitos.length > 0) {
          return `(${new_digitos.slice(0)}`;
        }

        return new_digitos;
      }}
      parser={(value) => {
        const digitos = value.match(/\d+/g) || [];
        const new_digitos = digitos.join('');
        return new_digitos;
      }}
    />
  );
}

TelefoneInput.propTypes = {
  formatarFixoECelular: PropTypes.bool
};

TelefoneInput.defaultProps = {
  formatarFixoECelular: false
};

export default TelefoneInput;
