import React, { memo } from 'react';
import { Col, Row } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import FormasPagamentoField from './FormasPagamentoField';

/* eslint-disable react/forbid-prop-types */
const propTypes = {
  form: PropTypes.object.isRequired,
  formasPagamento: PropTypes.array,
  valorTotal: PropTypes.number.isRequired
};
/* eslint-enable react/forbid-prop-types */

const defaultProps = {
  formasPagamento: []
};

const PagarNaEntregaForm = ({ form, formasPagamento, valorTotal }) => {
  return (
    <Row gutter={16}>
      <Col xs={24}>
        <FormasPagamentoField
          form={form}
          formasPagamento={formasPagamento}
          valorTotal={valorTotal}
        />
      </Col>
    </Row>
  );
};

PagarNaEntregaForm.propTypes = propTypes;
PagarNaEntregaForm.defaultProps = defaultProps;

export default memo(PagarNaEntregaForm);
