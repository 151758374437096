import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  message
} from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { apiDelivery } from 'services/api';
import axios from 'axios';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { clearEndereco, useEnderecoContext } from 'contexts/EnderecoContext';

const { Meta } = Card;
const { TextArea } = Input;

const ClienteEndereco = ({ endereco, onSuccess }) => {
  const [width] = useDeviceWidth();
  const [form] = Form.useForm();
  const [cpfLoading, setCpfLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { enderecoDispatcher } = useEnderecoContext();

  useEffect(() => {
    if (endereco) {
      form.setFieldsValue(endereco);
    }
  }, [endereco, form]);

  const getCep = (cep) => {
    if (cep === '') {
      return;
    }
    setCpfLoading(true);
    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.data)
      .then((endereco) => {
        if (endereco.erro) {
          message.warn(`O endereço com o CEP ${cep} não foi encontrado.`);
          return;
        }
        const { bairro, localidade, logradouro, uf } = endereco;
        form.setFieldsValue({
          bairro,
          cidade: localidade,
          rua: logradouro,
          uf
        });
      })
      .catch(() => message.error('Erro ao obter o endereço.'))
      .finally(() => setCpfLoading(false));
  };

  const closeModal = () => setVisible(false);

  const atualizarEndereco = (dados) =>
    apiDelivery.put(`/controle/endereco/${endereco.id}/`, dados);

  const criarEndereco = (dados) =>
    apiDelivery.post('/controle/endereco/', dados);

  const removerEndereco = () => {
    setDeleteLoading(true);
    apiDelivery
      .delete(`/controle/endereco/${endereco.id}/`)
      .then(() => onSuccess && onSuccess())
      .then(() => enderecoDispatcher(clearEndereco()))
      .then(() => message.success('Endereço removido com sucesso.'))
      .catch(() => message.error('Erro ao tentar excluir o endereço.'))
      .finally(() => setDeleteLoading(false));
  };

  const handleSubmit = (dados) => {
    const dadosEndereco = { idtipoendereco: 1, ...dados };
    setLoading(true);
    (endereco ? atualizarEndereco(dadosEndereco) : criarEndereco(dadosEndereco))
      .then(() => closeModal())
      .then(() => onSuccess && onSuccess())
      .then(() => message.success('Endereço salvo com sucesso.'))
      .then(() => !endereco && form.resetFields())
      .catch(() => message.error('Erro ao tentar salvar o endereço.'))
      .finally(() => setLoading(false));
  };

  const openModal = () => setVisible(true);

  return (
    <>
      <Spin spinning={deleteLoading}>
        <Card hoverable style={{ marginBottom: 16 }} onClick={openModal}>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {endereco ? (
              <Meta
                title={`${endereco.rua}, ${endereco.numero}, ${endereco.bairro}`}
                description={
                  <>
                    {endereco.complemento && <div>{endereco.complemento}</div>}
                    <div>
                      {endereco.cep} {endereco.cidade}
                    </div>
                  </>
                }
              />
            ) : (
              <Meta title="Adicionar endereço..." />
            )}
            {endereco && (
              <Button
                icon={<DeleteOutlined />}
                shape="circle"
                type="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  removerEndereco();
                }}
              />
            )}
          </div>
        </Card>
      </Spin>

      <Modal
        centered={width < 768}
        footer={[
          <Button key="salvar" type="primary" onClick={() => form.submit()}>
            Salvar
          </Button>
        ]}
        forceRender
        title="Dados do endereço"
        visible={visible}
        width={width * 0.9}
        onCancel={closeModal}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            initialValues={{
              complemento: '',
              detalhe: ''
            }}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col xs={24} md={6}>
                <Form.Item
                  label="CEP"
                  name="cep"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                  onBlur={(e) => getCep(e.target.value)}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="Número"
                  name="numero"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label="Rua"
                  name="rua"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input
                    disabled={cpfLoading}
                    suffix={cpfLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="Bairro"
                  name="bairro"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input
                    disabled={cpfLoading}
                    suffix={cpfLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="Cidade"
                  name="cidade"
                  rules={[{ message: 'Campo obrigatório.', required: true }]}
                >
                  <Input
                    disabled={cpfLoading}
                    suffix={cpfLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item
                  label="UF"
                  name="uf"
                  rules={[
                    { message: 'Sigla da UF deve ter duas letras', len: 2 },
                    { message: 'Campo obrigatório.', required: true }
                  ]}
                >
                  <Input
                    disabled={cpfLoading}
                    suffix={cpfLoading ? <LoadingOutlined /> : null}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} md={6}>
                <Form.Item label="Complemento" name="complemento">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item label="Referência" name="detalhe">
                  <TextArea rows={4} style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ClienteEndereco;
