import React, { useCallback, useEffect, useState } from 'react';
import { Form, Radio } from 'antd';
import PropTypes from 'prop-types';

import PagarOnlineCartaoForm from './PagarOnlineCartaoForm';

import IMAGEM_PIX from 'assets/pix-logo.png';
import IMAGEM_CARTAO from 'assets/cartao.svg';

const propTypes = {
  cartoes: PropTypes.arrayOf(
    PropTypes.shape({
      token: PropTypes.string
    })
  ),
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  formasPagamento: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string,
      idformapgto: PropTypes.string,
      sttipopgto: PropTypes.number
    })
  )
};

const defaultProps = {
  cartoes: [],
  formasPagamento: []
};

const temApenasCartao = (formasPagamento) => {
  const isCartaoCreditoOuDebito = (formaPagamento) =>
    formaPagamento.sttipopgto === 60 || formaPagamento.sttipopgto === 61;

  return formasPagamento.every(isCartaoCreditoOuDebito);
};

const temApenasPix = (formasPagamento) => {
  const isPix = (formaPagamento) => formaPagamento.sttipopgto === 200;

  return formasPagamento.every(isPix);
};

const PagarOnlineForm = ({ cartoes, form, formasPagamento }) => {
  const [meioPgtoOnline, setMeioPgtoOnline] = useState(null);

  const alterarMeioPgtoOnline = useCallback((event) => {
    setMeioPgtoOnline(event.target.value);
  }, []);

  const definirEstadoInicialPelasFormasPgto = useCallback(() => {
    if (temApenasPix(formasPagamento)) {
      setMeioPgtoOnline('pix');
    } else if (temApenasCartao(formasPagamento)) {
      setMeioPgtoOnline('cartao');
    } else {
      setMeioPgtoOnline('cartao');
    }
  }, [formasPagamento]);

  useEffect(() => {
    if (formasPagamento.length > 0) {
      definirEstadoInicialPelasFormasPgto();
    }
  }, [formasPagamento, definirEstadoInicialPelasFormasPgto]);

  return (
    <>
      <Form.Item label="Selecione o meio de pagamento online">
        <Radio.Group value={meioPgtoOnline} onChange={alterarMeioPgtoOnline}>
          {(temApenasCartao(formasPagamento) ||
            !temApenasPix(formasPagamento)) && (
            <Radio value="cartao">
              <img
                src={IMAGEM_CARTAO}
                alt="Pagar com cartão."
                title="Pagar com cartão."
                width={48}
              />
              &nbsp;Cartão de crédito/débito
            </Radio>
          )}

          {(temApenasPix(formasPagamento) ||
            !temApenasCartao(formasPagamento)) && (
            <Radio value="pix">
              <img
                src={IMAGEM_PIX}
                alt="Pagar com PIX."
                title="Pagar com PIX."
                width={48}
              />
              PIX
            </Radio>
          )}
        </Radio.Group>
      </Form.Item>

      {meioPgtoOnline === 'cartao' && (
        <PagarOnlineCartaoForm
          cartoes={cartoes}
          form={form}
          formasPagamento={formasPagamento}
        />
      )}
    </>
  );
};

PagarOnlineForm.propTypes = propTypes;
PagarOnlineForm.defaultProps = defaultProps;

export default PagarOnlineForm;
