import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const NOME_COOKIE_IS_COOKIES_ACEITOS = 'is_cookies_aceitos';

/* eslint-disable-next-line no-underscore-dangle */
const _isCookiesAceitos = () => {
  if (!document.cookie || document.cookie === '') {
    return false;
  }
  const cookies = document.cookie.split(';');
  return cookies.some((cookie) =>
    cookie.trim().includes(`${NOME_COOKIE_IS_COOKIES_ACEITOS}=`)
  );
};

const usePoliticaCookies = () => {
  const { pathname } = useLocation();
  const [isCookiesAceitos, setIsCookiesAceitos] = useState(true);

  useEffect(() => {
    setIsCookiesAceitos(_isCookiesAceitos());
  }, [pathname]);

  const setCookiesComoAceitos = () => {
    document.cookie = `${NOME_COOKIE_IS_COOKIES_ACEITOS}=true;path=/`;
    setIsCookiesAceitos(true);
  };

  return [isCookiesAceitos, setCookiesComoAceitos];
};

export default usePoliticaCookies;
