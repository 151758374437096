import React, { useState } from 'react';
import { Button, Modal, Typography } from 'antd';

const { Paragraph, Text, Title } = Typography;

const LEVEL_TITULO = 3;

const LgpdModal = () => {
  const [visible, setVisible] = useState(false);

  const exibirModal = () => {
    setVisible(true);
  };

  return (
    <>
      <Button onClick={exibirModal}>Mais informações</Button>
      <Modal
        footer={null}
        title="Informações sobre nossos cookies"
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <Title level={LEVEL_TITULO}>Política de Cookies - EADelivery</Title>
        <Paragraph>
          O Sistema EADelivery utiliza cookies para melhorar a experiência dos
          usuários do sistema. Não temos acesso de sites externos ou quaisquer
          outros meios de propagar informação contida no sistema que não seja de
          interesse do nosso cliente cadastrado.
        </Paragraph>
        <Paragraph>
          Os utilizadores do sistema receberão cookies quando navegarem no
          sistema, o utilizador precisa aceitar para que tenha acesso continuado
          pelo sistema, assim deverá aceitar a política de uso de cookies
          conforme está no aviso que aparece na página inicial do sistema.
        </Paragraph>
        <Paragraph>
          O sistema EADelivery não utiliza cookies de propaganda ou quaisquer
          exibição de janelas com links de terceiros e ads.
        </Paragraph>

        <Title level={LEVEL_TITULO}>O que são Cookies?</Title>
        <Paragraph>
          O sistema EADelivery, como quase todos os sites e plataformas
          profissionais, fazem uso de cookies, que são pequenos arquivos,
          não-identificáveis, enviados para o seu computador ou dispositivo
          móvel com o objetivo de melhorar sua experiência de navegação.
        </Paragraph>

        <Title level={LEVEL_TITULO}>
          Quais os tipos de Cookies utilizamos?
        </Title>
        <Paragraph>
          <Text strong>Cookies de sessão (temporários)</Text>: são cookies que
          expiram depois que você fecha seu navegador da web ou exista uma
          atualização do sistema.
        </Paragraph>
        <Paragraph>
          <Text strong>Cookies persistentes (permanentes)</Text>: são cookies
          que devem permanecer no seu dispositivo por um período definido ou até
          você excluí-los. Esses cookies são de necessidade para sistema
          funcionar.
        </Paragraph>
        <Paragraph>
          <Text strong>Cookies proprietários</Text>: são cookies definidos pelo
          site que você está acessando no momento, pelo sistema EADelivery.
        </Paragraph>

        <Title level={LEVEL_TITULO}>Categorias de Cookies que usamos</Title>
        <Paragraph>
          <Text strong>Cookies estritamente necessários</Text>: essenciais para
          que você use os recursos de nossos serviços. Sem estes cookies, os
          serviços que você solicita não podem ser fornecidos, e
          consequentemente você não terá acesso ao sistema EADelivery.
        </Paragraph>
        <Paragraph>
          <Text strong>Cookies de funcionalidade</Text>: permitem que o site
          memorize escolhas que você faz (como seu nome de usuário, idioma,
          posição de janelas do dashboard por exemplo). As informações que esses
          cookies coletam podem ser anônimas e não podem controlar suas
          atividades de navegação em outros sites. Se o mesmo cookie for usado
          para redirecionamento, deverão ser incluídos também na categoria
          &quot;cookies de direcionamento ou publicidade&quot;. Ele também deve
          incluir os cookies usados para oferecer uma função específica, mas
          quando essa função incluir cookies usados para redes de publicidade
          comportamentais/direcionadas, ele deverá aparecer também na categoria
          &quot;cookies de direcionamento ou publicidade&quot;.
        </Paragraph>

        <Title level={LEVEL_TITULO}>
          Como gerenciar suas preferências de cookies
        </Title>
        <Paragraph>
          Você pode evitar a execução de cookies ajustando as configurações no
          seu navegador (consulte a Ajuda do seu navegador para saber como fazer
          isso). Contudo, esteja ciente de que a desativação de cookies afetará
          a funcionalidade deste e de muitos outros sites que você visita.
          Desabilitar os cookies resultará para o sistema EADelivery a
          desativação dos recursos deste site. Portanto, é recomendável que você
          não desabilite os cookies, caso não queria perder estas
          funcionalidades.
        </Paragraph>

        <Title level={LEVEL_TITULO}>Um compromisso com a sua privacidade</Title>
        <Paragraph>
          O sistema EADelivery é um produto da empresa Proabakus Desenvolvimento
          de Softwares LTDA.
        </Paragraph>
        <Paragraph>
          A Proabakus se reserva o direito de alterar sua Política de Cookies a
          qualquer momento, atualizando o conteúdo de acordo com as necessidades
          do negócio, de melhorias tecnológicas ou mesmo de funcionalidades.
        </Paragraph>
        <Paragraph>
          Ao continuar usufruindo de nossas ferramentas, websites e plataformas,
          reconhecemos automaticamente esta ação como uma concordância a nossos
          termos e condições, de forma irrevogável e irretratável.
        </Paragraph>
        <Paragraph>
          Caso não aceite os termos desta Política de Cookies, pedimos que entre
          em contato com o responsável pelo contrato com a proabakus e solicite
          o descadastramento de seu usuário/acesso ao sistema.
        </Paragraph>
        <Paragraph>
          Para maiores informações pode entrar em contato conosco pelo email:
          contato@proabakus.com.br
        </Paragraph>
        <Paragraph>Agradecemos a sua atenção.</Paragraph>
      </Modal>
    </>
  );
};

export default LgpdModal;
