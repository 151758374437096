import React from 'react';
import { Typography, Card, Layout, Divider } from 'antd';

// import { Container } from './styles';

function Termos() {
  const { Text, Paragraph, Title } = Typography;
  return (
    <Layout>
      <Card>
        <Title level={3}>Termos de Uso</Title>
        <Divider type="horizontal" />
        <Paragraph style={{ textAlign: 'justify' }}>
          Este Contrato de Licença de Usuário Final é um acordo legal entre o
          usuário pessoa física ou pessoa jurídica, doravante denominado
          “CLIENTE”, e PROABAKUS DESENVOLVIMENTO DE SISTEMAS LTDA, inscrita no
          CNPJ sob número 13.107.496/0001-49, com sede na Av. José Amora Sá,
          1201 Sala 01, Distrito Industrial II, Eusébio-CE, CEP 61760-000,
          Brasil, denominada “CONTRATADA”, para uso do programa de computador
          denominado “EADELIVERY” (doravante denominado “SOFTWARE”),
          disponibilizado neste ato pela PROABAKUS, por meio website e também de
          lojas de aplicativos para dispositivos móveis, pelo prazo determinado
          pelo CLIENTE no ato do licenciamento do SOFTWARE, o que inclui o
          programa de computador e pode incluir os meios físicos associados,
          quaisquer materiais impressos, e qualquer documentação “online” ou
          eletrônica. Ao utilizar o SOFTWARE, mesmo que parcialmente ou a título
          de teste, o CLIENTE estará vinculado aos termos deste CONTRATO,
          concordando com os mesmos, principalmente CONSENTINDO COM O ACESSO,
          COLETA, USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS
          INFORMAÇÕES do CLIENTE para a integral execução das funcionalidades
          ofertadas pelo SOFTWARE. Em caso de discordância dos termos de uso
          aqui apresentados, a utilização do SOFTWARE deve ser imediatamente
          interrompida.
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          <Text strong>Propriedade Intelectual </Text>
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          O presente contrato não concede ao CLIENTE nenhum direito de
          propriedade intelectual sobre o SOFTWARE ou quaisquer partes dele ou
          dos sistemas agregados, ficando certo que esses direitos são e
          permanecerão sendo propriedade autoral exclusiva da CONTRATADA,
          detentora absoluta dos direitos morais e patrimoniais inerentes.
          Também constitui propriedade exclusiva da CONTRATADA, ou está
          devidamente licenciado para ela, todo o conteúdo disponibilizado no
          site, incluindo, mas não se limitando a textos, gráficos, imagens,
          logos, ícones, fotografias, conteúdo editorial, notificações, marcas,
          patentes, softwares e qualquer outro material. O CLIENTE concorda em
          não modificar, criar obras derivadas, descompilar ou de outra forma
          tentar extrair o código fonte, copiar, ceder, sublicenciar, vender,
          dar em locação ou em garantia, reproduzir, doar, alienar de qualquer
          forma, transferir total ou parcialmente, sob quaisquer modalidades,
          gratuita ou onerosamente, provisória ou permanentemente, o SOFTWARE
          objeto deste CONTRATO, assim como seus módulos, partes, manuais ou
          quaisquer informações relativas ao mesmo; Através deste contrato, é
          cedido ao CLIENTE apenas o direito de uso do SOFTWARE em questão, sem
          a necessidade de apresentar ou fornecer o código fonte ou estrutura
          interna do produto.
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          <Text strong>Licenciamento de Uso do Software </Text>
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          Sujeito aos termos e condições do presente instrumento, este CONTRATO
          concede ao CLIENTE uma licença revogável, não exclusiva e
          intransferível, para usar o SOFTWARE. É concedido ao CLIENTE o direito
          de uso do SOFTWARE de forma gratuita por período e prazo
          indeterminado, até que o CLIENTE ou a CONTRATADA realize SEM prévio
          aviso a exclusão do cadastro do CLIENTE no SOFTWARE.
        </Paragraph>{' '}
        <Paragraph style={{ textAlign: 'justify' }}>
          O SOFTWARE é fornecido "como está" sem nenhuma outra garantia. O
          cliente assume todos os riscos e responsabilidades pela escolha do
          software para alcançar os resultados desejados, bem como pela
          utilização e resultados obtidos por meio do SOFTWARE. A CONTRATADA não
          garante que o software esteja isento de erros, possíveis interrupções
          ou falhas, ou que o mesmo seja compatível com qualquer hardware ou
          software específico. Pela extensão máxima permitida pela lei
          aplicável, a empresa se isenta de todas as garantias, sejam explícitas
          ou implícitas, incluindo, sem limitação, garantias implícitas de
          adequação comercial, adequação para um propósito específico e
          não-violação em relação ao SOFTWARE e aos materiais escritos que o
          acompanham.
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          <Text strong>Exclusão da Conta do Cliente</Text>
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          A qualquer momento, sem aviso prévio de ambas as partes, a conta do
          CLIENTE poderá ser excluída eliminando do SOFTWARE toda e qualquer
          informação cadastrada ou autorizada pelo CLIENTE. Essa ação de
          exclusão poderá ser realizar através do botão “Excluir Conta” que está
          disponível no Menu “Perfil" do usuário.
        </Paragraph>
      </Card>
    </Layout>
  );
}
export default Termos;
