import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Typography } from 'antd';
import moment from 'moment';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import PedidoCardDescricaoItens from './PedidoCardDescricaoItens';
import PedidoCardPreco from './PedidoCardPreco';
import PedidoStatus from './PedidoStatus';

const propTypes = {
  pedido: PropTypes.shape({
    dthrpedido: PropTypes.string,
    estabelecimento: PropTypes.shape({
      apelidooufantasia: PropTypes.string
    }),
    historico: PropTypes.array,
    id: PropTypes.string,
    pedido_json: PropTypes.shape({
      numero_pedido: PropTypes.number,
      total: PropTypes.number
    })
  }).isRequired
};

const defaultProps = {};

const { Meta } = Card;
const { Text } = Typography;

const PedidoCard = ({ pedido }) => {
  const history = useHistory();

  const goToPedidoDetailsPageWithId = (id) => () =>
    history.push(`/pedido/${id}/`);

  return (
    <Card
      hoverable
      style={{ cursor: 'pointer', marginBottom: 16 }}
      onClick={goToPedidoDetailsPageWithId(pedido.id)}
    >
      <Meta
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{pedido.estabelecimento.apelidooufantasia}</span>
            <Text type="danger">#{pedido.pedido_json.numero_pedido}</Text>
          </div>
        }
        description={
          /* eslint-disable-next-line react/jsx-fragments */
          <React.Fragment>
            <p>{moment(pedido.dthrpedido).format('LL')}</p>

            <PedidoCardDescricaoItens pedido={pedido.pedido_json} />

            <hr />

            <div
              style={{
                alignItems: 'baseline',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <PedidoCardPreco total={pedido.pedido_json.total} />
              <PedidoStatus historico={pedido.historico} />
            </div>
          </React.Fragment>
        }
      />
    </Card>
  );
};

PedidoCard.propTypes = propTypes;
PedidoCard.defaultProps = defaultProps;

export default PedidoCard;
