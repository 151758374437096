import React, { Component } from 'react';
import { Alert, Card } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.element.isRequired,
  referencia: PropTypes.string.isRequired
};

const { Meta } = Card;

class PedidoCardErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    /* eslint-disable-next-line no-console */
    console.log(error);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { props, state } = this;

    if (state.hasError) {
      return (
        <Card style={{ marginBottom: 16 }}>
          <Meta
            description={
              <Alert
                message="Houve um erro ao tentar exibir as informações."
                showIcon
                type="error"
              />
            }
            title={`PROBLEMA COM O PEDIDO #${props.referencia}`}
          />
        </Card>
      );
    }
    return props.children;
  }
}

PedidoCardErrorBoundary.propTypes = propTypes;

export default PedidoCardErrorBoundary;
