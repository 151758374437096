import React from 'react';
import { Tag, PageHeader } from 'antd';

import { capitalizeFLetter, substituirHttp } from 'utils/normalize';

const EstabelecimentoHeader = ({
  dadosEstabelecimento,
  isEstabelecimentoAberto
}) => {
  if (!dadosEstabelecimento) {
    return null;
  }

  const sourceLogo = substituirHttp(dadosEstabelecimento.logo);
  const apelidooufantasia = capitalizeFLetter(
    dadosEstabelecimento.apelidooufantasia
  );

  return (
    <PageHeader
      avatar={{ src: sourceLogo, size: 48 }}
      title={apelidooufantasia}
      tags={!isEstabelecimentoAberto && <Tag color="red">FECHADO</Tag>}
      style={{ backgroundColor: 'white' }}
    />
  );
};

export default EstabelecimentoHeader;
