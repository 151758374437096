import React from 'react';
import { Form, Input } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const { TextArea } = Input;

const propTypes = {
  qrcode: PropTypes.string.isRequired
};

const CopiarQRCodePixManual = ({ qrcode }) => {
  return (
    <Form.Item label="Clique para selecionar e copie o QRCode abaixo:">
      <TextArea
        style={{ height: '12rem', resize: 'none' }}
        value={qrcode}
        onClick={(event) => {
          event.target.select();
        }}
      />
    </Form.Item>
  );
};

CopiarQRCodePixManual.propTypes = propTypes;

export default CopiarQRCodePixManual;
