import axios from 'axios';

import { apiDelivery } from 'services/api';

export const obterDadosEstabelecimento = (id) => {
  return axios
    .all([
      apiDelivery.get(`/controle/estabelecimento/${id}/`),
      apiDelivery.get(`/api/estabelecimento/${id}/taxas_entrega/`)
    ])
    .then(
      axios.spread((estabelecimento, taxasentrega) => [
        estabelecimento.data,
        taxasentrega.data
      ])
    )
    .then(([dadosEstabelecimento, taxasentrega]) => {
      const { estabelecimento, id } = dadosEstabelecimento;
      const {
        apelidooufantasia,
        endereco_set,
        nomeourazaosocial
      } = estabelecimento;
      const [
        { bairro, cep, cidade, complemento, numero, rua, uf }
      ] = endereco_set;
      return {
        id,
        apelidooufantasia,
        endereco: {
          bairro,
          cep,
          cidade,
          complemento,
          numero,
          rua,
          uf
        },
        nomeourazaosocial,
        taxasentrega
      };
    });
};

export const obterQuantidadeOpcionais = (opcoes) =>
  opcoes.reduce((qtd, opcao) => qtd + opcao.quantidade, 0);

export const isGrupoOpcaoValido = (grupoOpcao) => {
  const { qtdmultiplomax, qtdmultiplomin, opcoes, stindisponivel } = grupoOpcao;
  if (stindisponivel > 0 && stindisponivel <= 2) {
    return true;
  }
  const quantidade = obterQuantidadeOpcionais(opcoes);
  if (qtdmultiplomax === 0 && qtdmultiplomin === 0) {
    return true;
  }
  if (qtdmultiplomax === 0) {
    return qtdmultiplomin <= quantidade;
  }
  if (qtdmultiplomin === 0) {
    return quantidade <= qtdmultiplomax;
  }
  return qtdmultiplomin <= quantidade && quantidade <= qtdmultiplomax;
};

export const isProdutoValido = (produto) =>
  produto.grupoopcoes.every(isGrupoOpcaoValido);
