import React from 'react';

import { seloCielo } from 'assets';

const ImagemSeloSegurancaCielo = () => (
  <div style={{ marginBottom: 16, textAlign: 'center', width: '100%' }}>
    <img alt="Selo de segurança Cielo" src={seloCielo} />
  </div>
);

export default ImagemSeloSegurancaCielo;
