import React from 'react';

import { Redirect } from 'react-router-dom';
import { message } from 'antd';
import { useAuthContext, logout } from 'contexts/AuthContext';
import { useClienteContext, clearCliente } from 'contexts/ClienteContext';
import { clearEndereco, useEnderecoContext } from 'contexts/EnderecoContext';
import {
  limparFiltro,
  useFiltroEstabelecimentoContext
} from 'contexts/FiltroEstabelecimentoContext';

export default function AcessoLogout() {
  const { authDispatcher } = useAuthContext();
  const { clienteDispatcher } = useClienteContext();
  const { enderecoDispatcher } = useEnderecoContext();
  const { filtroEstabDispatcher } = useFiltroEstabelecimentoContext();

  authDispatcher(logout());
  clienteDispatcher(clearCliente());
  enderecoDispatcher(clearEndereco());
  filtroEstabDispatcher(limparFiltro());

  message.info('Você fez logout!', 5);

  const caminhoEstabelecimentoVisitado = window.localStorage.getItem(
    'caminho_estabelecimento_visitado'
  );
  if (caminhoEstabelecimentoVisitado) {
    return <Redirect to={caminhoEstabelecimentoVisitado} />;
  }

  return <Redirect to="/" />;
}
