import React from 'react';
import { Result, Typography } from 'antd';

import './NaoEncontrado.css';

const { Text } = Typography;

const NaoEncontrado = () => {
  return (
    <div className="nao-encontrado__container">
      <Result
        className="nao-encontrado__component"
        status="warning"
        subTitle={
          <>
            <Text className="nao-encontrado__sub-title" type="secondary">
              Agora cada estabelecimento tem a sua própria página.
            </Text>
            <br />
            <Text className="nao-encontrado__sub-title" type="secondary">
              Portanto, entre em contato com o estabelecimento para conhecer o
              seu cardápio e realizar o pedido.
            </Text>
          </>
        }
        title="Página modificada."
      />
    </div>
  );
};

export default NaoEncontrado;
