import React, { createContext, useEffect, useReducer, useContext } from 'react';

import useLocalStorage from 'hooks/useLocalStorage';

export const EnderecoContext = createContext();

const INITIAL_STATE = null;

export const KEY_ENDERECO = 'endereco';

export const CLEAR_ENDERECO = 'CLEAR_ENDERECO';

export const SET_ENDERECO = 'SET_ENDERECO';

export const clearEndereco = () => ({ type: CLEAR_ENDERECO });

export const setEndereco = (endereco) => ({ type: SET_ENDERECO, endereco });

export const enderecoReducer = (state, action) => {
  switch (action.type) {
    case SET_ENDERECO:
      return action.endereco;
    case CLEAR_ENDERECO:
      return null;
    default:
      return state;
  }
};

function EnderecoProvider(props) {
  const [enderecoSalvo, saveEndereco] = useLocalStorage(
    KEY_ENDERECO,
    INITIAL_STATE
  );
  const [endereco, enderecoDispatcher] = useReducer(
    enderecoReducer,
    enderecoSalvo
  );
  useEffect(() => {
    saveEndereco(endereco);
  }, [endereco]);
  return (
    <EnderecoContext.Provider
      value={{ endereco, enderecoDispatcher }}
      {...props}
    />
  );
}

function useEnderecoContext() {
  return useContext(EnderecoContext);
}

export { EnderecoProvider, useEnderecoContext };
