import {
  Badge,
  Button,
  Drawer,
  List,
  Typography,
  notification,
  Alert
} from 'antd';
import React, { useEffect, useState } from 'react';

import { ShoppingCartOutlined } from '@ant-design/icons';
import useDeviceWidth from 'hooks/useDeviceWidth';
import { useHistory } from 'react-router-dom';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';
import CarrinhoProduto from './Produto';
import ProdutoDrawer from 'components/Delivery/Layout/Content/Cardapio/Produto/Modal';

import { obterValorMonetario, obterValorSubTotal } from 'services/precos';

import BotaoContinuarComprandoMobile from './BotaoContinuarComprandoMobile';

const { Paragraph } = Typography;

const ContentCarrinho = () => {
  const { carrinho } = useCarrinhoContext();
  const { estabelecimento, taxaentrega, abono } = carrinho;
  const [deviceWidth] = useDeviceWidth();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [produtoDrawerVisible, setProdutoDrawerVisible] = useState(false);
  const [isProdutoDrawerRemovido, setIsProdutoDrawerRemovido] = useState(false);
  const [produtoDrawerIndice, setProdutoDrawerIndice] = useState(null);
  const [produtoDrawer, setProdutoDrawer] = useState({});
  const [disabled, setDisabled] = useState(false);

  const { itens } = carrinho;

  const abrirCarrinho = () => {
    if (itens.length > 0) {
      setVisible(true);
    } else {
      notification.info({
        key: 'carrinho-vazio',
        message: 'CARRINHO',
        description: 'Seu carrinho está sem itens =('
      });
    }
  };

  const valorTotal = obterValorSubTotal(itens);

  useEffect(() => {
    if (visible) {
      setDisabled(
        estabelecimento &&
          estabelecimento.pedidominimo &&
          valorTotal < parseFloat(estabelecimento.pedidominimo)
      );
    }
  }, [visible, estabelecimento, valorTotal]);

  const fecharCarrinho = () => setVisible(false);

  if (visible && itens.length === 0) {
    fecharCarrinho();
  }

  return (
    <>
      <div className="ripple" onClick={abrirCarrinho}>
        <Badge key={2} count={itens.length}>
          <ShoppingCartOutlined
            onClick={abrirCarrinho}
            style={{
              fontSize: '25px',
              color: '#ccc'
            }}
          />
        </Badge>
      </div>
      <Drawer
        title={`Seu pedido em ${
          estabelecimento && estabelecimento.apelidooufantasia
        }`}
        visible={visible}
        width={deviceWidth < 768 ? deviceWidth : 512}
        onClose={fecharCarrinho}
      >
        <BotaoContinuarComprandoMobile
          deliveryestabelecimento={estabelecimento?.id}
          onRedirect={fecharCarrinho}
        />

        <List
          dataSource={itens}
          footer={
            <>
              {abono !== 0 && typeof abono === 'number' ? (
                valorTotal < abono ? (
                  <Alert
                    type="success"
                    message={`Entrega Grátis com o mínimo de ${obterValorMonetario(
                      abono
                    )}!`}
                  />
                ) : (
                  <Alert type="success" message={`Entrega grátis!`} />
                )
              ) : null}
              <Paragraph>
                <b>Subtotal</b>: {obterValorMonetario(valorTotal)}
              </Paragraph>
              {typeof taxaentrega === 'number' ? (
                <Paragraph>
                  <b>Taxa de entrega</b>:{' '}
                  {taxaentrega === 0 ||
                  (typeof abono === 'number' && valorTotal >= abono) ? (
                    <b>Grátis</b>
                  ) : (
                    obterValorMonetario(taxaentrega)
                  )}
                </Paragraph>
              ) : (
                <Paragraph>Receber no estabelecimento.</Paragraph>
              )}
              <Paragraph>
                <b>Total</b>:{' '}
                {obterValorMonetario(
                  typeof abono === 'number' && valorTotal >= abono
                    ? valorTotal
                    : (taxaentrega || 0) + valorTotal
                )}
              </Paragraph>
            </>
          }
          itemLayout="vertical"
          renderItem={(produto, indice) => (
            <CarrinhoProduto
              estabelecimento={carrinho.estabelecimento}
              indice={indice}
              produto={produto}
              onEditar={(a_produto) => {
                setProdutoDrawer(a_produto);
                setProdutoDrawerIndice(indice);
                setProdutoDrawerVisible(true);
                setIsProdutoDrawerRemovido(true);
              }}
            />
          )}
        />

        {disabled && estabelecimento && (
          <p>
            O pedido mínimo para essa loja é de R${' '}
            {estabelecimento.pedidominimo} (sem contar a taxa de entrega).
          </p>
        )}

        <Button
          block
          disabled={disabled}
          type="primary"
          onClick={() => {
            history.push('/pagamento');
            fecharCarrinho();
          }}
        >
          Finalizar pedido
        </Button>
        {/* isProdutoDrawerRemovido foi criado para remover o drawer e seu estado residual, necessários para a qtdpadrao funcionar */}
        {isProdutoDrawerRemovido && (
          <ProdutoDrawer
            indice={produtoDrawerIndice}
            estabelecimento={estabelecimento}
            produto={produtoDrawer}
            visible={produtoDrawerVisible}
            onAnimationEnded={setIsProdutoDrawerRemovido}
            onClose={() => setProdutoDrawerVisible(false)}
          />
        )}
      </Drawer>
    </>
  );
};

export default ContentCarrinho;
