import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

const FinalizarPedidoValidacaoModal = ({ erros, visible, onOk, onCancel }) => {
  const onModalOk = () => {
    onOk && onOk();
  };

  const onModalCancel = () => {
    onCancel && onCancel();
  };

  return (
    <div>
      <Modal
        title="Não foi possível finalizar o pedido"
        visible={visible}
        onOk={onModalOk}
        onCancel={onModalOk}
        style={{ maxHeight: '100vh', top: 0 }}
      >
        <div style={{ maxHeight: '70vh', overflow: 'auto', padding: 10 }}>
          {erros.map((erro) => (
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ExclamationCircleFilled
                  style={{ fontSize: '32px', color: '#faad14' }}
                />
                <span style={{ marginLeft: 10 }}>{`${erro.msg}`}</span>
              </div>
              <hr />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default FinalizarPedidoValidacaoModal;
