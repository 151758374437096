import { useEffect, useState } from 'react';
import { apiDelivery } from 'services/api';

export default function usePedido(idpedido) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pedido, setPedido] = useState(null);

  async function getPedido(id) {
    return apiDelivery
      .get(`/controle/pedido/${id}/`)
      .then((response) => {
        const pedido_data = response.data;
        return {
          ...pedido_data,
          pedido: JSON.parse(
            pedido_data.pedido
              .replace(/'/g, '"')
              .replace(/None/g, 'null')
              .replace(/False/g, false)
              .replace(/True/g, true)
          )
        };
      })
      .then(setPedido)
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }

  return [pedido, loading, error, getPedido, setLoading];
}
