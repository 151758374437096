import React, { useEffect, useState } from 'react';
import { Row, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { useClienteContext, setPedidos } from 'contexts/ClienteContext';

import usePedidosPendentes from 'hooks/usePedidos';
import usePedidosConcluidos from 'hooks/usePedidosConcluidos';

import PedidosConcluidosPaginador from './PedidosConcluidosPaginador';
import PedidosLista from './PedidosLista';
import PedidosRequestError from './PedidosRequestError';

const { TabPane } = Tabs;

const TabPaneLabelWithLoading = ({ label, loading }) => (
  <span>
    {loading && <LoadingOutlined />}
    {label}
  </span>
);

TabPaneLabelWithLoading.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

const PedidoLista = () => {
  const { clienteDispatcher } = useClienteContext();
  const [
    pedidosConcluidosData,
    loadingForPedidosConcluidos,
    errorForPedidosConcluidos,
    getPedidosConcluidosFromPage
  ] = usePedidosConcluidos();
  const { pedidosConcluidos, qtdPedidosConcluidos } = pedidosConcluidosData;
  const [
    pedidosPendentes,
    loading,
    errorForPedidosPendentes
  ] = usePedidosPendentes();
  const [
    hasRequestedPedidosConcluidos,
    setPedidosConcluidosAsRequested
  ] = useState(false);

  const onChangePedidosTab = () => {
    if (hasRequestedPedidosConcluidos) {
      return false;
    }
    getPedidosConcluidosFromPage(0);
    setPedidosConcluidosAsRequested(true);
    return true;
  };

  useEffect(() => {
    clienteDispatcher(setPedidos(pedidosPendentes));
  }, [pedidosPendentes]);

  return (
    <Tabs defaultActiveKey="1" onChange={onChangePedidosTab}>
      <TabPane
        key="1"
        tab={<TabPaneLabelWithLoading label="Pendentes" loading={loading} />}
      >
        {errorForPedidosPendentes ? (
          <PedidosRequestError message="Erro ao tentar obter os pedidos pendentes." />
        ) : (
          <PedidosLista
            emptyMessage="Você não possui pedidos pendentes."
            loadingForPedidos={loading}
            pedidos={pedidosPendentes}
          />
        )}
      </TabPane>

      <TabPane
        key="2"
        tab={
          <TabPaneLabelWithLoading
            label="Concluídos"
            loading={loadingForPedidosConcluidos}
          />
        }
      >
        {errorForPedidosConcluidos ? (
          <PedidosRequestError message="Erro ao tentar obter os pedidos concluídos." />
        ) : (
          /* eslint-disable-next-line react/jsx-fragments */
          <React.Fragment>
            {qtdPedidosConcluidos > 0 && (
              <Row gutter={[0, 32]} justify="center" style={{ marginTop: 4 }}>
                <PedidosConcluidosPaginador
                  quantidade={qtdPedidosConcluidos}
                  onChange={(currentPage) => {
                    getPedidosConcluidosFromPage(currentPage - 1);
                  }}
                />
              </Row>
            )}

            <PedidosLista
              emptyMessage="Você ainda não possui algum pedido concluído."
              loadingForPedidos={loadingForPedidosConcluidos}
              pedidos={pedidosConcluidos}
            />
          </React.Fragment>
        )}
      </TabPane>
    </Tabs>
  );
};

export default PedidoLista;
