export const tratarErro = (erro, chave = null) => {
  let error_msg = 'Erro não identificado';

  if (typeof erro !== 'undefined') {
    const hasMessageProperty = Object.prototype.hasOwnProperty.call(
      erro,
      'message'
    );
    if (hasMessageProperty) {
      error_msg = erro.message;
    }
  }

  if (typeof erro.response !== 'undefined') {
    if (erro.response.status === 400) {
      error_msg = 'Requisição mal formada';

      const hasNonFieldErrosProperty = Object.prototype.hasOwnProperty.call(
        erro.response.data,
        'non_field_errors'
      );
      if (hasNonFieldErrosProperty) {
        error_msg = erro.response.data.non_field_errors;
      }

      if (
        chave &&
        erro.response &&
        erro.response.data &&
        erro.response.data[chave]
      ) {
        error_msg = erro.response.data[chave];
      }
    } else if (erro.response.status === 401) {
      error_msg = 'Não autorizado';
    } else if (erro.response.status === 404) {
      error_msg = 'Rota não encontrada';
    } else if (erro.response.status === 405) {
      error_msg = 'Sem permissão';
    } else if (erro.response.status === 422) {
      error_msg = 'Requisição inválida';
    } else if (erro.response.status >= 500) {
      error_msg = 'Tivemos um probleminha, tente novamente depois.';
    }

    const hasResponseProperty = Object.prototype.hasOwnProperty.call(
      erro,
      'response'
    );

    const hasErrorMessageProperty = Object.prototype.hasOwnProperty.call(
      erro.response.data,
      'error_message'
    );
    const hasDetailProperty = Object.prototype.hasOwnProperty.call(
      erro.response.data,
      'detail'
    );
    const hasErroProperty = Object.prototype.hasOwnProperty.call(
      erro.response.data,
      'erro'
    );

    if (hasResponseProperty) {
      if (hasErrorMessageProperty) error_msg = erro.response.data.error_message;
      else if (hasDetailProperty) error_msg = erro.response.data.detail;
      else if (hasErroProperty) error_msg = erro.response.data.erro;
    }
  }

  return error_msg;
};
