import { org_id, session_id } from './utils';

export const removerScript = (id) => {
  try {
    const element = document.getElementById(id);
    if (element) element.remove();
  } catch (e) {
    console.log(e);
  }
};

export const adicionarScript = (id, src) => {
  const script = document.createElement('script');

  script.src = src;
  script.id = id;
  script.type = 'text/javascript';

  document.head.appendChild(script);
};

const BrasPagScript = ({ browserfingerprint, sandbox = false }) => {
  const src = `https://h.online-metrix.net/fp/tags.js?org_id=${org_id(
    sandbox
  )}&session_id=${session_id(browserfingerprint, sandbox)}`;
  const id = 'bra-script';
  adicionarScript(id, src);
};

export default BrasPagScript;
