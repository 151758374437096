import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useRef
} from 'react';
import useCliente2 from 'hooks/useCliente2';
import { limparLocalStorageLogout } from 'services/api';
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

export const KEY_ACCESS_TOKEN = 'access_token';
export const KEY_REFRESH_TOKEN = 'refresh_token';
export const KEY_REDIRECIONAR = 'redirecionar';

const initialState = {
  isLoggedIn: null,
  cliente: null,
  error: null,
  redirect: null,
  access_token: localStorage.getItem(KEY_ACCESS_TOKEN) || '',
  refresh_token: localStorage.getItem(KEY_REFRESH_TOKEN) || ''
};

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_TOKENS = 'SET_AUTH_TOKENS';
export const SET_REDIRECIONAR = 'SET_REDIRECIONAR';

export function loginSuccess() {
  return { type: LOGIN_SUCCESS };
}

export function loginFail(error) {
  return { type: LOGIN_FAIL, error };
}

export function logout() {
  return { type: LOGOUT };
}

export function setAuthTokens(access_token, refresh_token) {
  return { type: SET_AUTH_TOKENS, access_token, refresh_token };
}

export function setRedirecionar(redirect) {
  localStorage.setItem(KEY_REDIRECIONAR, redirect);
  return { type: SET_REDIRECIONAR, redirect };
}

export function authReducer(state, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        error: null
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        name: null,
        error: action.error
      };

    case LOGOUT: {
      limparLocalStorageLogout();
      return {
        ...state,
        isLoggedIn: false,
        redirect: null,
        access_token: '',
        refresh_token: ''
      };
    }
    case SET_AUTH_TOKENS: {
      return {
        ...state,
        access_token: action.access_token,
        refresh_token: action.refresh_token
      };
    }
    case SET_REDIRECIONAR: {
      return {
        ...state,
        redirect: action.redirect
      };
    }
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [auth, dispatch] = useReducer(authReducer, initialState);
  const authData = { auth, authDispatcher: dispatch };
  const first = useRef();

  const [_cliente, loading, getCliente] = useCliente2();

  useEffect(() => {
    if (
      localStorage.getItem(KEY_ACCESS_TOKEN) ||
      (localStorage.getItem(KEY_REFRESH_TOKEN) && !auth.isLoggedIn)
    ) {
      getCliente();
    }
    if (localStorage.getItem(KEY_REDIRECIONAR)) {
      dispatch(setRedirecionar(localStorage.getItem(KEY_REDIRECIONAR)));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(KEY_ACCESS_TOKEN, auth.access_token);
    localStorage.setItem(KEY_REFRESH_TOKEN, auth.refresh_token);
  }, [auth.access_token, auth.refresh_token]);

  useEffect(() => {
    if (!localStorage.getItem(KEY_ACCESS_TOKEN)) {
      dispatch(loginFail());
      return;
    }

    if (_cliente) {
      dispatch(loginSuccess());
    } else if (first.current) {
      dispatch(loginFail());
    }
    first.current = true;
  }, [_cliente, dispatch]);

  return <AuthContext.Provider value={authData} {...props} />;
}

function useAuthContext() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuthContext };
