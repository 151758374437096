const VALIDACAO = Object.freeze({
  PEDIDO_DUPLICADO: 409,
  TAXA_ATUALIZADA: 1101,
  TAXA_NAO_EXISTE: 1102,
  PRODUTO_INDISPONIVEL: 1201,
  PRODUTO_PRECO_UNITARIO: 1202,
  PRODUTO_PRECO_TOTAL: 1203,
  OPCAO_INDISPONIVEL: 1301,
  OPCAO_PRECO_UNITARIO: 1302,
  OPCAO_PRECO_TOTAL: 1303,
  VALOR_TOTAL: 1403
});

const PAGAMENTO = Object.freeze({
  NAO_PERMITIDA: 100,
  COMUNICACAO: 500,
  GENERICO: 501
});

const ERRO = Object.freeze({
  PEDIDO_MINIMO: 102,
  CUPOM_DESCONTO_INVALIDO: 103,
  CUPOM_DESCONTO_VALOR: 104,
  VALIDACAO: 105,
  PAGAMENTO_ONLINE: 106,
  LOJA_FECHADA: 422
});

export default {
  VALIDACAO,
  PAGAMENTO,
  ERRO
};
