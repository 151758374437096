/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { obterDescricaoBandeiraCartao } from './utils';

const propTypes = {
  bandeira: PropTypes.string
};

const defaultProps = {
  bandeira: ''
};

const DescricaoBandeiraCartao = ({ bandeira }) => {
  const descricaoBandeira = obterDescricaoBandeiraCartao(bandeira);

  if (!descricaoBandeira) {
    return '-';
  }

  return descricaoBandeira;
};

DescricaoBandeiraCartao.propTypes = propTypes;
DescricaoBandeiraCartao.defaultProps = defaultProps;

export default DescricaoBandeiraCartao;
