import { Decimal } from 'decimal.js';

import {
  STPRECOPRATICADO_DESPREZA_PRECO,
  STPRECOPRATICADO_KIT,
  STPRECOPRATICADO_MAIOR_PRECO,
  STPRECOPRATICADO_MENOR_PRECO,
  STPRECOPRATICADO_PRECO_MEDIO,
  STPRECOPRATICADO_PRECO_OPCAO
} from './constants';
import { obterValorOpcao } from './obterValorOpcao';

const praticarDesprezandoPrecoOpcoes = () => 0;

const praticarMaiorPreco = (opcoes, valorafetatudo = 0) => {
  const opcionais = opcoes.filter((opcao) => opcao.quantidade);
  return opcionais.length > 0
    ? opcionais.reduce((maiorAtual, opcao) => {
        const preco = obterValorOpcao(opcao, valorafetatudo);
        return preco > maiorAtual ? preco : maiorAtual;
      }, Number.NEGATIVE_INFINITY)
    : 0;
};

const praticarMenorPreco = (opcoes, valorafetatudo = 0) => {
  const opcionais = opcoes.filter((opcao) => opcao.quantidade);
  return opcionais.length > 0
    ? opcionais.reduce((menorAtual, opcao) => {
        const preco = obterValorOpcao(opcao, valorafetatudo);
        return preco < menorAtual ? preco : menorAtual;
      }, Number.POSITIVE_INFINITY)
    : 0;
};

const praticarPrecoOpcao = (opcoes, valorafetatudo = 0) =>
  opcoes
    .filter((opcao) => opcao.quantidade)
    .reduce((valor, opcao) => {
      const { quantidade } = opcao;
      return parseFloat(
        Decimal(valor)
          .add(Decimal(obterValorOpcao(opcao, valorafetatudo)).mul(quantidade))
          .toFixed(3)
          .slice(0, -1)
      );
    }, 0);

const praticarPrecoMedio = (opcoes, valorafetatudo = 0) => {
  const qtd = opcoes.reduce((total, opcao) => {
    const { quantidade } = opcao;
    return total + quantidade;
  }, 0);
  return qtd > 0
    ? parseFloat(
        Decimal(praticarPrecoOpcao(opcoes, valorafetatudo))
          .div(qtd)
          .toFixed(3)
          .slice(0, -1)
      )
    : 0;
};

export const obterValorOpcionais = (
  grupoopcao,
  valorafetatudo = 0,
  valorproduto = 0
) => {
  const { opcoes, stprecopraticado } = grupoopcao;
  switch (stprecopraticado) {
    case STPRECOPRATICADO_PRECO_OPCAO:
      return praticarPrecoOpcao(opcoes, valorafetatudo);
    case STPRECOPRATICADO_MENOR_PRECO:
      return praticarMenorPreco(opcoes, valorafetatudo);
    case STPRECOPRATICADO_PRECO_MEDIO:
      return praticarPrecoMedio(opcoes, valorafetatudo);
    case STPRECOPRATICADO_MAIOR_PRECO:
      return praticarMaiorPreco(opcoes, valorafetatudo);
    case STPRECOPRATICADO_DESPREZA_PRECO:
      return praticarDesprezandoPrecoOpcoes();
    case STPRECOPRATICADO_KIT:
      return valorproduto;
    default:
      return praticarPrecoOpcao(opcoes, valorafetatudo);
  }
};
