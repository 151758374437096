import { useState } from 'react';

import { apiDelivery } from 'services/api';

import pedidosPresenter from './presenters';

/* FIXME: encontrar uma forma de retirar a duplicação em PedidosConcluidosPaginador.js */
const DEFAULT_PAGE_SIZE = 10;

const PEDIDOS_CONCLUIDOS_URL = '/controle/pedido/?pendentes=false';

const usePedidosConcluidos = () => {
  const [responseData, setResponseData] = useState({
    pedidosConcluidos: [],
    qtdPedidosConcluidos: 0
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getPedidosConcluidosFromPage = async (pagina) => {
    const offset = DEFAULT_PAGE_SIZE * pagina;
    const pedidosConcluidosRequestURL = `${PEDIDOS_CONCLUIDOS_URL}&limit=${DEFAULT_PAGE_SIZE}&offset=${offset}`;
    setLoading(true);
    try {
      const { data } = await apiDelivery.get(pedidosConcluidosRequestURL);
      const { count: qtdPedidosConcluidos, results: pedidosConcluidos } = data;
      setResponseData({
        pedidosConcluidos: pedidosPresenter(pedidosConcluidos),
        qtdPedidosConcluidos
      });
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.log(err.message);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return [responseData, loading, error, getPedidosConcluidosFromPage];
};

export default usePedidosConcluidos;
