import React from 'react';
import { Col, Result, Row, Spin } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import PedidoCard from './PedidoCard';
import PedidoCardErrorBoundary from './PedidoCardErrorBoundary';

const propTypes = {
  emptyMessage: PropTypes.string,
  loadingForPedidos: PropTypes.bool,
  pedidos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  )
};

const defaultProps = {
  emptyMessage: 'Você não tem pedidos.',
  loadingForPedidos: false,
  pedidos: []
};

const PedidosLista = ({ emptyMessage, loadingForPedidos, pedidos }) => {
  if (!loadingForPedidos && pedidos.length === 0) {
    return <Result title={emptyMessage} />;
  }

  return (
    <Spin spinning={loadingForPedidos}>
      <Row gutter={16}>
        {pedidos.map((pedido) => (
          <Col xs={24} sm={24} md={12} key={pedido.id}>
            <PedidoCardErrorBoundary referencia={pedido.referencia}>
              <PedidoCard pedido={pedido} />
            </PedidoCardErrorBoundary>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

PedidosLista.propTypes = propTypes;
PedidosLista.defaultProps = defaultProps;

export default PedidosLista;
