import { useState } from 'react';
import Axios from 'axios';
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN } from 'contexts/AuthContext';

Axios.interceptors.response.use(
  (response) => response,

  async (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url === '/api/ead/controle/token/refresh/'
    ) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (
      localStorage.getItem(KEY_REFRESH_TOKEN) &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      const refresh_token = localStorage.getItem(KEY_REFRESH_TOKEN);

      try {
        const response = await Axios.post('/api/ead/controle/token/refresh/', {
          refresh: refresh_token
        });

        localStorage.setItem(KEY_ACCESS_TOKEN, response.data.access);

        Axios.defaults.headers.Authorization = `Bearer ${response.data.access}`;
        originalRequest.headers.Authorization = `Bearer ${response.data.access}`;

        return Axios(originalRequest);
      } catch (err) {
        console.error(err);
      }
    }

    return Promise.reject(error);
  }
);

const useCliente2 = () => {
  const [cliente, setCliente] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCliente = () => {
    setLoading(true);
    Axios.get('/api/ead/controle/cliente/', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(KEY_ACCESS_TOKEN)}`
      }
    })
      .then((response) => response.data)
      .then(([_cliente]) => _cliente)
      .then(setCliente)
      .catch((err) => {
        console.log(err.stack);
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  return [cliente, loading, getCliente, error];
};

export default useCliente2;
