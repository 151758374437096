import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Form, message, Modal, Spin } from 'antd';
import { CreditCardOutlined, DeleteOutlined } from '@ant-design/icons';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import useDeviceWidth from 'hooks/useDeviceWidth';

import { apiDelivery } from 'services/api';
import { obterLabelBandeira, obterImagem } from 'services/cartoes';

import CartaoForm from '../../FinalizarPedido/components/PagarOnlineForm/CartaoForm';

const { Meta } = Card;

const propTypes = {
  cartao: PropTypes.shape({
    bandeira: PropTypes.string,
    id: PropTypes.string,
    numero: PropTypes.string,
    titular: PropTypes.string
  }),
  onSuccess: PropTypes.func.isRequired
};

const defaultProps = {
  cartao: null
};

const ClienteCartao = ({ cartao, onSuccess }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [width] = useDeviceWidth();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (cartao) {
      form.setFieldsValue(cartao);
    }
  }, [cartao]);

  const closeModal = () => setVisible(false);

  const openModal = () => setVisible(true);

  const atualizarCartao = (id, dados) =>
    /* eslint-disable-next-line no-console */
    console.log(`PUT cartao/${id}/`, JSON.stringify(dados, null, 2));

  const criarCartao = (dados) => {
    setLoading(true);
    apiDelivery
      .post('/controle/cartao/', dados)
      .then(closeModal)
      .then(() => onSuccess && onSuccess())
      .then(() => message.success('Cartão salvo com sucesso.'))
      .then(() => !cartao && form.resetFields())
      .catch(() => message.error('Erro ao tentar salvar o cartão de crédito.'))
      .finally(() => setLoading(false));
  };

  const removerCartao = (id) => {
    setDeleteLoading(true);
    apiDelivery
      .delete(`/controle/cartao/${id}/`)
      .then(() => onSuccess && onSuccess())
      .then(() => message.success('Cartão removido com sucesso.'))
      .catch(() => message.error('Erro ao tentar excluir o cartão.'))
      .finally(() => setDeleteLoading(false));
  };

  const submit = (dados) => {
    const { anoExpiracao, mesExpiracao, ...dadosCartao } = dados.cartao;
    const dadosSalvamento = {
      ...dadosCartao,
      expiracao: `${mesExpiracao}/${anoExpiracao}`
    };
    cartao
      ? atualizarCartao(cartao.id, dadosSalvamento)
      : criarCartao(dadosSalvamento);
  };

  const imagem = cartao ? obterImagem(cartao.bandeira) : null;

  return (
    /* eslint-disable-next-line react/jsx-fragments */
    <Fragment>
      <Spin spinning={deleteLoading}>
        <Card
          hoverable={!cartao}
          style={{ marginBottom: 16 }}
          onClick={cartao ? null : openModal}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {cartao ? (
              <Meta
                avatar={
                  imagem ? (
                    <img alt="" src={imagem} style={{ width: 32 }} />
                  ) : (
                    <CreditCardOutlined style={{ fontSize: 32 }} />
                  )
                }
                description={`${obterLabelBandeira(cartao.bandeira)} - ${
                  cartao.numero
                }`}
                title={`${cartao.titular}`}
              />
            ) : (
              <Meta title="Adicionar cartão..." />
            )}
            {cartao && (
              <Button
                icon={<DeleteOutlined />}
                shape="circle"
                type="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  removerCartao(cartao.id);
                }}
              />
            )}
          </div>
        </Card>
      </Spin>

      <Modal
        centered={width < 768}
        footer={[
          <Button key="salvar" type="primary" onClick={() => form.submit()}>
            Salvar
          </Button>
        ]}
        forceRender
        visible={visible}
        title="Dados do cartão"
        width={width * 0.9}
        onCancel={closeModal}
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" onFinish={submit}>
            <CartaoForm form={form} />
          </Form>
        </Spin>
      </Modal>
    </Fragment>
  );
};

ClienteCartao.propTypes = propTypes;
ClienteCartao.defaultProps = defaultProps;

export default ClienteCartao;
