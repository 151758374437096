import { Button, List, Tag } from 'antd';
import React from 'react';

import { removerItem, useCarrinhoContext } from 'contexts/CarrinhoContext';
import PropTypes from 'prop-types';

import CardapioProdutoOpcoes from 'components/Delivery/Layout/Content/Cardapio/Produto/Opcoes';

import { obterValorMonetario, obterValorProduto } from 'services/precos';

const propTypes = {
  indice: PropTypes.number.isRequired,
  produto: PropTypes.object,
  onEditar: PropTypes.func.isRequired
};

const defaultProps = {
  produto: null
};

const { Item } = List;

const CarrinhoProduto = ({ indice, produto, onEditar }) => {
  const { carrinhoDispatcher } = useCarrinhoContext();
  const { descricao, preco, precooferta, observacoes, quantidade } = produto;

  const editar = () => onEditar(produto);

  const valorTotal = obterValorProduto(produto);

  return (
    <>
      <Item
        actions={[
          <Button key="edit" onClick={editar}>
            Editar
          </Button>,
          <Button
            key="delete"
            type="danger"
            onClick={() => carrinhoDispatcher(removerItem(indice))}
          >
            Remover
          </Button>
        ]}
      >
        <Item.Meta
          description={observacoes ? `Observação: ${observacoes}` : ''}
          title={`${quantidade}x ${descricao}`}
        />

        <CardapioProdutoOpcoes produto={produto} />

        <div className="delivery-p-oferta">
          {obterValorMonetario(valorTotal)}
          {precooferta && precooferta < preco ? (
            <Tag className="delivery-p-oferta-tag" color="green">
              OFERTA
            </Tag>
          ) : null}
        </div>
      </Item>
    </>
  );
};

CarrinhoProduto.propTypes = propTypes;
CarrinhoProduto.defaultProps = defaultProps;

export default CarrinhoProduto;
