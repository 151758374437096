import React from 'react';

import {
  AuthBPMPI,
  CurrencyBPMPI,
  InstallmentsBPMPI,
  PaymentMethodBPMPI,
  OrderProductCodeBPMPI,
  OrderRecorrenciaBPMPI,
  OrderModoTransacaoBPMPI,
  MerchantUrlBPMPI,
  UsuarioConvidadoBPMPI,
  UsuarioMetodoAutenticacaoBPMPI,
  UsuarioProtocoloAutenticacaoBPMPI
} from 'utils/InputBPMPI';

const BPMpi3DSFixo = () => {
  return (
    <div>
      <AuthBPMPI value="true" />
      <CurrencyBPMPI value="BRL" />
      <InstallmentsBPMPI value="1" />
      <PaymentMethodBPMPI value="debit" />
      <OrderProductCodeBPMPI value="PHY" />
      <MerchantUrlBPMPI value="https://eadelivery.com.br/" />
      <OrderRecorrenciaBPMPI value="false" />
      <OrderModoTransacaoBPMPI value="S" />
      <UsuarioConvidadoBPMPI value="false" />
      <UsuarioMetodoAutenticacaoBPMPI value="02" />
      <UsuarioProtocoloAutenticacaoBPMPI value="oauth" />
    </div>
  );
};

export default BPMpi3DSFixo;
