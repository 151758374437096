import { Decimal } from 'decimal.js';

import get_sigla_uf from 'components/Delivery/Layout/utils';
import {
  obterValor,
  obterValorAfetaTudo,
  obterValorAjusteOpcao,
  obterValorAjusteProduto,
  obterValorSubTotal,
  obterValorOpcionais
} from 'services/precos';
import {
  STPRECOPRATICADO,
  LISTA_STPRECOPRATICADO_FRACIONADO
} from 'utils/GrupoOpcao';
import { multiplicar_decimal } from 'utils/decimal';

import {
  STTIPOPGTO_EAPAY_CREDITO,
  STTIPOPGTO_EAPAY_DEBITO,
  STTIPOPGTO_PIX
} from './constants';

const serializarEndereco = (endereco) => {
  const { id, cep, complemento, detalhe, uf, ...dadosEndereco } = endereco;
  return {
    ...dadosEndereco,
    uf: get_sigla_uf(uf),
    cep: cep || null,
    complemento: complemento || null,
    referencia: detalhe || null
  };
};

const serializarCliente = (cliente, endereco) => {
  const { id, cnpjoucpf, nomeourazaosocial, telefone } = cliente;
  const dadosCliente = {
    cpf: cnpjoucpf || null,
    external_id: id,
    nome: nomeourazaosocial,
    telefone: telefone || null
  };
  return endereco
    ? { endereco: serializarEndereco(endereco), ...dadosCliente }
    : { endereco: null, ...dadosCliente };
};

const serializarDadosPagamento = (pagamento, valor) => {
  const {
    cartao,
    descricaoFormaPgto,
    formapgto_id,
    pre_pago,
    formapgto_sttipopgto,
    troco_para
  } = pagamento;
  let dadosCartao = null;

  if (pre_pago === 1) {
    const { anoExpiracao, mesExpiracao, ...props } = cartao;
    if (anoExpiracao && mesExpiracao) {
      dadosCartao = {
        ...props,
        expiracao: `${mesExpiracao}/${anoExpiracao}`,
        token: null
      };
    } else if (formapgto_sttipopgto === STTIPOPGTO_PIX) {
      dadosCartao = null;
    } else {
      dadosCartao = {
        ...cartao,
        expiracao: null,
        numero: null,
        salvar: false
      };
    }
  }

  const int_troco_para = troco_para
    ? multiplicar_decimal(troco_para, 100)
    : null;

  let tipo = null;

  if (
    formapgto_sttipopgto === STTIPOPGTO_EAPAY_CREDITO ||
    formapgto_sttipopgto === STTIPOPGTO_EAPAY_DEBITO
  ) {
    tipo = 'CARTAO';
  } else if (formapgto_sttipopgto === STTIPOPGTO_PIX) {
    tipo = 'PIX';
  }

  return [
    {
      cartao: dadosCartao,
      descricaoFormaPgto,
      formapgto_id: formapgto_id || '-1',
      formapgto_sttipopgto,
      pre_pago: pre_pago === 1,
      tipo,
      troco_para: int_troco_para,
      valor
    }
  ];
};

const serializarOpcao = (
  opcao,
  preco_total_grupo_opcao,
  quantidade_opcionais,
  stprecopraticado,
  valorAfetaTudo = 0,
  coeficienteKitDecimal = 0
) => {
  const { descricao, idopcao, quantidade, preco, precooferta } = opcao;
  const preco_unit_inicial = multiplicar_decimal(
    precooferta || preco || 0,
    100
  );

  const quantidadeOpcao = () => {
    if (quantidade === 0 || quantidade_opcionais === 0) {
      return 0;
    }

    const Decimal3 = Decimal.clone({ precision: 3 });

    switch (stprecopraticado) {
      case STPRECOPRATICADO.MenorOpcao:
      case STPRECOPRATICADO.MediaOpcao:
      case STPRECOPRATICADO.MaiorOpcao:
        return new Decimal3(quantidade).div(quantidade_opcionais).toFixed(3);
      // return parseFloat(new Decimal3(quantidade).div(quantidade_opcionais).toFixed(3));
      case STPRECOPRATICADO.Normal:
      case STPRECOPRATICADO.DesprezaOpcao:
      default:
        return quantidade;
    }
  };

  const precoUnitario = () => {
    switch (stprecopraticado) {
      case STPRECOPRATICADO.MenorOpcao:
      case STPRECOPRATICADO.MaiorOpcao:
        return multiplicar_decimal(preco_total_grupo_opcao, 100);
      case STPRECOPRATICADO.DesprezaOpcao:
        return 0;
      case STPRECOPRATICADO.MediaOpcao:
      case STPRECOPRATICADO.Normal:
      default:
        return preco_unit_inicial;
    }
  };

  const preco_unit = precoUnitario();
  const quantidade_opcao = quantidadeOpcao();
  const preco_total = multiplicar_decimal(preco_unit, quantidade_opcao);
  const ajuste = multiplicar_decimal(
    stprecopraticado === STPRECOPRATICADO.Kit
      ? -coeficienteKitDecimal
          .mul(precooferta || preco || 0)
          .mul(quantidade_opcao)
      : obterValorAjusteOpcao(opcao, valorAfetaTudo),
    100
  );

  return {
    ajuste,
    descricao,
    opcao_id: idopcao,
    preco_total,
    preco_unit,
    quantidade: quantidade_opcao,
    quantidade_adicionada: quantidade
  };
};

const serializarGrupoOpcao = (
  grupoOpcao,
  valorAfetaTudo = 0,
  valorProduto = 0
) => {
  const { idgrupoopcao, descricao, opcoes, stprecopraticado } = grupoOpcao;
  const preco_total_grupo_opcao = obterValorOpcionais(
    grupoOpcao,
    valorAfetaTudo
  );
  const quantidade_opcionais = opcoes.reduce((total, opcao) => {
    return total + opcao.quantidade;
  }, 0);

  let coeficienteKit = 0;
  if (grupoOpcao.stprecopraticado === STPRECOPRATICADO.Kit) {
    const totalOpcoesDecimal = grupoOpcao.opcoes.reduce((total, opcao) => {
      const preco = opcao.precooferta || opcao.preco || 0;
      return total.add(Decimal(preco).mul(opcao.quantidade));
    }, Decimal(0));

    coeficienteKit = totalOpcoesDecimal
      .minus(valorProduto)
      .div(totalOpcoesDecimal);
  }

  let sub_subitens = opcoes
    .filter((opcao) => opcao.quantidade)
    .map((opcao) =>
      serializarOpcao(
        opcao,
        preco_total_grupo_opcao,
        quantidade_opcionais,
        stprecopraticado,
        valorAfetaTudo,
        coeficienteKit
      )
    );

  if (
    sub_subitens.length &&
    LISTA_STPRECOPRATICADO_FRACIONADO.includes(stprecopraticado)
  ) {
    const vltotal_subsubitens = sub_subitens.reduce((total, sub_subitem) => {
      return total + sub_subitem.preco_total;
    }, 0);

    const total_grupo_centavos = multiplicar_decimal(
      preco_total_grupo_opcao,
      100
    );

    if (vltotal_subsubitens !== total_grupo_centavos) {
      sub_subitens = sub_subitens.map((sub_subitem, index) => {
        if (index === 0) {
          const diff = total_grupo_centavos - vltotal_subsubitens;
          sub_subitem.preco_total = sub_subitem.preco_total + diff;
        }
        return sub_subitem;
      });
    }
  }

  /*
    esse ponto é responsável por reajustar os sub_subitens no caso do centavo sobressalente
    feito dessa maneira para que não pudesse afetar as outras partes do sistema
  */
  if (grupoOpcao.stprecopraticado === STPRECOPRATICADO.Kit) {
    const valorSubSubItens = sub_subitens.reduce(
      (valorTotal, sub_subitem) =>
        valorTotal + sub_subitem.preco_total + sub_subitem.ajuste,
      0
    );
    const valorProdutoEmCentavos = multiplicar_decimal(valorProduto, 100);

    if (valorProdutoEmCentavos !== valorSubSubItens) {
      const valorSobressalente = valorProdutoEmCentavos - valorSubSubItens;

      const novoSubSubItens = Array.from(sub_subitens).sort(
        (a, b) => b.preco_total - a.preco_total
      );

      const [opcionalComMaiorValor] = novoSubSubItens;

      if (typeof opcionalComMaiorValor?.ajuste === 'number') {
        opcionalComMaiorValor.ajuste += valorSobressalente;
      }

      sub_subitens = novoSubSubItens;
    }
  }

  return {
    ajuste: 0,
    descricao,
    grupoopcao_id: idgrupoopcao,
    preco_total: 0,
    preco_unit: 0,
    quantidade: 1,
    stprecopraticado,
    sub_subitens
  };
};

const serializarItens = (itens) =>
  itens.map((item) => {
    const {
      descricao,
      idproduto,
      grupoopcoes,
      observacoes,
      preco,
      precooferta,
      quantidade
    } = item;
    const possuiGrupoOpcaoKit = grupoopcoes.some(
      (grupoopcao) => grupoopcao.stprecopraticado === STPRECOPRATICADO.Kit
    );

    const preco_unit = possuiGrupoOpcaoKit
      ? 0
      : multiplicar_decimal(precooferta || preco, 100);
    const preco_total = multiplicar_decimal(preco_unit, quantidade);

    const valorAfetaTudo = obterValorAfetaTudo(grupoopcoes);
    const valorAjusteProduto = obterValorAjusteProduto(
      obterValor(item),
      grupoopcoes
    );
    const ajuste = multiplicar_decimal(valorAjusteProduto, 100 * quantidade);

    return {
      ajuste,
      descricao,
      preco_total,
      preco_unit,
      observacoes: observacoes || null,
      produto_id: idproduto,
      quantidade,
      subitens: grupoopcoes
        .map((grupoopcao) =>
          serializarGrupoOpcao(grupoopcao, valorAfetaTudo, precooferta || preco)
        )
        .filter((grupoOpcao) => grupoOpcao.sub_subitens.length > 0)
    };
  });

const serializarPagamento = (dados) => {
  const verificarAbonoTaxaEntrega = (
    a_taxa_entrega,
    a_tipo_entrega,
    a_valorPedidoAbono,
    a_subtotal
  ) => {
    const abonar =
      a_taxa_entrega &&
      a_tipo_entrega === 'delivery' &&
      typeof a_valorPedidoAbono === 'number' &&
      a_subtotal >= multiplicar_decimal(a_valorPedidoAbono, 100);
    return Boolean(abonar);
  };

  const calcularTaxaEntrega = (
    a_taxa_entrega,
    a_tipo_entrega,
    a_valorPedidoAbono,
    a_subtotal
  ) => {
    const nao_eh_delivery = a_tipo_entrega !== 'delivery';
    const taxa_entrega_abonada = verificarAbonoTaxaEntrega(
      a_taxa_entrega,
      a_tipo_entrega,
      a_valorPedidoAbono,
      a_subtotal
    );
    if (nao_eh_delivery || taxa_entrega_abonada) {
      return 0;
    }
    return multiplicar_decimal(a_taxa_entrega, 100);
  };

  const {
    cart,
    cliente,
    enderecoSelecionado,
    estabelecimento,
    pagamento,
    taxaentrega,
    desconto,
    codigo_cupom,
    abono
  } = dados;
  const {
    cpfnota,
    observacoes,
    tipo,
    entrega_as_dia,
    entrega_as_hora
  } = pagamento;
  const { previsaoentregamax, previsaoentregamin } = estabelecimento;
  const deliveryestabelecimento_id = estabelecimento.id;
  let observacoesPedido = observacoes || '';
  const dadosCliente = serializarCliente(
    cliente,
    tipo === 'delivery' ? enderecoSelecionado : null
  );
  const itens = serializarItens(cart);

  const subtotal = multiplicar_decimal(obterValorSubTotal(cart), 100);
  const descontoCentavos = multiplicar_decimal(desconto, 100);
  const abono_taxa_entrega = verificarAbonoTaxaEntrega(
    taxaentrega,
    tipo,
    abono,
    subtotal
  );
  const taxa_entrega = calcularTaxaEntrega(taxaentrega, tipo, abono, subtotal);
  const total = subtotal + taxa_entrega - descontoCentavos;
  const pagamentos = serializarDadosPagamento(pagamento, total);

  if (tipo === 'delivery') {
    const { local_entrega } = pagamento;
    observacoesPedido = `${observacoesPedido}\nEntregar em: ${local_entrega}`;
  }

  return {
    entregue_as:
      entrega_as_hora && entrega_as_dia
        ? `${entrega_as_dia.format().split('T')[0]}T${
            entrega_as_hora.format().split('T')[1]
          }`
        : null,
    ajuste: 0,
    desconto: descontoCentavos,
    cliente: dadosCliente,
    cpf_pagador: cpfnota || null,
    deliveryestabelecimento_id,
    itens,
    observacoes: observacoesPedido || null,
    numero_pedido: Math.floor(Math.random() * 9000) + 1000,
    origem: 'eadelivery',
    pagamentos,
    previsaoentregamax,
    previsaoentregamin,
    subtotal,
    taxa_entrega,
    tipo,
    total,
    endereco_togo: tipo === 'togo' ? estabelecimento.endereco : null,
    codigo_cupom,
    abono_taxa_entrega
  };
};

export default serializarPagamento;
