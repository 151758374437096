import { useState } from 'react';
import { apiDelivery } from 'services/api';

const useToken3DS = () => {
  const [token3DS, setToken3DS] = useState(null);
  const [loading, setLoading] = useState(false);

  const getToken3DS = async () => {
    setLoading(true);
    try {
      const { data } = await apiDelivery.get(`/controle/3ds/token/`);
      setToken3DS(data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return { token3DS, loading, getToken3DS };
};

export default useToken3DS;
