import { Decimal } from 'decimal.js';

import { STPRECOPRATICADO_KIT } from './constants';
import { obterValor } from './obterValor';
import { obterValorAfetaTudo } from './obterValorAfetaTudo';
import { obterValorAjusteProduto } from './obterValorAjusteProduto';
import { obterValorOpcionais } from './obterValorOpcionais';

const obterValorGrupoOpcoes = (grupoopcoes, valorproduto) => {
  if (!grupoopcoes) {
    return 0;
  }

  const valorAfetaTudo = obterValorAfetaTudo(grupoopcoes);

  return grupoopcoes.reduce((valor, grupoopcao) => {
    return parseFloat(
      Decimal(valor).add(
        obterValorOpcionais(grupoopcao, valorAfetaTudo, valorproduto)
      )
    );
  }, 0);
};

export const obterValorProduto = (produto) => {
  const valorUnitario = new Decimal(obterValor(produto));
  const { grupoopcoes, quantidade = 1 } = produto;

  const possuiGrupoOpcaoKit = grupoopcoes.some(
    (grupoopcao) => grupoopcao.stprecopraticado === STPRECOPRATICADO_KIT
  );

  const valorAjuste = obterValorAjusteProduto(valorUnitario, grupoopcoes);
  const valorProduto = possuiGrupoOpcaoKit
    ? new Decimal(0).add(valorAjuste)
    : valorUnitario.add(valorAjuste);

  const valor = valorProduto
    .add(obterValorGrupoOpcoes(grupoopcoes, valorUnitario))
    .mul(quantidade);
  return parseFloat(valor.toFixed(3).slice(0, -1));
};
