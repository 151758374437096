import React, { useState } from 'react';
import { Button, notification } from 'antd';

import { limparCarrinho, useCarrinhoContext } from 'contexts/CarrinhoContext';
import CODIGOS from './codigo';

export default function useValidacao() {
  const [erros, setErros] = useState([]);
  const { carrinho, carrinhoDispatcher } = useCarrinhoContext();
  const NOT_ERRO_GENERICO = {
    message: 'Ops!',
    description: 'Tivemos um probleminha, tente novamente depois'
  };

  function obterErrosValidicao(erro) {
    try {
      if (erro.response.data.codigo === CODIGOS.ERRO.VALIDACAO) {
        const { non_field_errors } = erro.response.data.extra.error;
        if (Array.isArray(non_field_errors)) {
          const lista_codigos = Object.values(CODIGOS.VALIDACAO);
          return non_field_errors.filter((data) =>
            lista_codigos.includes(data.codigo)
          );
        }
      }
    } catch (e) {}
    return [];
  }

  function obterErroLojaFechada(erro) {
    try {
      if (erro.response.status === CODIGOS.ERRO.LOJA_FECHADA) {
        return {
          message: 'Loja Está Fechada',
          description:
            'Infelizmente, a loja está fechada. Você pode tentar novamente mais tarde!'
        };
      }
    } catch (e) {}
    return null;
  }

  function notificacaoErroCielo(dados) {
    const { codigo, msg, extra } = dados;
    const notificacao = {};
    notificacao.message = 'Pagamento Online';
    notificacao.description = msg || 'Erro inesperado na transação';
    switch (codigo) {
      case CODIGOS.PAGAMENTO.COMUNICACAO: {
        try {
          const { error } = extra;
          error.forEach((erro) => {
            if (erro['Code'] === 126) {
              notificacao.description = 'Data de expiração é inválida';
            }
          });
        } catch (e) {
          console.log(e);
        }
        break;
      }
      case CODIGOS.PAGAMENTO.GENERICO: {
        try {
          const { Payment } = extra;
          // console.log('Payment', Payment);
          if (Payment['ReturnMessage']) {
            notificacao.description = Payment['ReturnMessage'];
            // console.log('notificacao.description', notificacao.description);
          }
        } catch (e) {
          console.log(e);
        }
        break;
      }
      default:
        // statements_def
        break;
    }
    return notificacao;
  }

  function obterErroPgtoOnline(erro) {
    try {
      if (erro.response.data.codigo === CODIGOS.ERRO.PAGAMENTO_ONLINE) {
        try {
          return notificacaoErroCielo(erro.response.data.extra);
        } catch (e) {
          return {
            message: 'Pagamento Online',
            description:
              erro?.response?.data?.msg || 'Erro inesperado na transação'
          };
        }
      }
    } catch (e) {}
    return null;
  }

  function obterErroPedidoMinimo(erro) {
    try {
      if (
        erro.response.status === 400 &&
        erro.response.data.codigo === CODIGOS.ERRO.PEDIDO_MINIMO
      ) {
        return {
          message: 'Pedido Mínimo',
          description:
            erro.response.data.msg || 'Pedido mínimo da loja não foi atingido'
        };
      }
    } catch (e) {}
    return null;
  }

  function obterErroPedidoDuplicado(erro) {
    try {
      if (
        erro.response.status === 400 &&
        erro.response.data.codigo === CODIGOS.ERRO.VALIDACAO &&
        erro.response.data.extra.status_code ===
          CODIGOS.VALIDACAO.PEDIDO_DUPLICADO
      ) {
        const erroMsg =
          erro.response.data.extra.error.msg ||
          'O restaurante já recebeu esse pedido';
        const key = `duplicado-${Date.now()}`;
        const onBtnClicked = () => {
          carrinhoDispatcher(limparCarrinho());
          notification.close(key);
        };

        return {
          message: 'Ops!',
          key,
          description: (
            <div>
              {erroMsg}
              <div>
                <Button onClick={onBtnClicked} type="primary">
                  Limpar Carrinho
                </Button>
              </div>
            </div>
          )
        };
      }
    } catch (e) {}
    return null;
  }

  function obterErroCupomInvalido(erro) {
    try {
      if (
        erro.response.status === 400 &&
        (erro.response.data.codigo === CODIGOS.ERRO.CUPOM_DESCONTO_INVALIDO ||
          erro.response.data.codigo === CODIGOS.ERRO.CUPOM_DESCONTO_VALOR)
      ) {
        return {
          message: 'Cupom Inválido',
          description: erro.response.data.msg || 'Cupom indisponível'
        };
      }
    } catch (e) {}
    return null;
  }

  function obterErro500(erro) {
    try {
      if (erro.response.status === 500) {
        return NOT_ERRO_GENERICO;
      }
    } catch (e) {}
    return null;
  }

  function mostrarNotificacao(not) {
    if (!not) return false;

    notification.error({
      ...not,
      duration: 0
    });
    return true;
  }

  function obterErrorDaResponse(erro) {
    return obterErrosValidicao(erro);
  }

  function processarErro(erro) {
    console.log('uv er: ', erro);
    if (!erro) {
      setErros([]);
      return;
    }

    let mostrouErro = false;
    mostrouErro |= mostrarNotificacao(obterErroPedidoMinimo(erro));
    mostrouErro |= mostrarNotificacao(obterErroPgtoOnline(erro));
    mostrouErro |= mostrarNotificacao(obterErroLojaFechada(erro));
    mostrouErro |= mostrarNotificacao(obterErroCupomInvalido(erro));
    mostrouErro |= mostrarNotificacao(obterErroPedidoDuplicado(erro));
    mostrouErro |= mostrarNotificacao(obterErro500(erro));

    const erros_validacao = obterErrorDaResponse(erro);

    mostrouErro |= erros_validacao.length;
    if (!mostrouErro) mostrarNotificacao(NOT_ERRO_GENERICO);

    setErros(erros_validacao);
  }

  return { processarErro, erros };
}
