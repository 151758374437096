import React from 'react';
import { Form } from 'antd';

const ExtAuthInputFormItem = ({ name }) => {
  return (
    <Form.Item hidden name={['cartao', 'external_authentication', name]}>
      <input />
    </Form.Item>
  );
};

const BPMpiForm = () => {
  return (
    <div>
      <ExtAuthInputFormItem name="cavv" />
      <ExtAuthInputFormItem name="xid" />
      <ExtAuthInputFormItem name="eci" />
      <ExtAuthInputFormItem name="version" />
      <ExtAuthInputFormItem name="referenceid" />
    </div>
  );
};

export default BPMpiForm;
