import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Result,
  Row,
  Select,
  Spin,
  Switch,
  Tabs,
  TimePicker,
  Typography
} from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import useCartoes from 'hooks/useCartoes';
import useCliente from 'hooks/useCliente';
import useDeviceWidth from 'hooks/useDeviceWidth';
import useFormasPagamento from 'hooks/useFormasPagamento';

import { limparCarrinho, useCarrinhoContext } from 'contexts/CarrinhoContext';
import { useEnderecoContext } from 'contexts/EnderecoContext';

import { apiDelivery } from 'services/api';
import {
  obterValorCupomDesconto,
  obterValorSubTotal,
  obterValorTotal
} from 'services/precos';

import TelefoneInput from 'components/Common/Telefone/Input';
import { OrderNumberBPMPI, AcessTokenBPMPI } from 'utils/InputBPMPI';

import { removerScript } from './BrasPag/Script';
import FinalizarPedidoResumo from './Resumo';
import ValidacaoModal from './Validacao';
import useValidacao from './Validacao/useValidacao';
import serializarPagamento from './utils';

import { obterHorarioFuncionamento } from '../Cardapio/utils';

import useToken3DS from './BrasPag/useToken3DS';
import BPMpi3DSFixo from './BrasPag/BPMpi3DSFixo';
import BPMpi3DSEndereco from './BrasPag/BPMpi3DSEndereco';
import BPMpiForm from './BrasPag/BPMpiForm';
import BPMMpiScript from './BrasPag/BP.Mpi.3ds20.min';
import BpmpiConfigScript, {
  EVENTOS as EVENTOS_BPMPI
} from './BrasPag/BpmpiConfig';

// import CpfNaNotaField from './components/CpfNaNotaField';
import LocalEntregaField from './components/LocalEntregaField';
import PagarNaEntregaForm from './components/PagarNaEntregaForm';
import PagarOnlineForm from './components/PagarOnlineForm';
import { validarBandeiraDebito } from './components/PagarOnlineForm/TipoCartaoField/utils';
import {
  STTIPOPGTO_EAPAY_CREDITO,
  STTIPOPGTO_EAPAY_DEBITO,
  STTIPOPGTO_PIX,
  TIPO_PAGAMENTO_ENTREGA,
  TIPO_PAGAMENTO_ONLINE,
  VALOR_TIPO_DEBITO
} from './constants';
import {
  adicionarDadosCartaoSalvo,
  obterDescricaoEIdFormaPgtoPix,
  obterDescricaoESttipopgoPeloIdFormaPagamento,
  obterDescricaoSttipopgoEIdFormaPagamentoPeloTipoCartao
} from './helpers';

const { Option } = Select;
const { TabPane } = Tabs;

const PAGAR_ONLINE_TAB_KEY = '1';
const PAGAR_NA_ENTREGA_TAB_KEY = '2';

const isFormaPagamentoOnline = ({ sttipopgto }) =>
  sttipopgto === STTIPOPGTO_EAPAY_CREDITO ||
  sttipopgto === STTIPOPGTO_EAPAY_DEBITO ||
  sttipopgto === STTIPOPGTO_PIX;

const isFormaPagamentoEntrega = (formaPagamento) =>
  !isFormaPagamentoOnline(formaPagamento);

const FinalizarPedido = () => {
  const { endereco: enderecoSelecionado } = useEnderecoContext();
  const { carrinho, carrinhoDispatcher } = useCarrinhoContext();
  const { estabelecimento, pedidoid: pedido_id, taxaentrega, abono } = carrinho;
  const [form] = Form.useForm();
  const [cliente, clienteLoading, clienteErro, { setCliente }] = useCliente();
  const [cartoes, cartoesLoading, cartoesError] = useCartoes();
  const [deviceWidth] = useDeviceWidth();
  const [
    formasPagamento,
    formasPagamentoLoading,
    formasPagamentoErro
  ] = useFormasPagamento(estabelecimento && estabelecimento.id);
  const validacao = useValidacao();
  const history = useHistory();
  const { token3DS, getToken3DS } = useToken3DS();

  const [loading, setLoading] = useState(false);
  const [modalClienteVisible, setModalClienteVisible] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [agendado, setAgendado] = useState(false);
  const [horariosFuncionamento, setHorariosFuncionamento] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [statusEventoBPMpi, setStatusEventoBPMpi] = useState(false);

  const textoAgendamento = useRef();

  const { itens, cupom } = carrinho;

  const subTotal = obterValorSubTotal(itens);
  const valorDesconto = obterValorCupomDesconto(cupom, subTotal);

  /* NOVO CÓDIGO */
  const [formasPagamentoEntrega, setFormasPagamentoEntrega] = useState([]);
  const [formasPagamentoOnline, setFormasPagamentoOnline] = useState([]);
  const [tipoEntrega, setTipoEntrega] = useState({
    delivery: false,
    togo: false
  });
  const [tipoPagamento, setTipoPagamento] = useState(TIPO_PAGAMENTO_ONLINE);

  const separarFormasPagamentoPeloTipo = () => {
    if (formasPagamento.length > 0) {
      setFormasPagamentoEntrega(
        formasPagamento.filter(isFormaPagamentoEntrega)
      );
      setFormasPagamentoOnline(formasPagamento.filter(isFormaPagamentoOnline));
    }
  };

  const selecionarTabPagamentoEntregaQuandoFormasPagamentoOnlineAusente = () => {
    if (formasPagamentoOnline.length === 0) {
      setTipoPagamento(TIPO_PAGAMENTO_ENTREGA);
    } else {
      setTipoPagamento(TIPO_PAGAMENTO_ONLINE);
    }
  };

  useEffect(separarFormasPagamentoPeloTipo, [formasPagamento]);

  useEffect(selecionarTabPagamentoEntregaQuandoFormasPagamentoOnlineAusente, [
    formasPagamentoOnline
  ]);

  const preencherFormExternalAuthenticaion = (detail) => {
    form.setFieldsValue({
      cartao: {
        external_authentication: {
          cavv: detail?.Cavv || '',
          xid: detail?.Xid || '',
          eci: detail?.Eci || '',
          version: detail?.Version || '',
          referenceid: detail?.ReferenceId || ''
        }
      }
    });
  };
  const resetarFormExternalAuthentication = () => {
    preencherFormExternalAuthenticaion({});
  };

  const handleBPMPIEvent = (event) => {
    const getLastReferenceId = () => {
      return localStorage.getItem('3ds_external_authentication_ref_id');
    };
    const setLastReferenceId = (id) => {
      if (id !== null && id !== '') {
        localStorage.setItem('3ds_external_authentication_ref_id', id);
      }
    };
    const isReferenceIdRepeted = (id) => {
      return id === getLastReferenceId();
    };

    setLoading(false);
    try {
      const { detail } = event;
      const { eventName } = detail;
      setStatusEventoBPMpi(eventName);
      // console.log('handleBPMPIEvent', eventName);
      if (
        eventName === EVENTOS_BPMPI.onSuccess ||
        eventName === EVENTOS_BPMPI.onUnenrolled ||
        eventName === EVENTOS_BPMPI.onFailure
      ) {
        if (!isReferenceIdRepeted(detail.ReferenceId)) {
          setLastReferenceId(detail.ReferenceId);
          preencherFormExternalAuthenticaion(detail);
          form.submit();
        }
      } else if (eventName !== EVENTOS_BPMPI.onReady) {
        resetarFormExternalAuthentication();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const adicionarScriptBPMPIConfig = () => {
    const id = 'bpmpi-config';
    if (document.getElementById(id)) {
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.type = 'text/javascript';
    script.text = BpmpiConfigScript;
    document.head.appendChild(script);
  };
  const removerScriptBPMPIConfig = () => removerScript('bpmpi-config');

  const adicionarScriptBPMPILib = () => {
    const id = 'bpmpi-lib';
    if (document.getElementById(id)) {
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.type = 'text/javascript';
    script.text = BPMMpiScript;
    document.head.appendChild(script);
  };

  const removerScriptBPMPILib = () => {
    window.removeEventListener('bpmpi', handleBPMPIEvent);
    removerScript('bpmpi-lib');
  };

  const recarregarScript3DS = () => {
    const isDebitoDisponivel = formasPagamento.some(
      (formaPgto) => formaPgto.sttipopgto === STTIPOPGTO_EAPAY_DEBITO
    );
    if (!isDebitoDisponivel) return;
    resetarFormExternalAuthentication();
    removerScriptBPMPILib();
    adicionarScriptBPMPILib();
  };

  const cleanUpScriptsBPMPI = () => {
    removerScriptBPMPIConfig();
    removerScriptBPMPILib();
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    return cleanUpScriptsBPMPI;
  }, []);

  useEffect(() => {
    const isDebitoDisponivel = formasPagamento.some(
      (formaPgto) => formaPgto.sttipopgto === STTIPOPGTO_EAPAY_DEBITO
    );
    if (isDebitoDisponivel) {
      getToken3DS();
    }
  }, [formasPagamentoOnline]);

  useEffect(() => {
    if (token3DS) {
      adicionarScriptBPMPIConfig();
      adicionarScriptBPMPILib();
      window.addEventListener('bpmpi', handleBPMPIEvent, false);
    }

    return () => {
      // removerScriptBPMPIConfig();
      removerScriptBPMPILib();
    };
  }, [token3DS]);

  const alterarTipoEntrega = (tipoEntrega) => {
    setTipoEntrega({
      delivery: tipoEntrega === 'delivery',
      togo: tipoEntrega === 'togo'
    });
  };

  const alterarTipoPagamentoByTabChange = (tabTipoPagamento) => {
    setTipoPagamento(
      tabTipoPagamento === PAGAR_ONLINE_TAB_KEY
        ? TIPO_PAGAMENTO_ONLINE
        : TIPO_PAGAMENTO_ENTREGA
    );
  };

  const isPagamentoOnline = tipoPagamento === TIPO_PAGAMENTO_ONLINE;
  const valorTotalPedido = obterValorTotal(
    subTotal,
    taxaentrega,
    valorDesconto
  );
  /* FIM NOVO CÓDIGO */

  const efetuarPedido = (dados) => {
    setLoading(true);
    apiDelivery
      .post(`/api/estabelecimento/${estabelecimento.id}/pedido/`, dados)
      .then((response) => response.data)
      .then(({ id }) => history.push(`/pedido/${id}/`))
      .then(() => {
        carrinhoDispatcher(limparCarrinho());
      })
      .catch((err) => {
        validacao.processarErro(err);
        recarregarScript3DS();
      })
      .finally(() => setLoading(false));
  };

  const [formModal] = Form.useForm();

  function abrirModalTelefone(str_telefone) {
    const nao_definido = !str_telefone || str_telefone === '';
    const invalido =
      str_telefone &&
      (str_telefone.match(/\d+/g).join('').length < 10 ||
        str_telefone.match(/\d+/g).join('').length > 11);

    formModal.setFieldsValue({
      telefone: invalido
        ? parseInt(str_telefone.match(/\d+/g).join('').slice(0, 11))
        : null
    });

    setModalMsg(
      invalido
        ? 'Oi, precisamos atualizar seu número de contato para finalizar o pedido.'
        : 'Oi, precisamos do seu número de contato para finalizar o pedido.'
    );
    setModalClienteVisible(nao_definido || invalido);

    return nao_definido || invalido;
  }

  const validarHorarioFuncionamento = (dadosPedido) => {
    const selectedDate = moment(form.getFieldValue('entrega_as_dia')).format(
      'YYYY-MM-DD'
    );
    if (selectedDate) {
      const timesFromSelectedDay = estabelecimento.horariofuncionamento_set
        .filter((horario) => horario.diadasemana === moment(selectedDate).day())
        .map((horario) => {
          const { abertura, fechamento } = horario;
          return {
            abertura: moment(
              `${selectedDate} ${abertura}`,
              'YYYY-MM-DD HH:mm:ss'
            ),
            fechamento: moment(
              `${selectedDate} ${fechamento}`,
              'YYYY-MM-DD HH:mm:ss'
            )
          };
        });
      setHorariosFuncionamento(timesFromSelectedDay);
      return timesFromSelectedDay.some(
        (horario) =>
          (moment(dadosPedido.entregue_as, 'YYYY-MM-DD HH:mm:ss').isBetween(
            horario.abertura,
            horario.fechamento
          ) ||
            moment(dadosPedido.entregue_as, 'YYYY-MM-DD HH:mm:ss').isSame(
              horario.abertura
            ) ||
            moment(dadosPedido.entregue_as, 'YYYY-MM-DD HH:mm:ss').isSame(
              horario.fechamento
            )) &&
          moment(dadosPedido.entregue_as, 'YYYY-MM-DD HH:mm:ss').isAfter(
            moment()
          )
      );
    }
  };

  const handleSubmit = (dados) => {
    if (dados.tipo === 'delivery' && !enderecoSelecionado) {
      notification.error({
        key: 'pedido_endereco',
        message: 'Entrega Delivery',
        description:
          'Escolha um endereço para a entrega do pedido ou receba o pedido no estabelecimento.',
        duration: 10
      });
      document.getElementById('modalEndereco').click();

      return;
    }

    if (dados.tipo === 'delivery' && taxaentrega == null) {
      Modal.confirm({
        title: 'Entrega Delivery',
        icon: <ExclamationCircleOutlined />,
        content:
          'Estabelecimento não entrega no endereço escolhido. Selecione retirar no estabelecimento no tipo de entrega.',
        okText: deviceWidth < 768 ? 'Ok' : 'Retirar No Estabelecimento',
        width: deviceWidth < 768 ? '100%' : '60%',
        onOk: () => {
          window.scrollTo(0, 100);
        },
        onCancel() {}
      });
      return;
    }

    if (tipoPagamento === TIPO_PAGAMENTO_ONLINE) {
      try {
        validarBandeiraDebito(dados?.cartao);
      } catch (erro) {
        notification.error({
          key: 'cartao_debito',
          message: 'Pagamento Online',
          description: erro.message,
          duration: 10
        });
        return;
      }

      const autentica_pgto_3ds_disponivel =
        statusEventoBPMpi === EVENTOS_BPMPI.onReady &&
        dados?.cartao?.tipo === VALOR_TIPO_DEBITO &&
        !dados?.cartao?.token;

      if (autentica_pgto_3ds_disponivel) {
        setLoading(true);
        window.bpmpi_authenticate();
        return;
      }

      const tipo = dados?.cartao?.tipo;
      const dadosCartaoSalvo = adicionarDadosCartaoSalvo(cartoes, dados);
      /* FIXME: corrigir a mutabilidade nos dados do cartão */
      dados.cartao = { ...dadosCartaoSalvo, tipo };
    }

    let descricaoFormaPgto = null;
    let formapgto_sttipopgto = null;
    if (dados.formapgto_id) {
      const {
        descricao,
        sttipopgto
      } = obterDescricaoESttipopgoPeloIdFormaPagamento(
        formasPagamento,
        dados.formapgto_id
      );
      descricaoFormaPgto = descricao;
      formapgto_sttipopgto = sttipopgto;
    } else if (!dados?.cartao?.tipo) {
      // pix
      formapgto_sttipopgto = STTIPOPGTO_PIX;
      const dadosPix = obterDescricaoEIdFormaPgtoPix(formasPagamento);
      dados.formapgto_id = dadosPix?.idformapgto;
      descricaoFormaPgto = dadosPix?.descricao;
    } else {
      const {
        descricao,
        idformapgto,
        sttipopgto
      } = obterDescricaoSttipopgoEIdFormaPagamentoPeloTipoCartao(
        formasPagamento,
        dados.cartao.tipo
      );
      dados.formapgto_id = idformapgto;
      descricaoFormaPgto = descricao;
      formapgto_sttipopgto = sttipopgto;
    }

    const codigo_cupom = cupom ? cupom.codigo : null;

    const dadosPedido = serializarPagamento({
      pagamento: {
        ...dados,
        pre_pago: tipoPagamento,
        descricaoFormaPgto,
        formapgto_sttipopgto
      },
      cliente,
      cart: itens,
      enderecoSelecionado,
      estabelecimento,
      taxaentrega,
      abono,
      desconto: valorDesconto,
      codigo_cupom
    });

    if (!validarHorarioFuncionamento(dadosPedido) && agendado) {
      Modal.confirm({
        title: 'Horário inválido',
        icon: <ExclamationCircleOutlined />,
        content:
          'Agendamento fora do horário de funciomanento do estabelecimento, \n ou o horário escolhido já passou.',

        okText: 'Ok',
        width: deviceWidth < 768 ? '100%' : '60%',
        onOk: () => {
          window.scrollTo(
            0,
            textoAgendamento.current.getBoundingClientRect().y * -1 - 130
          );
        },
        onCancel() {}
      });
      return;
    }

    // console.log(JSON.stringify({ pedido_id, ...dadosPedido }, null, 2));

    if (!abrirModalTelefone(cliente.telefone)) {
      efetuarPedido({ pedido_id, ...dadosPedido });
    }
  };

  if (itens.length === 0) {
    if (estabelecimento && estabelecimento.id) {
      return <Redirect to={`/estabelecimento/${estabelecimento.id}/`} />;
    }
    const caminhoEstabelecimentoVisitado = window.localStorage.getItem(
      'caminho_estabelecimento_visitado'
    );
    if (caminhoEstabelecimentoVisitado) {
      return <Redirect to={caminhoEstabelecimentoVisitado} />;
    }
    return <Redirect to="/" />;
  }

  const disableHours = () => {
    let hours = [];

    if (
      !moment(form.getFieldValue('entrega_as_dia'), 'day').isAfter(
        moment().startOf('day'),
        'day'
      )
    ) {
      for (let i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }

      return hours;
    }
  };

  const getDisabledHours = (selectedHour) => {
    let minutes = [];
    if (
      !moment(form.getFieldValue('entrega_as_dia'), 'day').isAfter(
        moment().startOf('day'),
        'day'
      )
    ) {
      if (selectedHour === moment().hour()) {
        for (let i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
      return minutes;
    }
  };

  function disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  const onTipoEntregaChanged = (value) => {
    const tpEntrega = {};
    tpEntrega.delivery = value === 'delivery';
    tpEntrega.togo = value === 'togo';
    setTipoEntrega(tpEntrega);
  };

  if (clienteErro || formasPagamentoErro) {
    return (
      <Result
        subTitle="Tente novamente após recarregar a página."
        title="Ocorreu um erro ao tentar obter os dados do pedido."
      />
    );
  }

  return (
    <>
      <Modal
        forceRender
        visible={modalClienteVisible}
        onOk={formModal.submit}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setModalClienteVisible(false);
          formModal.resetFields();
          notification.error({
            key: 'cliente_telefone',
            message: 'Número de Telefone',
            description: 'Precisamos do seu contato para concluir o pedido =(',
            duration: 10
          });
        }}
      >
        <div>
          <p>{modalMsg}</p>
          <Form
            form={formModal}
            layout="vertical"
            scrollToFirstError
            onFinish={async (values) => {
              try {
                setConfirmLoading(true);
                const { data } = await apiDelivery.patch(
                  `/controle/cliente/${cliente.id}/`,
                  values
                );
                setCliente(data);
                formModal.resetFields();
                setModalClienteVisible(false);
                form.submit();
              } catch (e) {
                notification.error({
                  key: 'cliente_telefone2',
                  message: 'Número de Telefone',
                  description:
                    'Não foi possível atualizar seu telefone, tente novamente mais tarde!',
                  duration: 10
                });
                console.log(e);
              } finally {
                setConfirmLoading(false);
              }
            }}
          >
            <Form.Item
              label="Telefone"
              name="telefone"
              rules={[
                {
                  type: 'number',
                  min: 1000000000,
                  message:
                    'Telefone deve ter o DDD e 8 dígitos (fixo) ou 9 dígitos (celular)!'
                },
                { required: true, message: 'Telefone é obrigatório' }
              ]}
            >
              <TelefoneInput />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {token3DS && (
        <div>
          <OrderNumberBPMPI value={pedido_id} />
          <AcessTokenBPMPI type="text" value={token3DS?.access_token} />
          <BPMpi3DSFixo />
          <BPMpi3DSEndereco
            endereco={enderecoSelecionado}
            nome={cliente.nomeourazaosocial}
            telefone={cliente.telefone}
            email={cliente.email}
          />
        </div>
      )}
      <Spin spinning={loading}>
        <FinalizarPedidoResumo
          cupom={cupom}
          enderecoEstabelecimento={estabelecimento.endereco}
          enderecoCliente={enderecoSelecionado}
          itens={itens}
          onContinuarComprandoClicked={() =>
            history.push(`/estabelecimento/${estabelecimento.id}`)
          }
          subtotal={subTotal}
          taxaentrega={taxaentrega}
          abono={abono}
          tipoEntrega={tipoEntrega}
          title={estabelecimento && estabelecimento.apelidooufantasia}
        />

        <Form
          form={form}
          initialValues={{
            agendado: false,
            cartao: {
              salvar: false
            },
            local_entrega: 'Quero encontrar o entregador.',
            observacoes: '',
            tipo: null
          }}
          layout="vertical"
          name="pagamento-form"
          scrollToFirstError
          onFinish={handleSubmit}
          style={{ marginTop: 16 }}
        >
          {token3DS && <BPMpiForm />}
          <Form.Item
            label="Agendar entrega/retirada?"
            name="agendado"
            rules={[{ message: 'Campo obrigatório.', required: false }]}
          >
            <Switch
              onChange={() => {
                setAgendado(!agendado);
                // form.setFieldsValue({
                //   entrega_as_dia: moment().startOf('day')
                // });
                if (!agendado) {
                  form.setFieldsValue({
                    entrega_as_dia: null,
                    entrega_as_hora: null
                  });
                }
              }}
            >
              Entregar agora{' '}
            </Switch>
          </Form.Item>

          <Row gutter={[16, 16]} hidden={!agendado}>
            <Col>
              <div ref={textoAgendamento}>
                <Typography.Text type={'secondary'}>
                  Horário de Funcionamento:{' '}
                </Typography.Text>
              </div>
              {obterHorarioFuncionamento(
                estabelecimento.horariofuncionamento_set
              ).map((dia, indice) => (
                <li key={indice}>
                  {dia.descricao}:{' '}
                  {dia.horarios
                    .map(
                      ({ abertura, fechamento }) =>
                        `${abertura.slice(0, 5)} às ${fechamento.slice(0, 5)}`
                    )
                    .join(', ')}
                </li>
              ))}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={6}>
              <Form.Item
                label="Data agendamento: "
                name="entrega_as_dia"
                rules={[
                  {
                    message: 'Campo obrigatório.',
                    required: !!agendado
                  }
                ]}
                hidden={!agendado}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                label="Hora agendamento: "
                name="entrega_as_hora"
                rules={[
                  {
                    message: 'Campo obrigatório.',
                    required: !!agendado
                  }
                ]}
                hidden={!agendado}
              >
                <TimePicker
                  style={{ width: '100%' }}
                  format="HH:mm"
                  inputReadOnly
                  disabledHours={disableHours}
                  disabledMinutes={(selectedHour) =>
                    getDisabledHours(selectedHour)
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/* NOVO CÓDIGO */}
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                label="Tipo de entrega"
                name="tipo"
                rules={[{ message: 'Campo obrigatório', required: true }]}
              >
                <Select onChange={alterarTipoEntrega}>
                  <Option value="delivery">Delivery</Option>
                  <Option value="togo">Retirar no estabelecimento</Option>
                </Select>
              </Form.Item>
            </Col>

            {/*<Col xs={24} md={12}>
              <CpfNaNotaField cpfCliente={cliente.cnpjoucpf} form={form} />
            </Col>*/}

            <Col xs={24}>
              <Form.Item label="Observações" name="observacoes">
                <Input.TextArea rows={4} style={{ resize: 'none' }} />
              </Form.Item>
            </Col>

            {tipoEntrega.delivery && (
              <Col xs={24}>
                <LocalEntregaField
                  form={form}
                  isPagamentoOnline={isPagamentoOnline}
                />
              </Col>
            )}
          </Row>

          {!cartoesLoading && !formasPagamentoLoading ? (
            <Row gutter={16}>
              <Col md={{ offset: 6, span: 12 }}>
                {formasPagamentoOnline.length === 0 &&
                  formasPagamentoEntrega.length === 0 && (
                    <Alert
                      description="Selecione pelo menos um meio de pagamento."
                      message="Nenhum meio de pagamento configurado."
                      showIcon
                      style={{ marginBottom: 16 }}
                      type="warning"
                    />
                  )}
              </Col>

              <Col xs={24}>
                <Tabs
                  activeKey={
                    tipoPagamento === TIPO_PAGAMENTO_ONLINE
                      ? PAGAR_ONLINE_TAB_KEY
                      : PAGAR_NA_ENTREGA_TAB_KEY
                  }
                  onChange={alterarTipoPagamentoByTabChange}
                >
                  {formasPagamentoOnline.length > 0 && (
                    <TabPane key={PAGAR_ONLINE_TAB_KEY} tab="Pagar online">
                      {cartoesError && (
                        <Alert
                          description="Você pode continuar o pagamento inserindo os dados de um cartão."
                          message="Erro ao tentar obter os cartões salvos."
                          showIcon
                          style={{ marginBottom: 16 }}
                          type="error"
                        />
                      )}

                      {tipoPagamento === TIPO_PAGAMENTO_ONLINE && (
                        <PagarOnlineForm
                          cartoes={cartoes}
                          form={form}
                          formasPagamento={formasPagamentoOnline}
                        />
                      )}
                    </TabPane>
                  )}

                  {formasPagamentoEntrega.length > 0 && (
                    <TabPane
                      key={PAGAR_NA_ENTREGA_TAB_KEY}
                      tab="Pagar na entrega"
                    >
                      {tipoPagamento === TIPO_PAGAMENTO_ENTREGA && (
                        <PagarNaEntregaForm
                          form={form}
                          formasPagamento={formasPagamentoEntrega}
                          valorTotal={valorTotalPedido}
                        />
                      )}
                    </TabPane>
                  )}
                </Tabs>
              </Col>
            </Row>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <LoadingOutlined />
            </div>
          )}

          {/* FIM NOVO CÓDIGO */}

          <Row gutter={16}>
            <Col
              xs={24}
              md={{ offset: 6, span: 12 }}
              lg={{ offset: 8, span: 8 }}
            >
              <Form.Item>
                <Button
                  block
                  htmlType="submit"
                  size="large"
                  style={{ marginTop: 16 }}
                  type="primary"
                >
                  Finalizar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <ValidacaoModal
        erros={validacao.erros}
        visible={validacao.erros.length}
        onOk={() => validacao.processarErro(null)}
      />
    </>
  );
};

export default FinalizarPedido;
