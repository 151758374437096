import React, { useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';

import { CardExpMonthBPMPI, CardExpYearBPMPI } from 'utils/InputBPMPI';

import { ANOS_VALIDADE_CARTAO, MESES_DO_ANO } from './utils';

const { Option } = Select;

/* FIXME: pesquisar uma forma de validar o campo */
const PagamentoValidadeCartao = () => {
  const [cardExpMonth, setCardExpMonth] = useState('');
  const [cardExpYear, setCardExpYear] = useState('');

  return (
    <>
      <Form.Item label="Validade">
        <Input.Group style={{ width: '100%' }}>
          <Row gutter={16}>
            <Col xs={12} md={12}>
              <Form.Item
                name={['cartao', 'mesExpiracao']}
                noStyle
                rules={[{ message: 'Informe o mês.', required: true }]}
              >
                <Select placeholder="Mês" onChange={setCardExpMonth} style={{ width: '100%' }}>
                  {MESES_DO_ANO.map((mes) => (
                    <Option key={mes} value={mes}>
                      {mes}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={12} md={12}>
              <Form.Item
                name={['cartao', 'anoExpiracao']}
                noStyle
                rules={[{ message: 'Informe o ano.', required: true }]}
              >
                <Select placeholder="Ano" onChange={setCardExpYear} style={{ width: '100%' }}>
                  {ANOS_VALIDADE_CARTAO.map((ano) => (
                    <Option key={ano} value={`${ano}`}>
                      {ano}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Input.Group>
      </Form.Item>
      <CardExpMonthBPMPI value={cardExpMonth} />
      <CardExpYearBPMPI value={cardExpYear} />
    </>
  );
};

export default PagamentoValidadeCartao;
