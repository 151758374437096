import React, { useState } from 'react';
import { Avatar, Button, Dropdown, Menu } from 'antd';

import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useAuthContext } from 'contexts/AuthContext';
import { useClienteContext } from 'contexts/ClienteContext';
import useDeviceWidth from 'hooks/useDeviceWidth';

export const AVATAR_KEY = 'avatar';

export function clearAvatar() {
  localStorage.removeItem(AVATAR_KEY);
}

export function setAvatar(url) {
  if (url) {
    localStorage.setItem(AVATAR_KEY, url);
  } else {
    clearAvatar();
  }
}

const UserComponent = () => {
  const avatarUrl = localStorage.getItem(AVATAR_KEY);

  if (!avatarUrl) {
    return (
      <UserOutlined
        style={{
          fontSize: '25px',
          color: '#ccc'
        }}
      />
    );
  }

  return <Avatar style={{ marginBottom: 5 }} src={avatarUrl} />;
};

export default function UsuarioAvatar() {
  const { auth } = useAuthContext();
  const { cliente } = useClienteContext();
  const [deviceWidth] = useDeviceWidth();
  const [visibleDrop, setVisibleDrop] = useState(false);

  function UserDropDown({ cliente }) {
    return (
      <Menu onClick={() => setVisibleDrop(false)}>
        <Menu.Item disabled key="1">
          Olá {cliente && cliente.cliente && cliente.cliente.nomeourazaosocial}!
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2">
          <Link to="/cliente">
            <UserOutlined style={{ marginRight: '10px' }} />
            Alterar Dados
          </Link>
        </Menu.Item>

        <Menu.Item key="4">
          <Link to="/logout">
            <LogoutOutlined style={{ marginRight: '10px' }} />
            Sair
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <>
      {auth.isLoggedIn ? (
        <Dropdown
          placement={deviceWidth < 768 ? 'topCenter' : 'bottomRight'}
          key={3}
          lit
          overlay={<UserDropDown cliente={cliente} />}
          trigger={['click']}
          visible={visibleDrop}
          onVisibleChange={setVisibleDrop}
        >
          <span style={{ cursor: 'pointer' }}>
            <UserComponent />
          </span>
        </Dropdown>
      ) : (
        <Link to="/login">
          <Button type="primary">Entrar</Button>
        </Link>
      )}
    </>
  );
}
