import React from 'react';
import { Tag } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import moment from 'moment';

import StatusPedido, { ordernarPorDataHoraDec } from 'utils/Pedido';

const propTypes = {
  historico: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.number
    })
  )
};

const defaultProps = {
  historico: []
};

const exibirDescricaoStatus = (status) => {
  switch (status) {
    case StatusPedido.Realizado:
      return 'Realizado';
    case StatusPedido.PgtoPendente:
      return 'Pagamento pendente';
    case StatusPedido.Sincronizado:
      return 'Realizado';
    case StatusPedido.Confirmado:
      return 'Confirmado';
    case StatusPedido.Pronto:
      return 'Pronto';
    case StatusPedido.Entregue:
      return 'Entregue';
    case StatusPedido.Cancelado:
    case StatusPedido.CancelamentoSincronizado:
      return 'Cancelado';
    case StatusPedido.Concluido:
      return 'Concluído';
    default:
      return 'Realizado';
  }
};

const hasCancelado = (historico) => historico.status === StatusPedido.Cancelado;

const PedidoStatus = ({ historico }) => {
  if (historico.length === 0) {
    return null;
  }

  if (historico.some(hasCancelado)) {
    return (
      <Tag color="red" style={{ marginRight: 0 }}>
        Cancelado
      </Tag>
    );
  }

  historico.sort(ordernarPorDataHoraDec);
  const [ultimaAtualizacao] = historico.slice(-1);

  return (
    <Tag
      color={
        ultimaAtualizacao.status === StatusPedido.Concluido ? 'green' : 'blue'
      }
      style={{ marginRight: 0 }}
    >
      {exibirDescricaoStatus(ultimaAtualizacao.status)}
    </Tag>
  );
};

PedidoStatus.propTypes = propTypes;
PedidoStatus.defaultProps = defaultProps;

export default PedidoStatus;
