import { Decimal } from 'decimal.js';

export const obterValorCupomDesconto = (cupom, subtotal) => {
  if (!cupom || !Object.keys(cupom).length) return '0';
  const subtotalDecimal = Decimal(subtotal);

  let desconto = Decimal(cupom.desconto);
  if (cupom.ehpercentual) {
    desconto = desconto.mul(subtotal).div(100);
  }

  if (cupom.limitedesconto > 0) {
    desconto = Math.min(desconto, Decimal(cupom.limitedesconto));
  }

  desconto = Math.min(
    desconto,
    Decimal(Decimal(subtotalDecimal).mul(100) - Decimal(0.01)).div(100)
  );
  return parseFloat(desconto);
};
