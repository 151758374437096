import IMAGEM_BANDEIRA_AMEX from 'assets/cartoes/amex.png';
import IMAGEM_BANDEIRA_DINERS from 'assets/cartoes/diners.png';
import IMAGEM_BANDEIRA_ELO from 'assets/cartoes/elo.png';
import IMAGEM_BANDEIRA_HIPERCARD from 'assets/cartoes/hipercard.png';
import IMAGEM_BANDEIRA_MASTER from 'assets/cartoes/mastercard.png';
import IMAGEM_BANDEIRA_VISA from 'assets/cartoes/visa.png';

export const STTIPOPGTO_DINHEIRO = 0;
export const STTIPOPGTO_EAPAY_CREDITO = 60;
export const STTIPOPGTO_EAPAY_DEBITO = 61;
export const STTIPOPGTO_PIX = 200;

export const TIPO_PAGAMENTO_ENTREGA = 0;
export const TIPO_PAGAMENTO_ONLINE = 1;

export const VALOR_BANDEIRA_AMEX = 'Amex';
export const VALOR_BANDEIRA_DINERS = 'Diners';
export const VALOR_BANDEIRA_ELO = 'Elo';
export const VALOR_BANDEIRA_HIPERCARD = 'Hipercard';
export const VALOR_BANDEIRA_MASTER = 'Master';
export const VALOR_BANDEIRA_VISA = 'Visa';

export const VALOR_TIPO_CREDITO = 'credito';
export const VALOR_TIPO_DEBITO = 'debito';

export const BANDEIRAS_CARTAO_DELIVERY = [
  {
    descricao: 'AMEX',
    imagem: IMAGEM_BANDEIRA_AMEX,
    padrao: /^3[47][0-9]{13}/,
    valor: VALOR_BANDEIRA_AMEX
  },
  {
    descricao: 'Diners',
    imagem: IMAGEM_BANDEIRA_DINERS,
    padrao: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    valor: VALOR_BANDEIRA_DINERS
  },
  {
    descricao: 'ELO',
    imagem: IMAGEM_BANDEIRA_ELO,
    padrao: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    valor: VALOR_BANDEIRA_ELO
  },
  {
    descricao: 'Hipercard',
    imagem: IMAGEM_BANDEIRA_HIPERCARD,
    padrao: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    valor: VALOR_BANDEIRA_HIPERCARD
  },
  {
    descricao: 'Mastercard',
    imagem: IMAGEM_BANDEIRA_MASTER,
    padrao: /^5[1-5][0-9]{14}/,
    valor: VALOR_BANDEIRA_MASTER
  },
  {
    descricao: 'VISA',
    imagem: IMAGEM_BANDEIRA_VISA,
    padrao: /^4[0-9]{12}(?:[0-9]{3})/,
    valor: VALOR_BANDEIRA_VISA
  }
];
