import React, { memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { notification, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import StatusPedido, { ordernarPorDataHoraDec } from 'utils/Pedido';

const { Text } = Typography;

const propTypes = {
  pedidosPendentes: PropTypes.arrayOf(
    PropTypes.shape({
      historico: PropTypes.arrayOf(
        PropTypes.shape({
          datahora: PropTypes.string,
          status: PropTypes.number
        })
      ),
      id: PropTypes.string
    })
  )
};

const defaultProps = {
  pedidosPendentes: []
};

const ordenarHistoricoStatusPedido = (pedidoPendente) => {
  pedidoPendente.historico.sort(ordernarPorDataHoraDec);
  return pedidoPendente;
};

const isUltimoStatusPedidoPgtoPendente = (pedidoPendente) => {
  const ultimaEntradaHistorico =
    pedidoPendente.historico[pedidoPendente.historico.length - 1];
  return ultimaEntradaHistorico.status === StatusPedido.PgtoPendente;
};

const AVISO_PEDIDOS_PGTO_PENDENTE_ID = 'aviso-pedidos-pgto-pendente';

const CONFIGURACAO_AVISO_PEDIDOS_PGTO_PENDENTE = {
  duration: 0,
  icon: (
    <Text type="warning">
      <ExclamationCircleOutlined />
    </Text>
  ),
  key: AVISO_PEDIDOS_PGTO_PENDENTE_ID,
  top: 72,
  style: { cursor: 'pointer' }
};

const NotificacaoPagamentoPendente = ({ pedidosPendentes }) => {
  const history = useHistory();
  const location = useLocation();

  if (location.pathname.includes('/pedido/')) {
    notification.close(AVISO_PEDIDOS_PGTO_PENDENTE_ID);
    return null;
  }

  const pedidosComPagamentoPendente = pedidosPendentes
    .map(ordenarHistoricoStatusPedido)
    .filter(isUltimoStatusPedidoPgtoPendente);

  const gerarNotificacaoPedidoPgtoPendenteComId = (idpedido) => {
    notification.open({
      ...CONFIGURACAO_AVISO_PEDIDOS_PGTO_PENDENTE,
      description: 'Clique aqui para concluir o pagamento.',
      message: 'Você possui um pedido com pagamento pendente.',
      onClick: () => history.push(`/pedido/${idpedido}/`)
    });
  };

  const gerarNotificacaoPedidosPgtoPendente = () => {
    notification.open({
      ...CONFIGURACAO_AVISO_PEDIDOS_PGTO_PENDENTE,
      description:
        'Clique aqui para ser redirecionado à página de pedidos e concluir o pagamento.',
      message: 'Você possui pedidos com pagamento pendente.',
      onClick: () => history.push('/pedido/')
    });
  };

  if (pedidosComPagamentoPendente.length === 1) {
    const [pedidoComPagamentoPendente] = pedidosComPagamentoPendente;
    gerarNotificacaoPedidoPgtoPendenteComId(pedidoComPagamentoPendente.id);
    return null;
  }

  if (pedidosComPagamentoPendente.length > 1) {
    gerarNotificacaoPedidosPgtoPendente();
    return null;
  }

  return null;
};

NotificacaoPagamentoPendente.propTypes = propTypes;
NotificacaoPagamentoPendente.defaultProps = defaultProps;

const atualizarQuandoQtdeMudar = (prevProps, nextProps) => {
  return (
    prevProps?.pedidosPendentes.length === nextProps?.pedidosPendentes.length
  );
};

export default memo(NotificacaoPagamentoPendente, atualizarQuandoQtdeMudar);
