import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import useDeviceWidth from 'hooks/useDeviceWidth';

const propTypes = {
  deliveryestabelecimento: PropTypes.string,
  onRedirect: PropTypes.func
};

const defaultProps = {
  deliveryestabelecimento: null,
  onRedirect: null
};

const BotaoContinuarComprando = ({ deliveryestabelecimento, onRedirect }) => {
  const history = useHistory();
  const [width] = useDeviceWidth();

  const redirecionarParaPaginaEstabelecimento = useCallback(() => {
    history.push(`/estabelecimento/${deliveryestabelecimento}`);
  });

  const executarOnRedirectEDepoisRedirecionar = useCallback(() => {
    onRedirect();
    redirecionarParaPaginaEstabelecimento();
  });

  if (!deliveryestabelecimento) {
    return null;
  }

  return (
    <Button
      block={width <= 576}
      style={{ fontWeight: 'bold' }}
      type="link"
      onClick={
        onRedirect
          ? executarOnRedirectEDepoisRedirecionar
          : redirecionarParaPaginaEstabelecimento
      }
    >
      Continuar comprando
    </Button>
  );
};

BotaoContinuarComprando.propTypes = propTypes;
BotaoContinuarComprando.defaultProps = defaultProps;

export default BotaoContinuarComprando;
