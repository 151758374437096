import { useEffect, useState } from 'react';
import { apiDelivery } from 'services/api';

const opcaoSerializer = (opcao) => {
  const { idopcao_str: idopcao, ...props } = opcao;
  return { ...props, idopcao, quantidade: 0 };
};

const grupoOpcaoSerializer = (grupoOpcao) => {
  const {
    idgrupoopcao_str: idgrupoopcao,
    multiplo,
    opcoes,
    qtdinclusa,
    qtdmultiplomax,
    qtdmultiplomin,
    ...props
  } = grupoOpcao;
  return {
    ...props,
    idgrupoopcao,
    multiplo: parseInt(multiplo, 10),
    opcoes: opcoes
      .filter((opcao) => opcao.stindisponivel !== 2)
      .map((opcao) => opcaoSerializer(opcao)),
    qtdinclusa: parseInt(qtdinclusa, 10),
    qtdmultiplomax: parseInt(qtdmultiplomax, 10),
    qtdmultiplomin: parseInt(qtdmultiplomin, 10)
  };
};

const produtoSerializer = (produto, grupoOpcoesPai) => {
  const {
    descricao,
    detalhe,
    grupoopcoes,
    idproduto_str: idproduto,
    preco,
    precooferta,
    stindisponivel,
    imagem
  } = produto;

  function removerGrupoOpcoesPaiNegados() {
    const goNegados = grupoopcoes.filter((go) => go.stnegagrupoopcao);

    if (goNegados.length) {
      return grupoOpcoesPai.filter(
        (go) => !goNegados.some((el) => el.idgrupoopcao_str === go.idgrupoopcao)
      );
    }
    return grupoOpcoesPai;
  }

  return {
    descricao,
    detalhe,
    grupoopcoes: [
      ...removerGrupoOpcoesPaiNegados(),
      ...grupoopcoes
        .filter((go) => !go.stnegagrupoopcao)
        .map((grupoOpcao) => grupoOpcaoSerializer(grupoOpcao))
        .filter(
          (grupoOpcao) =>
            !grupoOpcoesPai.some(
              (grupoOpcaoPai) =>
                grupoOpcaoPai.idgrupoopcao === grupoOpcao.idgrupoopcao
            )
        )
    ],
    idproduto,
    imagem,
    preco: preco === null ? 0 : preco,
    precooferta,
    stindisponivel
  };
};

/* eslint-disable-next-line no-unused-vars */
const defaultSerializer = (item) => {
  const { idgrupo_str: idgrupo, descricao, grupoopcoes, produtos } = item;
  const grupoOpcoesPai = grupoopcoes.map((grupoOpcao) =>
    grupoOpcaoSerializer(grupoOpcao)
  );
  return {
    descricao,
    idgrupo,
    produtos: produtos.map((produto) =>
      produtoSerializer(produto, grupoOpcoesPai)
    )
  };
};

const useMenu = (idestabelecimento, serializer = defaultSerializer) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (idestabelecimento) {
      setLoading(true);
      apiDelivery
        .get(`/api/estabelecimento/${idestabelecimento}/menu/`)
        .then((response) => response.data)
        .then((menu) => menu.grupos)
        .then((menu) => menu.map(serializer))
        .then(setMenu)
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [idestabelecimento, serializer]);

  return [menu, loading, error];
};

export default useMenu;
