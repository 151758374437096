import { Decimal } from 'decimal.js';

export const obterValorAfetaTudo = (grupoopcoes) => {
  return parseFloat(
    grupoopcoes.reduce((valorAfetaTudo, grupoopcao) => {
      const { opcoes } = grupoopcao;
      return valorAfetaTudo.add(
        opcoes.reduce((valorAfetaTudoGrupo, opcao) => {
          return valorAfetaTudoGrupo.add(
            new Decimal(opcao?.valorafetatudo ?? 0)
          );
        }, new Decimal(0))
      );
    }, new Decimal(0))
  );
};
