import {
  STPRECOPRATICADO_DESPREZA_PRECO,
  STPRECOPRATICADO_KIT,
  STPRECOPRATICADO_MAIOR_PRECO,
  STPRECOPRATICADO_MENOR_PRECO,
  STPRECOPRATICADO_PRECO_MEDIO,
  STPRECOPRATICADO_PRECO_OPCAO
} from 'services/precos/constants';

const praticarPrecoSubSubItens = (sub_subitens) => {
  return sub_subitens.reduce((total, sub_subitem) => {
    const { ajuste, preco_unit, quantidade_adicionada } = sub_subitem;
    return total + (preco_unit + ajuste) * quantidade_adicionada;
  }, 0);
};

const praticarPrecoDesprezandoSubSubItens = () => 0;

const praticarPrecoMedioSubSubItens = (sub_subitens) => {
  const quantidade = sub_subitens.reduce((total, sub_subitem) => {
    return total + sub_subitem.quantidade_adicionada;
  }, 0);
  const precoSubSubItens = praticarPrecoSubSubItens(sub_subitens);
  return Math.trunc(precoSubSubItens / quantidade);
};

const praticarPrecoUnitarioSubSubItens = (sub_subitens) => {
  const [{ preco_unit }] = sub_subitens;
  return preco_unit;
};

const praticarPrecoTotalAjustado = (sub_subitens) =>
  sub_subitens.reduce(
    (total, sub_subitem) =>
      total + sub_subitem.preco_total + sub_subitem.ajuste,
    0
  );

const obterValorSubSubItens = (stprecopraticado, sub_subitens) => {
  if (
    stprecopraticado === STPRECOPRATICADO_MENOR_PRECO ||
    stprecopraticado === STPRECOPRATICADO_MAIOR_PRECO
  ) {
    return praticarPrecoUnitarioSubSubItens(sub_subitens);
  }

  if (stprecopraticado === STPRECOPRATICADO_PRECO_MEDIO) {
    return praticarPrecoMedioSubSubItens(sub_subitens);
  }

  if (stprecopraticado === STPRECOPRATICADO_DESPREZA_PRECO) {
    return praticarPrecoDesprezandoSubSubItens();
  }

  if (stprecopraticado === STPRECOPRATICADO_KIT) {
    return praticarPrecoTotalAjustado(sub_subitens);
  }

  return praticarPrecoSubSubItens(sub_subitens);
};

const obterValorSubItens = (subitens) => {
  return subitens.reduce((total, subitem) => {
    const { stprecopraticado, sub_subitens } = subitem;
    return total + obterValorSubSubItens(stprecopraticado, sub_subitens);
  }, 0);
};

const obterValorProduto = (item) => {
  const { ajuste, preco_unit, quantidade, subitens } = item;
  return (
    (preco_unit + ajuste / quantidade + obterValorSubItens(subitens)) *
    quantidade
  );
};

export default obterValorProduto;
