import { Layout } from 'antd';
import React from 'react';

export default function AcessoFooter() {
  return (
    <Layout.Footer className="footer-login">
      EA Delivery ©2020 um Produto{' '}
      <a href="http://www.easyassist.com.br "> &nbsp; Easy@ssist</a>
    </Layout.Footer>
  );
}
