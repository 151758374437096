import { useEffect, useState } from 'react';
import { apiDelivery } from 'services/api';

const defaultSerializer = (cliente) => {
  const { senha, ...dadosClienteSemSenha } = cliente;
  return dadosClienteSemSenha;
};

const useCliente = (serializer = defaultSerializer) => {
  const [cliente, setCliente] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  function getCliente() {
    setLoading(true);
    apiDelivery
      .get('/controle/cliente/')
      .then((response) => response.data)
      .then(([clienteLogado]) => clienteLogado)
      .then(setCliente)
      .catch((err) => {
        /* eslint-disable-next-line no-console */
        console.log(err);
        setError(true);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getCliente();
  }, [serializer]);

  return [cliente, loading, error, { getCliente, setCliente }];
};

export default useCliente;
