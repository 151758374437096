import { Decimal } from 'decimal.js';

export const obterValorAjusteProduto = (preco, grupoopcoes) => {
  let valorAfetaTudo = new Decimal(0);
  const valorUnitario = new Decimal(preco);

  const valorAfetaProduto = grupoopcoes.reduce((valor, grupoopcao) => {
    const { opcoes } = grupoopcao;

    const valorAfetadoOpcoes = opcoes.reduce((valor, opcao) => {
      const { quantidade, stpercentualafetaproduto } = opcao;
      const valorafetaproduto = new Decimal(opcao?.valorafetaproduto ?? 0).mul(
        quantidade
      );

      const valorAfetadoOpcao =
        stpercentualafetaproduto === 0
          ? valorafetaproduto
          : valorUnitario.mul(valorafetaproduto.div(100));

      // aproveita o laço para somar o valorafetatudo
      valorAfetaTudo = valorAfetaTudo.add(
        new Decimal(opcao?.valorafetatudo ?? 0).mul(quantidade)
      );

      return valor.add(valorAfetadoOpcao);
    }, new Decimal(0));

    return valor.add(valorAfetadoOpcoes);
  }, new Decimal(0));

  return parseFloat(
    valorAfetaProduto
      .add(valorAfetaProduto.mul(valorAfetaTudo.div(100)))
      .add(valorUnitario.mul(valorAfetaTudo.div(100)))
  );
};
