import {
  STTIPOPGTO_EAPAY_CREDITO,
  STTIPOPGTO_EAPAY_DEBITO,
  STTIPOPGTO_PIX,
  VALOR_TIPO_CREDITO,
  VALOR_TIPO_DEBITO
} from './constants';

const encontrarCartaoPeloToken = (token) => (cartao) => cartao?.token === token;

const encontrarFormaPagamentoPeloId = (id) => (formaPagamento) =>
  formaPagamento?.idformapgto === id;

const encontrarFormaPagamentoPeloSttipopgto = (sttipopgto) => (
  formaPagamento
) => formaPagamento.sttipopgto === sttipopgto;

export const adicionarDadosCartaoSalvo = (cartoesSalvos, dadosPagamento) => {
  const { cartao } = dadosPagamento;

  if (!cartao?.token) {
    return cartao;
  }

  const cartaoSalvoSelecionado = cartoesSalvos.find(
    encontrarCartaoPeloToken(cartao?.token)
  );
  const { bandeira, cnpjoucpf, titular } = cartaoSalvoSelecionado;

  return { ...cartao, ...{ bandeira, cnpjoucpf, titular } };
};

export const obterDescricaoESttipopgoPeloIdFormaPagamento = (
  formasPagamento,
  formapgto_id
) => {
  const formaPagamentoSelecionada = formasPagamento.find(
    encontrarFormaPagamentoPeloId(formapgto_id)
  );
  const { descricao, sttipopgto } = formaPagamentoSelecionada;
  return { descricao, sttipopgto };
};

export const obterDescricaoSttipopgoEIdFormaPagamentoPeloTipoCartao = (
  formasPagamento,
  tipoCartao
) => {
  if (tipoCartao === VALOR_TIPO_CREDITO) {
    const formaPagamentoSelecionada = formasPagamento.find(
      encontrarFormaPagamentoPeloSttipopgto(STTIPOPGTO_EAPAY_CREDITO)
    );
    const { descricao, idformapgto, sttipopgto } = formaPagamentoSelecionada;
    return { descricao, idformapgto, sttipopgto };
  }

  if (tipoCartao === VALOR_TIPO_DEBITO) {
    const formaPagamentoSelecionada = formasPagamento.find(
      encontrarFormaPagamentoPeloSttipopgto(STTIPOPGTO_EAPAY_DEBITO)
    );
    const { descricao, idformapgto, sttipopgto } = formaPagamentoSelecionada;
    return { descricao, idformapgto, sttipopgto };
  }

  throw new Error(
    `O tipo de cartão ${tipoCartao} não é conhecido ou suportado.`
  );
};

export const obterDescricaoEIdFormaPgtoPix = (formasPagamento) => {
  return formasPagamento.find((formaPagamento) => {
    return formaPagamento.sttipopgto === STTIPOPGTO_PIX;
  });
};
