import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './AguardandoPgto.css';

const antIcon = <LoadingOutlined style={{ fontSize: 120 }} spin />;

const AguardandoPgto = () => {
    
    useEffect(() => {
        document.body.style = 'background: white;';
        window.parent.postMessage({ urlCallbackCarregada: true }, "*");
    }, [])

    return (
        <div className="loading">
            <span>Aguarde...</span>
            <Spin indicator={antIcon} />
        </div>
    )
}

export default AguardandoPgto;