import axios from 'axios';
import { message } from 'antd';
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN } from 'contexts/AuthContext';
import { KEY_ENDERECO } from 'contexts/EnderecoContext';
import { KEY_FILTRO_ESTAB } from 'contexts/FiltroEstabelecimentoContext';
import { clearAvatar } from 'components/Common/Usuario/Avatar';

export function limparLocalStorageLogout() {
  localStorage.removeItem(KEY_ACCESS_TOKEN);
  localStorage.removeItem(KEY_REFRESH_TOKEN);
  localStorage.removeItem(KEY_ENDERECO);
  localStorage.removeItem(KEY_FILTRO_ESTAB);
  clearAvatar();
}

export const apiRetaguarda = axios.create({
  baseURL: '/api/ear'
});

export const apiDelivery = axios.create({
  baseURL: '/api/ead',
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

apiDelivery.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem(KEY_ACCESS_TOKEN)
      ? `Bearer ${localStorage.getItem(KEY_ACCESS_TOKEN)}`
      : null;

    return config;
  },
  function error(_error) {
    return Promise.reject(_error);
  }
);

apiDelivery.interceptors.response.use(
  (response) => response,

  async (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url === '/controle/token/refresh/'
    ) {
      window.location = '/logout';
      limparLocalStorageLogout();
      message.info(
        'Sua sessão expirou, para continuar é preciso fazer login navamente.'
      );
      return Promise.reject(error);
    }

    const originalRequest = error.config;

    if (
      localStorage.getItem(KEY_REFRESH_TOKEN) &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      const refresh_token = localStorage.getItem(KEY_REFRESH_TOKEN);

      try {
        const response = await apiDelivery.post('/controle/token/refresh/', {
          refresh: refresh_token
        });

        localStorage.setItem(KEY_ACCESS_TOKEN, response.data.access);

        apiDelivery.defaults.headers.Authorization = `Bearer ${response.data.access}`;
        originalRequest.headers.Authorization = `Bearer ${response.data.access}`;

        return apiDelivery(originalRequest);
      } catch (err) {
        console.error(err);
        window.location('/logout');
      }
    }

    return Promise.reject(error);
  }
);
