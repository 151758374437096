import moment from 'moment';

const StatusPedido = {
  Realizado: 0,
  PgtoPendente: 10,
  PgtoConfirmado: 11,
  Sincronizado: 20,
  Confirmado: 21,
  Pronto: 30,
  // SaiuParaEntrega: 40,
  Entregue: 41,
  Cancelado: 50,
  CancelamentoSincronizado: 51,
  Concluido: 100
};

export function statusConcluido(historico, tipo) {
  return historico.status === StatusPedido.Concluido;
}

export function pedidosPendentes(pedidos) {
  if (!Array.isArray(pedidos)) return [];

  return pedidos.filter((pedido) => {
    const { historico } = pedido;
    if (historico.some((hst) => statusConcluido(hst, pedido.pedido.tipo))) {
      return false;
    }
    return true;
  });
}

export function ordernarPorDataHoraDec(a, b) {
  try {
    const am = moment(a.datahora);
    const bm = moment(b.datahora);
    if (am > bm) return 1;
    if (am < bm) return -1;
    if (am === bm) return 0;
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.log(err);
  }
  return -1;
}

export default StatusPedido;
