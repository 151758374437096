import { Decimal } from 'decimal.js';

import { obterValor } from './obterValor';

export const obterValorAjusteOpcao = (opcao, valorafetatudo = 0) => {
  const valorAfetaOpcao = new Decimal(opcao?.valorafetaopcao ?? 0);
  const valorAfetaTudo = new Decimal(valorafetatudo);
  let valorAjuste = new Decimal(0);
  const valorUnitario = new Decimal(obterValor(opcao));

  const valorUnitarioAfetado =
    opcao.stpercentualafetaopcao === 0
      ? valorAfetaOpcao
      : valorUnitario.mul(valorAfetaOpcao.div(100));

  valorAjuste = valorUnitarioAfetado.add(
    valorUnitarioAfetado.mul(valorAfetaTudo.div(100))
  );
  valorAjuste = valorAjuste.add(valorUnitario.mul(valorAfetaTudo.div(100)));

  return parseFloat(valorAjuste);
};
