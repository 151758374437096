import { useEffect, useState } from 'react';

import { apiDelivery } from 'services/api';

const useAreasEntrega = (idEstabelecimento) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (idEstabelecimento) {
      setLoading(true);

      apiDelivery
        .get(`/api/estabelecimento/${idEstabelecimento}/areas_entrega/`)
        .then((response) => response.data)
        .then((areasEntrega) =>
          areasEntrega.map((areaEntrega) => {
            const {
              idareaentrega_str,
              idestabelecimento_str,
              ...propriedades
            } = areaEntrega;

            return {
              ...propriedades,
              idareaentrega: idareaentrega_str,
              idestabelecimento: idestabelecimento_str
            };
          })
        )
        .then(setData)
        .catch((error) => {
          console.log(error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [idEstabelecimento]);

  return [data, loading, error];
};

export default useAreasEntrega;
