import { Badge, Divider, Layout } from 'antd';
import {FormOutlined, HomeOutlined } from '@ant-design/icons';
import React from 'react';

import ContentCarrinho from 'components/Delivery/Layout/Content/Carrinho';
import { Link } from 'react-router-dom';

import { useAuthContext } from 'contexts/AuthContext';

import UsuarioAvatar from 'components/Common/Usuario/Avatar';

export default function DeliveryFooter({ pedidosPendentes }) {
  const { auth } = useAuthContext();

  return (
    <Layout.Footer className="footer">
      {/* <Link to="/">
        <div className="ripple">
          <HomeOutlined
            style={{
              fontSize: '25px',
              color: '#ccc'
            }}
          />
        </div>
      </Link>

      <Divider type="vertical" /> */}

      <ContentCarrinho />

      <Divider type="vertical" />

      {auth.isLoggedIn && (
        <>
          <Link to="/pedido/">
            <div className="ripple">
              <Badge count={pedidosPendentes.length} key={1}>
                <FormOutlined
                  style={{
                    fontSize: '25px',
                    color: '#ccc'
                  }}
                />
              </Badge>
            </div>
          </Link>

          <Divider type="vertical" />
        </>
      )}

      <UsuarioAvatar />
    </Layout.Footer>
  );
}
