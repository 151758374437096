import { useEffect, useState } from 'react';
import moment from 'moment';

import { apiDelivery } from 'services/api';

const defaultSerializer = (cartao) => {
  const { datahora, numero, ...props } = cartao;
  return {
    ...props,
    datahora: moment(datahora).format('DD/MM/YYYY HH:mm:ss'),
    numero: '*'.repeat(12).concat(numero.slice(-4))
  };
};

const useCartoes = (serializer = defaultSerializer) => {
  const [cartoes, setCartoes] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCartoes = () => {
    setLoading(true);
    apiDelivery
      .get('/controle/cartao/')
      .then((response) => response.data)
      .then((cartoes_data) => cartoes_data.map(defaultSerializer))
      .then(setCartoes)
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCartoes();
  }, []);

  return [cartoes, loading, error, { getCartoes }];
};

export default useCartoes;
