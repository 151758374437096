import React from 'react';
import { Typography } from 'antd';

const propTypes = {};

const defaultProps = {};

const { Paragraph, Text } = Typography;

const PedidoProduto = ({ produto }) => {
  if (!Array.isArray(produto.subitens)) {
    return null;
  }
  return (
    <Paragraph>
      {produto.subitens.map((subitem) => {
        const {
          descricao: descricaoGrupoOpcao,
          stprecopraticado,
          sub_subitens
        } = subitem;

        const quantidadeOpcoes =
          stprecopraticado >= 1 && stprecopraticado <= 3
            ? sub_subitens.reduce(
                (total, sub_subitem) =>
                  total + sub_subitem.quantidade_adicionada,
                0
              )
            : null;
        return (
          <div key={subitem.grupoopcao_id} style={{ marginLeft: 8 }}>
            <Text>{descricaoGrupoOpcao}</Text>
            <br />
            {sub_subitens
              .filter((sub_subitem) => sub_subitem.quantidade)
              .map((sub_subitem) => {
                const {
                  descricao,
                  opcao_id,
                  quantidade,
                  quantidade_adicionada
                } = sub_subitem;
                return (
                  <React.Fragment key={opcao_id}>
                    <Text
                      style={{ marginLeft: 8 }}
                      type="secondary"
                    >
                      {quantidadeOpcoes
                        ? `(${quantidade_adicionada}/${quantidadeOpcoes})`
                        : quantidade}
                      x {descricao}
                    </Text>
                    <br />
                  </React.Fragment>
                );
              })}
          </div>
        );
      })}
    </Paragraph>
  );
};

PedidoProduto.propTypes = propTypes;
PedidoProduto.defaultProps = defaultProps;

export default PedidoProduto;
