import React from 'react';

import { Redirect, Route, useLocation } from 'react-router-dom';
import { useAuthContext, setRedirecionar } from 'contexts/AuthContext';

const PrivateRoute = ({ children, ...props }) => {
  const { auth, authDispatcher } = useAuthContext();
  const location = useLocation();

  if (auth.isLoggedIn === false) {
    authDispatcher(setRedirecionar(location.pathname));
    return <Redirect to='/login'/>;
  }

  return <Route {...props}>{children}</Route>;
};

export default PrivateRoute;
