import { useState, useEffect } from 'react';

import { apiDelivery } from 'services/api';

export default function usePedidos() {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getPedidos = async () => {
    setLoading(true);
    try {
      const { data } = await apiDelivery.get(`/controle/pedido/?pendentes=true`);

      const pedidos_json_ajustado = data.map(dadosPedido => {
        const pedido_json = JSON.parse(
          dadosPedido.pedido
            .replace(/'/g, '"')
            .replace(/None/g, 'null')
            .replace(/False/g, false)
            .replace(/True/g, true)
        );
        return {
          ...dadosPedido,
          pedido: pedido_json,
          pedido_json
        };
      });

      setPedidos(pedidos_json_ajustado);
    } catch (err) {
      console.error(err);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPedidos();
  }, []);

  return [pedidos, loading, error, getPedidos];
}
