import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import PedidoAcompanhe from './Acompanhe';
import PedidoLista from './Lista';

export default function PedidoRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:id`} >
        <PedidoAcompanhe />
      </Route>
      <Route>
        <PedidoLista />
      </Route>
    </Switch>
  )
}