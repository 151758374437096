import { Decimal } from 'decimal.js';

import { obterValor } from './obterValor';
import { obterValorAjusteOpcao } from './obterValorAjusteOpcao';

export const obterValorOpcao = (opcao, valorafetatudo = 0) => {
  const preco = new Decimal(obterValor(opcao));
  const valorAjuste = obterValorAjusteOpcao(opcao, valorafetatudo);
  return parseFloat(preco.add(valorAjuste));
};
