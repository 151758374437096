import { useEffect, useState } from 'react';
import { apiDelivery } from 'services/api';

const useEstabelecimento = (idEstabelecimento) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ddEstabelecimento, setEstabelecimento] = useState(null);

  useEffect(() => {
    if (idEstabelecimento) {
      setLoading(true);
      apiDelivery
        .get(`/controle/estabelecimento/${idEstabelecimento}/`)
        .then((estabelecimento) => estabelecimento.data)
        .then((dadosEstabelecimento) => {
          const {
            estabelecimento,
            horariofuncionamento_set,
            lojaaberta,
            id,
            previsaoentregamax,
            previsaoentregamin,
            logo,
            pedidominimo,
            taxaentrega_set
          } = dadosEstabelecimento;
          const {
            apelidooufantasia,
            endereco_set,
            nomeourazaosocial,
            imgpessoa
          } = estabelecimento;
          let endereco = {};
          try {
            const [
              { bairro, cep, cidade, complemento, numero, rua, uf }
            ] = endereco_set;
            endereco = {
              bairro,
              cep,
              cidade,
              complemento,
              numero,
              rua,
              uf
            };
          } catch (e) {
            console.log(e);
          }

          return {
            id,
            apelidooufantasia,
            endereco,
            horariofuncionamento_set,
            lojaaberta,
            nomeourazaosocial,
            previsaoentregamax,
            previsaoentregamin,
            taxasentrega: taxaentrega_set,
            imgpessoa,
            logo,
            pedidominimo
          };
        })
        .then(setEstabelecimento)
        .catch((e) => {
          console.log(e);
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [idEstabelecimento]);

  return [ddEstabelecimento, loading, error];
};

export default useEstabelecimento;
