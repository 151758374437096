import React from 'react';
import { Divider, List } from 'antd';
import { obterValorMonetario } from 'services/precos';

import obterValorProduto from './obterValorProduto';

import PedidoProduto from '../Produto';

const exibirMonetario = (valor) => obterValorMonetario(valor / 100);

export default function AcompanheDetalhe({ pedido, data_pedido = '' }) {
  return (
    <>
      <Divider />
      <h3>PEDIDO #{pedido.numero_pedido}</h3>
      <p className="p-valor">{data_pedido}</p>
      <Divider />
      <h3>RESUMO</h3>
      <List
        dataSource={pedido.itens}
        rowKey={(record) => `${record.produto_id}${record.quantidade}`}
        renderItem={(item) => {
          return (
            <List.Item>
              <div className="detalhe-container">
                <div className="detalhe-left">
                  {item.quantidade}x {item.descricao}
                  <PedidoProduto produto={item} />
                </div>
                <div className="detalhe-right">
                  {exibirMonetario(obterValorProduto(item))}
                </div>
              </div>
            </List.Item>
          );
        }}
      />
      <Divider />
      <h3>FORMA DE PAGAMENTO</h3>
      {pedido.pagamentos &&
        pedido.pagamentos.map((pagamento) => (
          <p key={pagamento.descricaoFormaPgto}>
            {pagamento.descricaoFormaPgto}
          </p>
        ))}
      <Divider />
      <h3>CONTA</h3>
      <div className="detalhe-container">
        <div className="detalhe-left">Produtos</div>
        <div className="detalhe-right">{exibirMonetario(pedido.subtotal)}</div>
      </div>
      <div className="detalhe-container">
        <div className="detalhe-left">Taxa de entrega</div>
        <div className="detalhe-right">
          {exibirMonetario(pedido.taxa_entrega)}
        </div>
      </div>
      {pedido.desconto > 0 && (
        <div className="detalhe-container">
          <div className="detalhe-left">Desconto</div>
          <div className="detalhe-right">
            -{exibirMonetario(pedido.desconto)}
          </div>
        </div>
      )}
      <div className="detalhe-container">
        <div className="detalhe-left">
          <b>Total</b>
        </div>
        <div className="detalhe-right">{exibirMonetario(pedido.total)}</div>
      </div>
      <Divider />
    </>
  );
}
