import React from 'react';
import { Tag } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { converteMonetario } from 'utils/normalize';

const propTypes = {
  total: PropTypes.number.isRequired
};

const defaultProps = {};

const PedidoCardPreco = ({ total }) => <Tag>{converteMonetario(total)}</Tag>;

PedidoCardPreco.propTypes = propTypes;
PedidoCardPreco.defaultProps = defaultProps;

export default PedidoCardPreco;
