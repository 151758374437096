import {
  VALOR_BANDEIRA_AMEX,
  VALOR_BANDEIRA_DINERS,
  VALOR_BANDEIRA_ELO,
  VALOR_BANDEIRA_HIPERCARD,
  VALOR_BANDEIRA_MASTER,
  VALOR_BANDEIRA_VISA
} from '../../../constants';

const cartoesAceitos = [
  {
    bandeira: VALOR_BANDEIRA_AMEX,
    padraoNumero: /^3[47][0-9]{13}/
  },
  {
    bandeira: VALOR_BANDEIRA_DINERS,
    padraoNumero: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/
  },
  {
    bandeira: VALOR_BANDEIRA_ELO,
    padraoNumero: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/
  },
  {
    bandeira: VALOR_BANDEIRA_HIPERCARD,
    padraoNumero: /^(606282\d{10}(\d{3})?)|(3841\d{15})/
  },
  {
    bandeira: VALOR_BANDEIRA_MASTER,
    padraoNumero: /^5[1-5][0-9]{14}/
  },
  {
    bandeira: VALOR_BANDEIRA_VISA,
    padraoNumero: /^4[0-9]{12}(?:[0-9]{3})/
  }
];

/* TODO: checar esse nome */
const encontrarCartaoCorrespondentePeloNumero = (numeroCartao) => (
  cartaoAceito
) => cartaoAceito.padraoNumero.test(numeroCartao);

const obterBandeiraPeloPadraoNumero = (numeroCartao) => {
  const cartaoEncontrado = cartoesAceitos.find(
    encontrarCartaoCorrespondentePeloNumero(numeroCartao)
  );
  return cartaoEncontrado?.bandeira;
};

export default obterBandeiraPeloPadraoNumero;
