import React from 'react';
import { Result } from 'antd';

const FacebookRemoveContentFeedback = () => (
  <Result
    status="success"
    subTitle="Os seus dados de acesso com o Facebook foram removidos."
    title="Sucesso!"
  />
);

export default FacebookRemoveContentFeedback;
