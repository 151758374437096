import React from 'react';
import { Typography } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { obterValorMonetario } from 'services/precos';

import PrecoOfertaProduto from './PrecoOfertaProduto';

const { Text } = Typography;

const propTypes = {
  preco: PropTypes.number,
  precooferta: PropTypes.number,
  prefixoPreco: PropTypes.string
};

const defaultProps = {
  preco: null,
  precooferta: null,
  prefixoPreco: ''
};

const PrecoProduto = ({ preco, precooferta, prefixoPreco }) => {
  if (precooferta > 0) {
    return (
      <PrecoOfertaProduto
        preco={preco}
        precooferta={precooferta}
        prefixoPreco={prefixoPreco}
      />
    );
  }

  if (preco > 0) {
    return (
      <Text>
        {prefixoPreco}
        {obterValorMonetario(preco)}
      </Text>
    );
  }

  return null;
};

PrecoProduto.propTypes = propTypes;
PrecoProduto.defaultProps = defaultProps;

export default PrecoProduto;
