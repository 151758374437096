import { multiplicar_decimal } from 'utils/decimal';

export const obterValorTotal = (subTotal, taxaEntrega, desconto) => {
  const subtotal = multiplicar_decimal(subTotal, 100);
  const descontoCentavos = multiplicar_decimal(desconto, 100);
  const taxa_entrega =
    taxaEntrega > 0 ? multiplicar_decimal(taxaEntrega, 100) : 0;
  const total = (subtotal + taxa_entrega - descontoCentavos) / 100;
  return total;
};
