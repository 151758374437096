import AMEX_IMG from 'assets/cartoes/amex.png'
import DINERS_IMG from 'assets/cartoes/diners.png'
import DISCOVER_IMG from 'assets/cartoes/discover.png'
import ELO_IMG from 'assets/cartoes/elo.png'
import HIPERCARD_IMG from 'assets/cartoes/hipercard.png'
import MASTERCARD_IMG from 'assets/cartoes/mastercard.png'
import VISA_IMG from 'assets/cartoes/visa.png'

export const CARTOES = [
  {
    label: 'AMEX',
    img: AMEX_IMG,
    pattern: /^3[47][0-9]{13}/,
    value: 'Amex'
  },
  {
    label: 'Diners',
    img: DINERS_IMG,
    pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    value: 'Diners'
  },
  {
    label: 'Discover',
    img: DISCOVER_IMG,
    pattern: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    value: 'Discover'
  },
  {
    label: 'ELO',
    img: ELO_IMG,
    pattern: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    value: 'Elo'
  },
  {
    label: 'Hipercard',
    img: HIPERCARD_IMG,
    pattern: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    value: 'Hipercard'
  },
  {
    label: 'Mastercard',
    img: MASTERCARD_IMG,
    pattern: /^5[1-5][0-9]{14}/,
    value: 'Master'
  },
  {
    label: 'VISA',
    img: VISA_IMG,
    pattern: /^4[0-9]{12}(?:[0-9]{3})/,
    value: 'Visa'
  }
];

export const gerarAnosDeValidade = () =>
  Array.from({ length: 21 }, (_, i) => 2020 + i);

export const obterBandeira = (numero) => {
  const cartao = CARTOES.find(({ pattern }) => pattern.test(numero));
  return cartao ? cartao.value : null;
};

export const obterLabelBandeira = (bandeira) => {
  const cartao = CARTOES.find(cartao => cartao.value === bandeira);
  return cartao ? cartao.label : null
};

export const obterImagem = (bandeira) => {
  const cartao = CARTOES.find(cartao => cartao.value === bandeira);
  return cartao ? cartao.img : null
};
