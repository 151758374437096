import React, { useState } from 'react';
import { Form, Input } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { CardNumberBPMPI } from 'utils/InputBPMPI';

import obterBandeiraPeloPadraoNumero from './utils';

const propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  onPaste: PropTypes.func
};

const defaultProps = {
  onPaste: null
};

const NumeroCartaoField = ({ form, onPaste }) => {
  const [cardNumber, setCardNumber] = useState('');

  const autoSelecionarBandeiraPeloPadraoNumero = (event) => {
    const numeroCartao = event.target.value;
    setCardNumber(numeroCartao);
    form.setFieldsValue({
      cartao: {
        bandeira: obterBandeiraPeloPadraoNumero(numeroCartao)
      }
    });
  };

  return (
    <>
      <Form.Item
        label="Número do cartão"
        name={['cartao', 'numero']}
        rules={[
          {
            message: 'Campo obrigatório.',
            required: true
          },
          {
            message: 'Digite um número de 14 à 16 dígitos.',
            pattern: /^\d{14,16}$/
          }
        ]}
      >
        <Input
          maxLength={16}
          onChange={autoSelecionarBandeiraPeloPadraoNumero}
          inputmode="numeric"
          pattern="[0-9]*"
          // onPaste={onPaste}
        />
      </Form.Item>
      <CardNumberBPMPI value={cardNumber} />
    </>
  );
};

NumeroCartaoField.propTypes = propTypes;
NumeroCartaoField.defaultProps = defaultProps;

export default NumeroCartaoField;
