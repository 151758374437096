export const EVENTOS = {
  onSuccess: 'onSuccess',
  onReady: 'onReady',
  onFailure: 'onFailure',
  onUnenrolled: 'onUnenrolled',
  onDisabled: 'onDisabled',
  onError: 'onError',
  onUnsupportedBrand: 'onUnsupportedBrand'
};

const BpmpiConfigScript = `
function bpmpi_config() {
  function sendPostMessage(eventName, data) {
    const event = document.createEvent('CustomEvent');
    const newData = {
      ...data,
      eventName
    }
    event.initCustomEvent("bpmpi", true, true, newData);
    document.documentElement.dispatchEvent(event);
  }

  return {
    onReady: function () {
      // Evento indicando quando a inicialização do script terminou.
      console.log('onReady');
      sendPostMessage('${EVENTOS.onReady}', {});
      // document.getElementById('btnSendOrder').disabled = false;
    },
    onSuccess: function (e) {
      // Cartão elegível para autenticação, e portador autenticou com sucesso.
      sendPostMessage('${EVENTOS.onSuccess}', e);
      var cavv = e.Cavv;
      var xid = e.Xid;
      var eci = e.Eci;
      var version = e.Version;
      var referenceId = e.ReferenceId;
    },
    onFailure: function (e) {
      // Cartão elegível para autenticação, porém o portador finalizou com falha.
      sendPostMessage('${EVENTOS.onFailure}', e);
      var xid = e.Xid;
      var eci = e.Eci;
      var version = e.Version;
      var referenceId = e.ReferenceId;
    },
    onUnenrolled: function (e) {
      // Cartão não elegível para autenticação (não autenticável).
      sendPostMessage('${EVENTOS.onUnenrolled}', e);
      var xid = e.Xid;
      var eci = e.Eci;
      var version = e.Version;
      var referenceId = e.ReferenceId;
    },
    onDisabled: function () {
      // Loja não requer autenticação do portador (classe "bpmpi_auth" false -> autenticação desabilitada).
    },
    onError: function (e) {
      // Erro no processo de autenticação.
      console.log(' Erro no processo de autenticação');
      sendPostMessage('${EVENTOS.onError}', e);
      var xid = e.Xid;
      var eci = e.Eci;
      var returnCode = e.ReturnCode;
      var returnMessage = e.ReturnMessage;
      var referenceId = e.ReferenceId;
    },
    onUnsupportedBrand: function (e) {
      // Bandeira não suportada para autenticação.
      sendPostMessage('${EVENTOS.onUnsupportedBrand}', e);
      var returnCode = e.ReturnCode;
      var returnMessage = e.ReturnMessage;
    },
    Environment: '${process.env.REACT_APP_3DS_ENV}',
    Debug: true
  };
}
`;

export default BpmpiConfigScript;
