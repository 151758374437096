import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Result } from 'antd';

import ContentCardapio from 'components/Delivery/Layout/Content/Cardapio';
import ContentCliente from 'components/Delivery/Layout/Content/Cliente';
import ContentFinalizarPedido from 'components/Delivery/Layout/Content/FinalizarPedido';
import FacebookRemoveContentFeedback from 'components/Delivery/Layout/FacebookRemoveContentFeedback';
import PedidoRouter from 'components/Delivery/Layout/Content/Pedido';

import PrivateRoute from './PrivateRoute';

const DeliveryRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={`${path}cliente`}>
        <ContentCliente />
      </PrivateRoute>
      <PrivateRoute path={`${path}pagamento`}>
        <ContentFinalizarPedido />
      </PrivateRoute>
      <PrivateRoute path={`${path}pedido`}>
        <PedidoRouter />
      </PrivateRoute>
      <Route path={`${path}estabelecimento/:id`}>
        <ContentCardapio />
      </Route>
      <Route path={`${path}fb_confirmar_remocao`}>
        <FacebookRemoveContentFeedback />
      </Route>
    </Switch>
  );
};

export default DeliveryRouter;
