import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

import useLocalStorage from 'hooks/useLocalStorage';

const INITIAL_STATE = {
  estabelecimento: null,
  itens: [],
  pedidoid: null,
  browserfingerprint: null,
  taxaentrega: null,
  cupom: null
};

export const CarrinhoContext = createContext();

export const KEY_CARRINHO = 'carrinho';

export const ADICIONAR_ITEM = 'ADICIONAR_ITEM';

export const EDITAR_ITEM = 'EDITAR_ITEM';

export const INICIALIZAR_CARRINHO = 'INICIALIZAR_CARRINHO';

export const LIMPAR_CARRINHO = 'LIMPAR_CARRINHO';

export const REMOVER_ITEM = 'REMOVER_ITEM';

export const SET_TAXA_ENTREGA = 'SET_TAXA_ENTREGA';

export const SET_ABONO_TAXA_ENTREGA = 'SET_ABONO_TAXA_ENTREGA';

export const SET_DESCONTO = 'SET_DESCONTO';

export const SET_CUPOM_DESCONTO = 'SET_CUPOM_DESCONTO';

export const SET_BROWSERFINGERPRINT = 'SET_BROWSERFINGERPRINT';

export const REINICIAR_PEDIDO_ID = 'REINICIAR_PEDIDO_ID';

export const adicionarItem = (item) => ({ type: ADICIONAR_ITEM, item });

export const editarItem = (item, indice) => ({
  type: EDITAR_ITEM,
  item,
  indice
});

export const inicializarCarrinho = (estabelecimento, item) => ({
  type: INICIALIZAR_CARRINHO,
  estabelecimento,
  item
});

export const setBrowserFingerprint = (
  browserfingerprint = Math.floor(Math.random() * 900000) + 100000
) => ({
  type: SET_BROWSERFINGERPRINT,
  browserfingerprint
});

export const limparCarrinho = () => ({ type: LIMPAR_CARRINHO });

export const removerItem = (indice) => ({ type: REMOVER_ITEM, indice });

export const setTaxaEntrega = (taxaentrega) => ({
  type: SET_TAXA_ENTREGA,
  taxaentrega
});
export const setAbonoTaxaEntrega = (abono) => ({
  type: SET_ABONO_TAXA_ENTREGA,
  abono
});

export const setCupomDesconto = (cupom) => ({
  type: SET_CUPOM_DESCONTO,
  cupom
});

export const reiniciarPedidoId = () => ({ type: REINICIAR_PEDIDO_ID });

const removerQuantidadePadrao = (produto) => {
  const { grupoopcoes, ...propriedadesProduto } = produto;
  return {
    ...propriedadesProduto,
    grupoopcoes: grupoopcoes.map((grupoopcao) => {
      const { opcoes, ...propriedadesGrupoopcao } = grupoopcao;
      return {
        ...propriedadesGrupoopcao,
        opcoes: opcoes.map((opcao) => {
          const { qtdpadrao, ...propriedadesOpcao } = opcao;
          return { ...propriedadesOpcao };
        })
      };
    })
  };
};

export const carrinhoReducer = (state, action) => {
  switch (action.type) {
    case ADICIONAR_ITEM:
      return {
        ...state,
        itens: [...state.itens, removerQuantidadePadrao(action.item)]
      };
    case EDITAR_ITEM:
      return {
        ...state,
        itens: state.itens.map((item, indice) => {
          if (indice === action.indice) {
            return action.item;
          }
          return item;
        })
      };
    case INICIALIZAR_CARRINHO:
      return {
        estabelecimento: action.estabelecimento,
        itens: [removerQuantidadePadrao(action.item)],
        pedidoid: uuidv4(),
        browserfingerprint: Math.floor(Math.random() * 900000) + 100000,
        taxaentrega: null
      };
    case LIMPAR_CARRINHO:
      return INITIAL_STATE;
    case REMOVER_ITEM: {
      const itens = state.itens.filter(
        (item, indice) => indice !== action.indice
      );
      if (itens.length === 0) {
        return INITIAL_STATE;
      }
      return {
        ...state,
        itens
      };
    }

    case SET_TAXA_ENTREGA:
      return {
        ...state,
        taxaentrega: action.taxaentrega
      };

    case SET_ABONO_TAXA_ENTREGA:
      return {
        ...state,
        abono: action.abono
      };

    case SET_BROWSERFINGERPRINT:
      return {
        ...state,
        browserfingerprint: action.browserfingerprint
      };

    case SET_CUPOM_DESCONTO:
      return {
        ...state,
        cupom: action.cupom
      };

    case REINICIAR_PEDIDO_ID:
      return {
        ...state,
        pedidoid: uuidv4()
      };

    default:
      return state;
  }
};

const CarrinhoProvider = (props) => {
  const [carrinhoSalvo, saveCarrinho] = useLocalStorage(
    KEY_CARRINHO,
    INITIAL_STATE
  );
  const [carrinho, carrinhoDispatcher] = useReducer(
    carrinhoReducer,
    carrinhoSalvo
  );
  useEffect(() => {
    saveCarrinho(carrinho);
  }, [carrinho]);
  return (
    <CarrinhoContext.Provider
      value={{ carrinho, carrinhoDispatcher }}
      {...props}
    />
  );
};

const useCarrinhoContext = () => useContext(CarrinhoContext);

export { CarrinhoProvider, useCarrinhoContext };
