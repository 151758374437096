import React from 'react';
import { Result } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const propTypes = {
  message: PropTypes.string.isRequired
};

const defaultProps = {};

const PedidosRequestError = ({ message }) => (
  <Result
    subTitle="Tente novamente após recarregar a página."
    title={message}
  />
);

PedidosRequestError.propTypes = propTypes;
PedidosRequestError.defaultProps = defaultProps;

export default PedidosRequestError;
