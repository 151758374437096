import React from 'react';
import { Timeline } from 'antd';
import {
  ClockCircleOutlined,
  CheckCircleFilled,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import StatusPedido from 'utils/Pedido';

export default function AcompanheTimeline({ dadosPedido }) {
  const realizado = dadosPedido.historico.find(
    (historico) => historico.status === StatusPedido.Realizado
  );
  const confirmado = dadosPedido.historico.find(
    (historico) => historico.status === StatusPedido.Confirmado
  );
  const pronto = dadosPedido.historico.find(
    (historico) => historico.status === StatusPedido.Pronto
  );
  const entregue = dadosPedido.historico.find(
    (historico) => historico.status === StatusPedido.Entregue
  );
  const cancelado = dadosPedido.historico.find(
    (historico) => historico.status === StatusPedido.Cancelado
  );

  function formatarPrevisaoEntrega(previsao) {
    const h = (previsao / 60) | 0;
    const m = previsao % 60 | 0;
    if (h > 0) {
      return `${h}h${m > 0 ? m : ''}`;
    }
    return `${m}m`;
  }

  function PrevisaoEntrega(entregaMin, entregaMax) {
    if (!entregaMax && !entregaMin)
      return null;

    const entregaMaxFormatada = formatarPrevisaoEntrega(entregaMax); 
    if (entregaMax === entregaMin) {
      return `${entregaMaxFormatada}`;
    }

    const entregaMinFormatada = formatarPrevisaoEntrega(entregaMin); 
    return `${entregaMinFormatada} à ${entregaMaxFormatada}`
  }

  const { pedido } = dadosPedido
  const { previsaoentregamax, previsaoentregamin } = (
    pedido || { previsaoentregamax: null, previsaoentregamin: null }
  )

  return (
    <Timeline>
      {dadosPedido.pedido.previsaoentregamin &&
        dadosPedido.pedido.previsaoentregamax && (
          <Timeline.Item
            color="blue"
            dot={<ClockCircleOutlined style={{ fontSize: '22px' }} />}
          >
            {dadosPedido.pedido.entregue_as === null ? (
              <>
                <p className="p-status">
                  {PrevisaoEntrega(previsaoentregamin, previsaoentregamax)}
                </p>
              </>
            ) : (
              <>
                <p className="p-status">Pedido agendado para: </p>
                <p className="p-valor">
                  {' '}
                  {`${moment(dadosPedido.pedido.entregue_as).format('LLLL')} `}
                </p>
              </>
            )}
          </Timeline.Item>
        )}
      <Timeline.Item
        dot={
          realizado ? <CheckCircleFilled style={{ fontSize: '22px' }} /> : null
        }
      >
        <p className="p-status">Realizado</p>
        <p className="p-valor">
          {realizado ? moment(realizado.datahora).format('LLLL') : null}
        </p>
        {realizado && !confirmado && (
          <p className="p-valor">
            Estamos esperando o restaurante confirmar seu pedido.
          </p>
        )}
      </Timeline.Item>
      {cancelado ? (
        <Timeline.Item
          dot={<ExclamationCircleOutlined style={{ fontSize: '22px' }} />}
        >
          <p className="p-status">Pedido Cancelado</p>
          <p className="p-valor">
            Seu pedido foi cancelado, se você pagou online, não se preocupe, o
            estorno é automático
          </p>
        </Timeline.Item>
      ) : (
        <>
          <Timeline.Item
            dot={
              confirmado ? (
                <CheckCircleFilled style={{ fontSize: '22px' }} />
              ) : null
            }
          >
            <p className="p-status">Confirmado</p>
            <p className="p-valor">
              {confirmado ? moment(confirmado.datahora).format('LLLL') : null}
            </p>
            {confirmado &&
              !pronto &&
              (dadosPedido.pedido.entregue_as === null ? (
                <p className="p-valor">
                  O restaurante está preparando seu pedido.
                </p>
              ) : (
                <p className="p-valor">O restaurante já recebeu seu pedido.</p>
              ))}
          </Timeline.Item>
          <Timeline.Item
            dot={
              pronto ? <CheckCircleFilled style={{ fontSize: '22px' }} /> : null
            }
          >
            <p className="p-status">Pronto</p>
            <p className="p-valor">
              {pronto ? moment(pronto.datahora).format('LLLL') : null}
            </p>
            {pronto && !entregue && (
              <p className="p-valor">
                O seu pedido está pronto
                {dadosPedido.pedido.tipo === 'delivery' &&
                  ' e logo vai ser entregue'}
                .
              </p>
            )}
          </Timeline.Item>
          {dadosPedido.pedido.tipo === 'delivery' && (
            <Timeline.Item
              dot={
                entregue ? (
                  <CheckCircleFilled style={{ fontSize: '22px' }} />
                ) : null
              }
            >
              <p className="p-status">Entregue</p>
              <p className="p-valor">
                {entregue ? moment(entregue.datahora).format('LLLL') : null}
              </p>
              {entregue && <p className="p-valor">Aproveite o seu pedido!</p>}
            </Timeline.Item>
          )}
        </>
      )}
    </Timeline>
  );
}
