export default function unaccentString(text, case_insensitive = true) {
  let l_text = text;
  if (case_insensitive) l_text = l_text.toLowerCase();

  return l_text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const converteMonetario = (valor) => {
  return Intl.NumberFormat('pt-Br', {
    style: 'currency',
    currency: 'BRL'
  }).format(parseInt(valor, 10) / 100);
};

export const capitalizeFLetter = (str) => {
  return typeof str === 'string'
    ? str
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    : str;
};

export const substituirHttp = (url_requisicao) => {
  if (!url_requisicao) {
    return null;
  }
  if (url_requisicao.includes('localhost:')) {
    return url_requisicao;
  }
  const nova_url = url_requisicao.replace('http:/', 'https:/');
  return nova_url;
};

export function toUnaccentCaseInsensitive(c) {
  return unaccentString(c.toLowerCase().trim());
}

export function unaccentCaseInsensitiveEqual(a, b) {
  return toUnaccentCaseInsensitive(a) === toUnaccentCaseInsensitive(b);
}
