import moment from 'moment';

const ANO_ATUAL = moment().year();

const QUANTIDADE_ANOS_VALIDADE = 20;

export const ANOS_VALIDADE_CARTAO = Array.from(
  new Array(QUANTIDADE_ANOS_VALIDADE),
  (_, i) => `${ANO_ATUAL + i}`
);

export const MESES_DO_ANO = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];
