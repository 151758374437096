import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import ClipboardJS from 'clipboard';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import CopiarQRCodePixManual from './CopiarQRCodePixManual';

const propTypes = {
  qrcode: PropTypes.string.isRequired
};

const CopiarQRCodePixAreaTransferencia = ({ qrcode }) => {
  useEffect(() => {
    const clipboard = new ClipboardJS('#btn-copiar-qrcode');

    clipboard.on('error', () => {
      message.error(
        'Houve um erro ao tentar copiar o QRCode para a área de transferência.'
      );
    });

    clipboard.on('success', () => {
      message.success('QRCode copiado para a área de transferência.');
    });

    return () => {
      clipboard.destroy();
    };
  }, [qrcode]);

  if (!ClipboardJS.isSupported()) {
    return <CopiarQRCodePixManual qrcode={qrcode} />;
  }

  return (
    <Button
      block
      data-clipboard-text={qrcode}
      id="btn-copiar-qrcode"
      style={{ marginBottom: 16 }}
      type="primary"
    >
      Copiar QRCode
    </Button>
  );
};

CopiarQRCodePixAreaTransferencia.propTypes = propTypes;

export default CopiarQRCodePixAreaTransferencia;
