function formatarTelefone(telefone) {
    const NUMERO_DIGITOS_FIXO = 8;
    const NUMERO_DIGITOS_CELULAR = 9;
    const NUMERO_DIGITOS_DDD = 2;
    
    function removerZeroDoDDD(numero) {
        if (numero.charAt(0) === '0') {
            return numero.substr(1);
        }
        return numero;
    }

    function formatarDDD(numero) {
        if (numero.length >= NUMERO_DIGITOS_FIXO + NUMERO_DIGITOS_DDD) {
            return '(' + numero.substr(0, 2) + ') ';
        }
        return  '';
    }

    function formatarNumeroCelularOuFixo(numero) {
        let numero_sem_ddd = numero;
        if (numero.length >= NUMERO_DIGITOS_FIXO + NUMERO_DIGITOS_DDD)
            numero_sem_ddd = numero.substr(2);

        const qtdUltimosDigitos = 4;
        const qtdPrimeirosDigitos = numero_sem_ddd.length - qtdUltimosDigitos;
        return numero_sem_ddd.substr(0, qtdPrimeirosDigitos) + '-' + numero_sem_ddd.substr(qtdPrimeirosDigitos, qtdUltimosDigitos);
    }

    const digitos = removerZeroDoDDD(telefone.replace(/[^0-9]/g, ''));
    if (digitos.length < NUMERO_DIGITOS_FIXO) return digitos;
    return formatarDDD(digitos) + formatarNumeroCelularOuFixo(digitos);
}

export { formatarTelefone };