import React, { useEffect, useState } from 'react';
import { Affix, Badge, Button, Layout, Space, Tooltip } from 'antd';
import { FormOutlined, LeftOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { svgLogoApp, svgLogoEAD } from 'assets';

import ContentCarrinho from 'components/Delivery/Layout/Content/Carrinho';
import FormEnderecoSelecionado from 'components/Delivery/Layout/Header/Endereco/FormEnderecoSelecionado';

import useDeviceWidth from 'hooks/useDeviceWidth';
// import { usePosition } from 'hooks/usePosition';
import { useAuthContext } from 'contexts/AuthContext';
import UsuarioAvatar from 'components/Common/Usuario/Avatar';

import {
  filtrarCidadeUf,
  useFiltroEstabelecimentoContext
} from 'contexts/FiltroEstabelecimentoContext';

const DeliveryHeader = ({ children, pedidosPendentes }) => {
  // const {
    // location: { uf, cidade },
    // ipLocation: { cidadeIp, ufIp }
  // } = usePosition();

  const [, setInitialCidade] = useState();
  const [, setInitialUf] = useState();
  const history = useHistory();
  const location = useLocation();

  const { auth } = useAuthContext();

  const { filtroEstabDispatcher } = useFiltroEstabelecimentoContext();

  // useEffect(() => {
    // if (uf) setInitialUf(uf);
    // if (cidade) setInitialCidade(cidade);
  // }, [uf, cidade]);

  const { Header } = Layout;

  const [deviceWidth] = useDeviceWidth();

  // useEffect(() => {
  //   if (
  //     (!filtroEstabDispatcher.cidade || !filtroEstabDispatcher.uf) &&
  //     cidadeIp &&
  //     ufIp
  //   ) {
  //     filtroEstabDispatcher(filtrarCidadeUf(cidadeIp, ufIp));
  //   }
  // }, [ufIp, cidadeIp]);

  const MobileDevice = (
    <>
      <Affix offsetTop={0}>
        <Header className="main-header">
          <div className="container">
            <div className="mh-logo">
              {location.pathname.includes('estabelecimento') ||
              location.pathname === '/' ? (
                <img alt="logoapp" src={svgLogoApp} />
              ) : (
                <Button
                  style={{ zIndex: 1000 }}
                  shape="circle"
                  icon={<LeftOutlined />}
                  onClick={() => history.goBack()}
                />
              )}
            </div>

            <div>
              <FormEnderecoSelecionado />
            </div>
          </div>
          {children}
        </Header>
      </Affix>
    </>
  );

  const DesktopDevice = (
    <>
      <Header className="main-header">
        <div className="container">
          <div className="mh-logo">
            <img alt="logo" src={svgLogoEAD} />
          </div>

          <div>
            <FormEnderecoSelecionado />
          </div>

          <div style={{ paddingLeft: 100 }}>
            <Space size={16}>
              {auth.isLoggedIn ? (
                <Tooltip title="Meus Pedidos">
                  <Badge count={pedidosPendentes.length} key={1}>
                    <Link to="/pedido/">
                      <FormOutlined
                        style={{
                          fontSize: '25px',
                          color: '#ccc'
                        }}
                      />
                    </Link>
                  </Badge>
                </Tooltip>
              ) : (
                ' '
              )}
              <ContentCarrinho />
              <UsuarioAvatar />
            </Space>
          </div>
        </div>
        {children}
      </Header>
    </>
  );

  if (deviceWidth < 768) return MobileDevice;

  return DesktopDevice;
};
export default DeliveryHeader;
