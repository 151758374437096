const pedidoPresenter = (pedido) =>
  JSON.parse(
    pedido
      .replace(/'/g, '"')
      .replace(/None/g, 'null')
      .replace(/False/g, false)
      .replace(/True/g, true)
  );

const pedidosPresenter = (pedidos) => {
  return pedidos.map((pedido) => {
    try {
      return { ...pedido, pedido_json: pedidoPresenter(pedido.pedido) };
    } catch (err) {
      /* eslint-disable-next-line no-console */
      console.log(err);
      return { ...pedido, pedido_json: null };
    }
  });
};

export default pedidosPresenter;
