import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

const { Option } = Select;

const propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  isPagamentoOnline: PropTypes.bool,
  isDelivery: PropTypes.bool
};

const defaultProps = {
  isPagamentoOnline: false,
  isDelivery: true
};

const LocalEntregaField = ({ form, isPagamentoOnline, isDelivery }) => {
  useEffect(() => {
    if (!isPagamentoOnline) {
      form.setFieldsValue({
        local_entrega: 'Quero encontrar o entregador.'
      });
    }
  }, [form, isPagamentoOnline]);

  useEffect(() => {
    if (isDelivery) {
      form.resetFields(['local_entrega']);
    }
  }, [form, isDelivery]);

  return (
    !isDelivery && (
      <Form.Item
        label="Onde o pedido deve ser deixado"
        name="local_entrega"
        rules={[{ message: 'Campo obrigatório', required: true }]}
      >
        <Select disabled={!isPagamentoOnline}>
          {isPagamentoOnline && (
            <>
              <Option value="Portão da casa / prédio.">
                Portão da casa / prédio.
              </Option>
              <Option value="Portaria do prédio / condomínio.">
                Portaria do prédio / condomínio.
              </Option>
            </>
          )}
          <Option value="Quero encontrar o entregador.">
            Quero encontrar o entregador.
          </Option>
        </Select>
      </Form.Item>
    )
  );
};

LocalEntregaField.propTypes = propTypes;
LocalEntregaField.defaultProps = defaultProps;

export default LocalEntregaField;
