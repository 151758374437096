import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CarrinhoProvider } from 'contexts/CarrinhoContext';
import { useAuthContext, setRedirecionar } from 'contexts/AuthContext';
import {
  useFiltroEstabelecimentoContext,
  limparFiltro
} from 'contexts/FiltroEstabelecimentoContext';
// import { useEnderecoContext } from 'contexts/EnderecoContext';
import DeliveryRouter from './Router';
import DeliveryLayout from './Layout';

window.initMap = () => {};

const Delivery = () => {
  const { auth, authDispatcher } = useAuthContext();
  const { filtroEstabDispatcher } = useFiltroEstabelecimentoContext();
  const location = useLocation();
  // const { enderecoDispatcher } = useEnderecoContext();

  useEffect(() => {
    if (!document.getElementById('google-maps-api-script')) {
      const googleMapsScript = document.createElement('script');

      googleMapsScript.async = true;
      googleMapsScript.id = 'google-maps-api-script';
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap&libraries=geometry`;

      document.body.appendChild(googleMapsScript);
    }
  }, []);

  useEffect(() => {
    if (auth.isLoggedIn === false) {
      filtroEstabDispatcher(limparFiltro());
      // enderecoDispatcher(clearEndereco());
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    authDispatcher(setRedirecionar(location.pathname));
  }, [location]);

  return (
    <CarrinhoProvider>
      <DeliveryLayout>
        <DeliveryRouter />
      </DeliveryLayout>
    </CarrinhoProvider>
  );
};

export default Delivery;
