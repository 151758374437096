import { useState } from 'react';

import { apiDelivery } from 'services/api';

import StatusPedido from 'utils/Pedido';

const INTERVALO_TENTATIVAS = 10000;

export class PgtoNaoEfetuadoError extends Error {
  constructor(message) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PgtoNaoEfetuadoError);
    }

    this.name = 'PgtoNaoEfetuadoError';
  }
}

export const obterStatusPgto = (deliveryestabelecimento, idpedido) => {
  return apiDelivery
    .get(`/api/estabelecimento/${deliveryestabelecimento}/status/${idpedido}/`)
    .then((response) => response.data)
    .then((response) => response.status);
};

const useStatusPgto = (deliveryestabelecimento) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const checarStatusPgtoPoll = async (idpedido) => {
    const executarPollStatusPgto = async (resolve, reject) => {
      try {
        const status = await obterStatusPgto(deliveryestabelecimento, idpedido);

        if (status === StatusPedido.Sincronizado) {
          resolve();
        } else if (
          status === StatusPedido.Cancelado ||
          status === StatusPedido.CancelamentoSincronizado
        ) {
          reject(new PgtoNaoEfetuadoError('Pagamento cancelado.'));
        } else if (status === StatusPedido.PgtoPendente) {
          const pollTimeoutId = setTimeout(
            executarPollStatusPgto,
            INTERVALO_TENTATIVAS,
            resolve,
            reject
          );
          setTimeoutId(pollTimeoutId);
        }
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.log(error);
        const pollTimeoutId = setTimeout(
          executarPollStatusPgto,
          INTERVALO_TENTATIVAS,
          resolve,
          reject
        );
        setTimeoutId(pollTimeoutId);
      }
    };

    return new Promise(executarPollStatusPgto);
  };

  const interromperPollStatusPgto = () => {
    clearTimeout(timeoutId);
  };

  return [checarStatusPgtoPoll, interromperPollStatusPgto];
};

export default useStatusPgto;
