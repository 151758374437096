import React from 'react';
import { Typography, Card, Divider, Layout } from 'antd';

// import { Container } from './styles';

function Privacidade() {
  const { Text, Title, Paragraph } = Typography;
  return (
    <Layout>
      <Card>
        <Title level={3}>Política de Privacidade</Title>
        <Divider type="horizontal" />
        <Paragraph style={{ textAlign: 'justify' }}>
          Cada uma das partes se compromete a não divulgar a qualquer terceiro,
          quaisquer informações, inclusive o Conteúdo do CLIENTE e outras
          informações prestadas pelo CLIENTE à CONTRATADA, obtidas de outra
          parte, que sejam designadas como proprietárias ou confidenciais ou
          que, pela sua natureza, seja manifestamente claro que devam ser
          consideradas confidenciais ou proprietárias, ressalvado que a
          CONTRATADA poderá acessar, divulgar e compartilhar essas Informações
          Confidenciais do CLIENTE e de seus Usuários Finais na extensão
          necessária para a prestação dos serviços hora contratados. Cada uma
          das partes concorda que deverá usar o mesmo grau de zelo que utiliza
          para a proteção de suas próprias informações confidenciais de natureza
          similar para proteger o sigilo e evitar a divulgação ou uso das
          Informações Confidenciais, visando impedir que se tornem de domínio
          público ou da posse de terceiros, que não as pessoas autorizadas nos
          termos deste Contrato a possuir quaisquer destas informações. A
          obrigação de confidencialidade nesta cláusula não se aplica às
          informações que o recipiente possa demonstrar que tenham se tornado do
          seu conhecimento de outra forma, que não fornecidas nos termos deste
          Contrato ou obtidas da outra parte, e às informações que sejam de
          domínio público. Além disso, as informações confidenciais poderão ser
          divulgadas na medida em que a divulgação seja necessária para permitir
          o cumprimento por parte da legislação aplicável, de uma decisão
          judicial ou para o cumprimento de exigências das agências
          governamentais ou de terceiros, cuja divulgação seja determinada pela
          referida parte, mas somente após ter primeiramente notificado a outra
          parte da exigência de divulgação, salvo se esta notificação for
          proibida.
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          A CONTRATADA coleta as informações fornecidas pelos usuários para a
          realização dos cadastros em seus servidores. Os dados coletados são as
          informações inerentes a empresa e usuários, que serão usados
          estritamente para efeito de registros em seus servidores e emissão de
          contrato.
        </Paragraph>

        <Paragraph style={{ textAlign: 'justify' }}>
          <Text strong>Marco Civil da Internet (lei nº 12.965/2014)</Text>
        </Paragraph>

        <Paragraph style={{ textAlign: 'justify' }}>
          A CONTRATADA assegura que está de acordo com o Marco Civil da Internet
          (lei nº 12.965/2014) e que removerá qualquer material caso seja
          devidamente notificada de que os materiais infringiram direitos
          autorais de terceiros. Além disso, a CONTRATADA pode, em
          circunstâncias apropriadas, encerrar as contas de infratores de
          direitos autorais. Se você acredita que seu trabalho foi copiado de
          uma forma que constitua violação de direitos autorais, por favor nos
          forneça um aviso por escrito, contendo as seguintes informações: Seu
          nome, endereço, número de telefone e endereço de e-mail (se houver). A
          descrição do perfil com direitos autorais que você alega ter sido
          violado. Uma descrição de onde o material que você alega estar
          infringindo está localizado no material fornecido pela equipe do
          SOFTWARE, suficiente para que possamos localizar o supostamente
          violador. Uma declaração de que você tem uma crença de boa fé que o
          uso do trabalho com direitos autorais não é autorizado pelo
          proprietário dos direitos autorais, seu agente ou a lei. Uma
          declaração sua de que as informações em seu aviso são precisas e sob
          pena de perjúrio, que você é o proprietário dos direitos autorais ou
          autorizado a agir em nome do proprietário dos direitos autorais. Uma
          assinatura eletrônica ou física da pessoa autorizada a agir em nome do
          proprietário dos direitos autorais. Você pode enviar essas informações
          via: (1) e-mail, enviando uma notificação para
          atendimento@proabakus.com.br ou (2) Correios para agente de Direitos
          Autorais regulamentados.
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          <Text strong>Dados</Text>
        </Paragraph>
        <Paragraph style={{ textAlign: 'justify' }}>
          Os dados referentes a uso do SOFTWARE ficam armazenados em servidores
          com uso da tecnologia de nuvem (cloud computer), desde que o cliente
          não esteja utilizando a versão Stand Alone.
        </Paragraph>

        <Divider type="horizontal" />
        <Title level={3}>Restrições de Responsabilidades</Title>

        <Paragraph style={{ textAlign: 'justify' }}>
          Por meio deste, o CLIENTE reconhece que o SOFTWARE pode não estar
          disponível ou ser indisponibilizado por diversos fatores, incluindo,
          sem limitação, manutenções periódicas do sistema (programadas ou não),
          manifestações da natureza, falhas técnicas do software, infraestrutura
          de telecomunicações ou atraso ou interrupção ocasionada por vírus,
          ataques de negação de serviços, aumento ou flutuação de demanda, ações
          e omissões de terceiros ou qualquer outra causa que esteja fora do
          controle da CONTRATADA. Portanto, a CONTRATADA se isenta expressamente
          de qualquer garantia explícita ou implícita a respeito de
          disponibilidade, acessibilidade ou desempenho do sistema e/ou SOFTWARE
        </Paragraph>

        <Paragraph style={{ textAlign: 'justify' }}>
          O cliente assume o custo total de qualquer dano ocasionado pelo
          próprio uso do SOFTWARE, pelas informações contidas ou compiladas pelo
          software e pela interação (ou imperícia na interação) com qualquer
          outro hardware ou software, seja fornecido pela CONTRATADA ou por
          terceiros. Pela máxima extensão permitida pela lei aplicável, em
          nenhuma circunstância a CONTRATADA, nem seus fornecedores ou
          licenciadores serão responsabilizados por qualquer dano (incluindo,
          sem limitação, danos por lucros cessantes, interrupção de negócios,
          perda de informações comerciais, perda de bens intangíveis,
          interrupção de trabalho, ruptura, dano ou falha no hardware ou
          software, custos de reparo, perda de tempo de trabalho ou outras
          perdas pecuniárias) decorrente do uso ou da imperícia no uso do
          SOFTWARE, ou incompatibilidade do SOFTWARE com qualquer hardware,
          software ou tipo de utilização.
        </Paragraph>

        <Paragraph style={{ textAlign: 'justify' }}>
          Mesmo que, por segurança e conveniência, a CONTRATADA mantenha sob sua
          guarda durante a vigência deste contrato cópias de seguranças
          (backups) dos dados cadastrados pelo cliente no SOFTWARE, é dever do
          cliente fazer suas próprias cópias, de modo que a contratada em
          hipótese alguma será responsável pela perda de dados cadastrados.
        </Paragraph>

        <Paragraph style={{ textAlign: 'justify' }}>
          Em nenhum caso, alguma das partes será responsável em relação à outra
          parte pelo lucro cessante, ou pelos danos indiretos, especiais,
          incidentais, consequentes, de cobertura ou punitivos, seja qual for a
          causa, ora de natureza contratual, ora por ato ilícito, ou conforme
          qualquer outra teoria de responsabilidade, tenha sido notificada ou
          não a outra parte sobre a possibilidade de tais danos. A isenção
          precedente não será aplicada quando proibido pela lei aplicável.
        </Paragraph>
      </Card>
    </Layout>
  );
}

export default Privacidade;
