import React from 'react';
import { Tag, Typography } from 'antd';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import { obterValorMonetario } from 'services/precos';

import './PrecoOfertaProduto.css';

const { Text } = Typography;

const propTypes = {
  preco: PropTypes.number,
  precooferta: PropTypes.number,
  prefixoPreco: PropTypes.string
};

const defaultProps = {
  preco: null,
  precooferta: null,
  prefixoPreco: ''
};

const PrecoOfertaProduto = ({ preco, precooferta, prefixoPreco }) => {
  if (precooferta >= preco) {
    return (
      <Text>
        {prefixoPreco}
        {obterValorMonetario(precooferta)}
      </Text>
    );
  }

  return (
    <div className="preco-oferta-produto">
      <Text delete>{obterValorMonetario(preco)}</Text>
      <Text style={{ color: 'green', margin: '0 8px 0' }}>
        {prefixoPreco}
        {obterValorMonetario(precooferta)}
      </Text>
      <Tag color="green">OFERTA</Tag>
    </div>
  );
};

PrecoOfertaProduto.propTypes = propTypes;
PrecoOfertaProduto.defaultProps = defaultProps;

export default PrecoOfertaProduto;
