import moment from 'moment';

const funcionaHoje = ({ diadasemana }) => diadasemana === moment().day();

const isHorarioFuncionamento = ({ abertura, fechamento }) => {
  const format = 'hh:mm:ss';
  const horaAtual = moment();
  const horaAbertura = moment(abertura, format);
  const horaFechamento = moment(fechamento, format);
  return horaAtual.isBetween(horaAbertura, horaFechamento);
};

const isLojaAberta = (horarios) => {
  return (
    horarios.some(funcionaHoje) &&
    horarios.filter(funcionaHoje).some(isHorarioFuncionamento)
  );
};

const momentFactoryForHour = time => moment(time, 'HH:mm:ss')

export const obterHorarioFuncionamento = (horarios) => {
  const diasDaSemana = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado'
  ];
  return diasDaSemana
    .map((dia, indiceDia) => {
      return {
        descricao: dia,
        horarios:
          horarios
            .filter((horario) => horario.diadasemana === indiceDia)
            .sort((a, b) => (
              momentFactoryForHour(a.abertura) < momentFactoryForHour(b.abertura)
                ? -1
                : 1
            ))
      }
    })
    .filter((dia) => dia.horarios.length > 0);
};

export default isLojaAberta;
