import './Acesso.css';

import { Button, Form, Input, Spin, Typography, notification } from 'antd';
import { LeftOutlined, MailOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { apiDelivery } from 'services/api';
import { svgLogoEAD } from 'assets';
import { tratarErro } from 'utils/tratarErros';
import AcessoFooter from './AcessoFooter';

export default function AcessoRecuperarSenha() {
  const [loading, setLoading] = useState(false);
  const { Text } = Typography;

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await apiDelivery
        .post('/controle/cliente/token-resete-senha/', values)

        .catch((error) => {
          throw error;
        });

      notification.success({
        message: 'Falta Pouco',
        description: `Um email com as instruções para a redefinição da senha foi enviado para ${values.email}`,
        duration: 0
      });
    } catch (err) {
      const errorMsg = tratarErro(err);
      notification.error({
        message: 'Tente Novamente',
        description: errorMsg,
        duration: 0
      });
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <div style={{ position: 'fixed', top: 32, left: 32 }}>
        <Link to="/login">
          <Button icon={<LeftOutlined />} shape="circle" size="large" />
        </Link>
      </div>
      <Form onFinish={handleSubmit} className="form-email">
        <div className="logo">
          <img alt="logo" src={svgLogoEAD} />
        </div>
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <Text type="secondary">Preencha com seu email cadastrado.</Text>
        </div>
        <Spin spinning={loading}>
          <Form.Item
            name="email"
            rules={[
              { message: 'O email é obrigatório.', required: true },
              { message: 'Digite um email válido.', type: 'email' }
            ]}
          >
            <Input placeholder="Email" prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item>
            <Button block htmlType="submit" type="primary">
              Enviar
            </Button>
          </Form.Item>
          <div style={{ textAlign: 'center' }}>
            Lembrou ?! Então faça <Link to="/login">Login</Link>
          </div>
        </Spin>
      </Form>

      <AcessoFooter />
    </>
  );
}
