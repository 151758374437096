import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Tag, message } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import { STPRECOPRATICADO } from 'utils/GrupoOpcao';

import { GrupoOpcaoContext } from './GrupoOpcao';
import PrecoOpcao from './PrecoOpcao';
import ValorAjusteFeedback from './ValorAjusteFeedback';

import './OpcaoGrupoOpcao.css';

const OpcaoGrupoOpcao = ({
  hasMaximoOpcionais,
  opcao,
  stprecopraticado,
  valorAfetaTudo
}) => {
  const { opcionaisDispatcher } = useContext(GrupoOpcaoContext);
  const {
    descricao,
    detalhe,
    idopcao,
    qtdpadrao,
    quantidade,
    qtdmax,
    stindisponivel
  } = opcao;

  const [quantidadeExcedida, setQuantidadeExcedida] = useState(false);
  const [qtdAtual, setQtdAtual] = useState(quantidade);

  const indisponivel = stindisponivel === 1;

  useEffect(() => {
    if (qtdpadrao > 0) {
      opcionaisDispatcher({ type: 'DEFINIR_QTD_PADRAO', idopcao, qtdpadrao });
      return () => {};
    }
  }, [qtdpadrao]);

  const handleAdicionar = () => {
    const novaQuantidade = qtdAtual + 1;

    if (qtdmax > 0 && novaQuantidade > qtdmax) {
      setQuantidadeExcedida(true);
      message.error('Quantidade máxima da opção excedida!');
    } else {
      opcionaisDispatcher({ type: 'ADICIONAR_OPCIONAL', idopcao });
      setQtdAtual(novaQuantidade);
    }
  };

  const handleRemover = () => {
    opcionaisDispatcher({ type: 'REMOVER_OPCIONAL', idopcao });
    setQtdAtual(quantidade - 1);
    setQuantidadeExcedida(false);
  };

  return (
    <Card
      className={indisponivel ? 'opcao-disabled' : 'opcao'}
      size="small"
      style={{ marginBottom: 8 }}
    >
      <Card.Meta title={descricao} />
      <ValorAjusteFeedback opcao={opcao} />
      <Card.Meta style={{ marginTop: 5 }} description={detalhe} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginTop: 8
        }}
      >
        {![STPRECOPRATICADO.DesprezaOpcao, STPRECOPRATICADO.Kit].includes(
          stprecopraticado
        ) ? (
          <PrecoOpcao
            opcao={opcao}
            isPrecoOpcaoPraticado={stprecopraticado === STPRECOPRATICADO.Normal}
            valorAfetaTudo={valorAfetaTudo}
          />
        ) : (
          <span />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'baseline'
          }}
        >
          {indisponivel ? (
            <Tag>INDISPONÍVEL</Tag>
          ) : (
            <>
              {quantidade ? (
                <Button
                  disabled={stprecopraticado === STPRECOPRATICADO.Kit}
                  shape="circle"
                  style={{
                    color:
                      stprecopraticado === STPRECOPRATICADO.Kit
                        ? 'gray'
                        : 'red',
                    border: 0
                  }}
                  icon={<MinusOutlined />}
                  onClick={handleRemover}
                />
              ) : null}
              {quantidade ? (
                <span style={{ fontSize: 18, margin: '0 8px' }}>
                  {quantidade}
                </span>
              ) : null}
              <Button
                shape="circle"
                disabled={
                  stprecopraticado === STPRECOPRATICADO.Kit ||
                  indisponivel ||
                  hasMaximoOpcionais ||
                  quantidadeExcedida
                }
                style={{
                  color:
                    stprecopraticado === STPRECOPRATICADO.Kit ||
                    indisponivel ||
                    hasMaximoOpcionais ||
                    quantidadeExcedida
                      ? 'gray'
                      : 'red',
                  border: 0
                }}
                icon={<PlusOutlined />}
                onClick={handleAdicionar}
              />
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default OpcaoGrupoOpcao;
