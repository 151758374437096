import React, { Fragment } from 'react';
import { Card, Radio } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import PropTypes from 'prop-types';

import DescricaoBandeiraCartao from '../DescricaoBandeiraCartao';
import ImagemBandeiraCartao from '../ImagemBandeiraCartao';

const propTypes = {
  cartoes: PropTypes.arrayOf(
    PropTypes.shape({
      bandeira: PropTypes.string,
      id: PropTypes.string,
      numero: PropTypes.string,
      titular: PropTypes.string,
      token: PropTypes.string
    })
  )
};

const defaultProps = {
  cartoes: []
};

const CartoesSalvosList = ({ cartoes }) => {
  return (
    /* eslint-disable-next-line react/jsx-fragments */
    <Fragment>
      {cartoes.map((cartao) => {
        const { bandeira, id, token, numero, titular } = cartao;
        return (
          <Card key={id} style={{ marginBottom: 16 }}>
            <Radio style={{ display: 'block' }} value={token}>
              <Card.Meta
                avatar={<ImagemBandeiraCartao bandeira={bandeira} />}
                description={
                  <span>
                    <DescricaoBandeiraCartao bandeira={bandeira} />
                    {' - '}
                    {numero}
                  </span>
                }
                style={{ marginTop: 16 }}
                title={titular}
              />
            </Radio>
          </Card>
        );
      })}

      <Card style={{ marginBottom: 16 }}>
        <Radio style={{ display: 'block' }} value="">
          <Card.Meta
            avatar={<CreditCardOutlined style={{ fontSize: 32 }} />}
            description="Deseja utilizar outro cartão?"
            style={{ marginTop: 16 }}
            title="Outro"
          />
        </Radio>
      </Card>
    </Fragment>
  );
};

CartoesSalvosList.propTypes = propTypes;
CartoesSalvosList.defaultProps = defaultProps;

export default CartoesSalvosList;
