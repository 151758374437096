import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Affix,
  Alert,
  Button,
  BackTop,
  Col,
  Collapse,
  Input,
  notification,
  Row,
  Spin,
  Typography
} from 'antd';
import { Link, Element } from 'react-scroll';
import StickyBox from 'react-sticky-box';
import {
  CaretRightOutlined,
  LeftOutlined,
  ToTopOutlined
} from '@ant-design/icons';

import useAreasEntrega from 'hooks/useAreasEntrega';
import useDeviceWidth from 'hooks/useDeviceWidth';
import useEstabelecimento from 'hooks/useEstabelecimento';
import useFormasPagamento from 'hooks/useFormasPagamento';
import useMenu from 'hooks/useMenu';

import { capitalizeFLetter } from 'utils/normalize';
import Endereco from 'components/Common/Estabelecimento/Endereco';
import CardapioProduto from './Produto';
import ProdutoDrawer from './Produto/Modal';
import EstabelecimentoHeader from './EstabelecimentoHeader';

import isLojaAberta, { obterHorarioFuncionamento } from './utils';
import { useCarrinhoContext } from 'contexts/CarrinhoContext';
import { obterValorMonetario, obterValorSubTotal } from 'services/precos';

const { Paragraph, Title } = Typography;

const ContentCardapio = () => {
  const [width] = useDeviceWidth();
  const { id } = useParams();
  const [menu, menuLoading] = useMenu(id);
  const [search, setSearch] = useState('');
  const [produtoDrawerVisible, setProdutoDrawerVisible] = useState(false);
  const [produtoDrawerSelected, setProdutoDrawerSelected] = useState({});
  const [isEstabelecimentoAberto, setEstabelecimentoAberto] = useState(true);
  // const [menuRefs, setMenuRefs] = useState([]);
  const { carrinho } = useCarrinhoContext();

  const { itens, abono } = carrinho;

  const valorTotal = obterValorSubTotal(itens);

  const [
    dadosEstabelecimento,
    dadosEstabelecimentoLoading
  ] = useEstabelecimento(id);
  const [dadosAreasEntrega, dadosAreasEntregaLoading] = useAreasEntrega(id);
  const [
    formasPgto,
    formasPgtoLoading,
    formasPgtoError,
    { getFormasPagamento }
  ] = useFormasPagamento();

  const history = useHistory();

  const menuRefs = useRef([]);

  const salvarCaminhoEstabelecimentoVisitado = () => {
    window.localStorage.setItem(
      'caminho_estabelecimento_visitado',
      history.location.pathname
    );
  };

  useEffect(salvarCaminhoEstabelecimentoVisitado, []);

  useEffect(() => {
    menuRefs.current = menuRefs.current.slice(0, menu.length);
  }, [menu]);

  const scrollToCategory = (idgrupo) => {
    if (menuRefs.length !== 0 && menuRefs.current[idgrupo]) {
      menuRefs.current[idgrupo].scrollIntoView({ inline: 'center' });
    }
    return null;
  };

  useEffect(() => {
    if (dadosEstabelecimento) {
      const { horariofuncionamento_set, lojaaberta } = dadosEstabelecimento;
      setEstabelecimentoAberto(
        lojaaberta && isLojaAberta(horariofuncionamento_set)
      );
      getFormasPagamento(dadosEstabelecimento.id);
    }
  }, [dadosEstabelecimento]);

  const cardapio =
    search === ''
      ? menu
      : menu
          // aplica o filtro nos itens de cada grupo
          .map((item) => {
            const { produtos } = item;
            return {
              ...item,
              produtos: produtos.filter((produto) => {
                const descricao = produto.descricao.toLowerCase();
                return descricao.includes(search.toLowerCase());
              })
            };
          })
          // obtém os grupos que possuem produtos com o filtro
          .filter((item) => {
            const { produtos } = item;
            return produtos.length > 0;
          });

  function onProdutoClicked(produto) {
    if (!isEstabelecimentoAberto) {
      notification['warning']({
        key: 'estab_fechado',
        message: 'Estabelecimento Fechado',
        description: (
          <p>
            No momento o estabelecimento está fechado. <br />
            Tente novamente mais tarde!
          </p>
        )
      });
      return;
    }
    if (formasPgto.length <= 0) {
      notification['warning']({
        key: 'estab_formapgto',
        message: 'Sem Forma de Pagamento',
        description:
          'No momento o estabelecimento não possui formas de pagamento.'
      });
      return;
    }
    setProdutoDrawerSelected(produto);
    setProdutoDrawerVisible(true);
    const search_item = `?item=${produto.idproduto}`;
    history.push({ search: search_item });
  }

  function closeProdutoDrawer() {
    document.body.removeAttribute('style');
    if (history.location.search.includes('?item=')) {
      history.goBack();
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const idproduto = query.get('item');
    const is_produtoDrawerSelected = Boolean(
      Object.keys(produtoDrawerSelected).length
    );
    setProdutoDrawerVisible(Boolean(idproduto && is_produtoDrawerSelected));

    if (idproduto && !is_produtoDrawerSelected && menu.length) {
      history.replace({ search: null });
    }
  }, [history.location.search, menu]);

  const onProdutoDrawerAnimationEnded = useCallback(
    (openAnimation) => {
      if (!openAnimation) {
        setProdutoDrawerSelected({});
        if (history.location.search.includes('?item=')) {
          history.goBack();
        }
      } else {
        const search_item = `?item=${produtoDrawerSelected.idproduto}`;
        if (!history.location.search.includes(search_item)) {
          history.push({ search: search_item });
        }
      }
    },
    [produtoDrawerSelected, history]
  );

  const Header = useCallback(() => {
    if (width < 728) {
      return (
        <Affix offsetTop={4} style={{ background: '#fff' }}>
          <EstabelecimentoHeader
            dadosEstabelecimento={dadosEstabelecimento}
            isEstabelecimentoAberto={isEstabelecimentoAberto}
          />
        </Affix>
      );
    }

    return (
      <EstabelecimentoHeader
        dadosEstabelecimento={dadosEstabelecimento}
        isEstabelecimentoAberto={isEstabelecimentoAberto}
      />
    );
  }, [width, isEstabelecimentoAberto, dadosEstabelecimento]);

  return (
    <Spin
      spinning={
        menuLoading || dadosAreasEntregaLoading || dadosEstabelecimentoLoading
      }
    >
      <div
        style={{
          padding: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      />
      <Header />
      {/* <Button
        style={{ position: 'fixed', left: 15, top: 15, zIndex: 12 }}
        shape="circle"
        type="link"
        icon={<LeftOutlined />}
        onClick={() => history.goBack()}
      /> */}

      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="site-collapse-custom-collapse"
      >
        <Collapse.Panel
          header="Mais Informações"
          key="1"
          className="site-collapse-custom-panel"
        >
          <div>
            {`Endereço: `}
            {dadosEstabelecimento && (
              <Endereco endereco={dadosEstabelecimento.endereco} />
            )}
            {formasPgto.length && (
              <Paragraph>
                Formas de pagamento:{' '}
                {formasPgto.map(({ descricao }) => descricao).join(', ')}.
              </Paragraph>
            )}
            <Paragraph>
              Horários de atendimento:
              <ul>
                {dadosEstabelecimento &&
                  obterHorarioFuncionamento(
                    dadosEstabelecimento.horariofuncionamento_set
                  ).map((dia, indice) => (
                    <li key={indice}>
                      {dia.descricao}:{' '}
                      {dia.horarios
                        .map(
                          ({ abertura, fechamento }) =>
                            `${abertura.slice(0, 5)} às ${fechamento.slice(
                              0,
                              5
                            )}`
                        )
                        .join(', ')}
                    </li>
                  ))}
              </ul>
            </Paragraph>
          </div>
        </Collapse.Panel>
      </Collapse>

      <Row align="center">
        <Col xs={24} sm={12} md={12} lg={8}>
          <Affix offsetTop={width < 768 ? 94 : 20}>
            <Input
              allowClear
              placeholder="Buscar no cardápio"
              size="large"
              style={{ marginBottom: 0 }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                // document.body.scrollTop = 0;
                // document.documentElement.scrollTop = 0;
              }}
            />
          </Affix>
        </Col>
      </Row>

      <Affix offsetTop={width < 768 ? 134 : 60}>
        <Row
          gutter={[0, 0]}
          align={width < 768 ? 'strech' : 'center'}
          className="menu-scroll"
        >
          <StickyBox>
            <ul
              style={{
                marginTop: '5px',
                display: 'flex',
                flexDirection: 'row',
                overflowY: 'hidden',
                whiteSpace: 'nowrap',
                listStyleType: 'none',
                paddingLeft: '16px',
                backgroundColor: '#ffffff',
                flexWrap: 'nowrap',
                height: '50px',
                justifyItems: 'center',
                WebkitOverflowScrolling: 'touch'
              }}
            >
              {cardapio &&
                cardapio.map((item) =>
                  item.idgrupo ? (
                    <li
                      key={item.idgrupo}
                      style={{
                        display: 'inline-block',
                        margin: '16px',
                        fontSize: '12px'
                      }}
                      ref={(el) => (menuRefs.current[item.idgrupo] = el)}
                    >
                      <Link
                        activeClass="activeCategoryLink"
                        className={item.idgrupo}
                        to={item.idgrupo.toString()}
                        spy
                        smooth
                        duration={200}
                        offset={width < 768 ? -210 : -120}
                        onSetActive={() => scrollToCategory(item.idgrupo)}
                      >
                        {capitalizeFLetter(item.descricao)}
                      </Link>
                    </li>
                  ) : null
                )}
            </ul>
          </StickyBox>
        </Row>
      </Affix>

      {cardapio.length === 0 ? (
        <Alert
          message={
            menuLoading
              ? 'Aguarde, buscando os produtos do estabelecimento.'
              : 'Nenhum produto encontrado.'
          }
          type="info"
        />
      ) : (
        cardapio.map((item, index) => {
          const { descricao, idgrupo, produtos } = item;
          return idgrupo ? (
            <Element
              name={idgrupo.toString()}
              className={idgrupo}
              key={'display' + idgrupo}
            >
              <React.Fragment key={idgrupo}>
                <Title className="teste" id={descricao} level={4}>
                  {capitalizeFLetter(descricao)}
                </Title>
                <Row gutter={16}>
                  {produtos
                    // .filter((produto) => produto.preco !== null)
                    .map((produto, index) => (
                      <CardapioProduto
                        key={index}
                        produto={produto}
                        onClick={onProdutoClicked}
                      />
                    ))}
                </Row>
                <BackTop visibilityHeight={1200}>
                  <Button
                    type="ghost"
                    style={{
                      border: '1px solid rgba(0,0,0,0.01)',
                      backgroundColor: 'rgba(0,0,0,0.01)',
                      color: '#fefefe'
                    }}
                    icon={<ToTopOutlined />}
                  />
                </BackTop>
              </React.Fragment>
            </Element>
          ) : null;
        })
      )}

      {/* <Affix offsetBottom={width < 726 ? 60 : 0}>
        {abono !== 0 && typeof abono === 'number' ? (
          valorTotal <= abono ? (
            <Alert
              type="success"
              message={`Entrega Grátis com o mínimo de ${obterValorMonetario(
                abono
              )}!`}
            />
          ) : (
            <Alert type="success" message={`Entrega grátis!`} />
          )
        ) : null}
      </Affix> */}

      <ProdutoDrawer
        areasEntrega={dadosAreasEntrega}
        estabelecimento={dadosEstabelecimento}
        produto={produtoDrawerSelected}
        onAnimationEnded={onProdutoDrawerAnimationEnded}
        visible={produtoDrawerVisible}
        onClose={closeProdutoDrawer}
      />
    </Spin>
  );
};

export default ContentCardapio;
