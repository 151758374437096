import React, { createContext, useEffect, useReducer, useState } from 'react';
import { Alert, Tag, Typography } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

import {
  obterValor,
  obterValorMonetario,
  obterValorOpcionais
} from 'services/precos';
import {
  STPRECOPRATICADO,
  LISTA_STPRECOPRATICADO_FRACIONADO
} from 'utils/GrupoOpcao';

import OpcaoGrupoOpcao from './OpcaoGrupoOpcao';

import { isGrupoOpcaoValido, obterQuantidadeOpcionais } from './utils';

const { Paragraph, Text, Title } = Typography;

export const GrupoOpcaoContext = createContext(null);

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADICIONAR_OPCIONAL':
      return state.map((opcao) => {
        if (opcao.idopcao === action.idopcao) {
          const { quantidade } = opcao;
          return { ...opcao, quantidade: quantidade + 1 };
        }
        return opcao;
      });
    case 'DEFINIR_QTD_PADRAO':
      return state.map((opcao) => {
        if (opcao.idopcao === action.idopcao) {
          const { quantidade } = opcao;
          const { qtdpadrao } = action;
          return { ...opcao, quantidade: quantidade + action.qtdpadrao };
        }
        return opcao;
      });
    case 'REMOVER_OPCIONAL':
      return state.map((opcao) => {
        if (opcao.idopcao === action.idopcao) {
          const { quantidade } = opcao;
          return { ...opcao, quantidade: quantidade - 1 };
        }
        return opcao;
      });
    default:
      return state;
  }
};

const GrupoOpcao = ({
  grupoopcao,
  produto,
  valorAfetaTudo,
  onAtualizarGrupo
}) => {
  const {
    descricao,
    opcoes,
    qtdmultiplomax,
    qtdmultiplomin,
    stprecopraticado,
    stindisponivel
  } = grupoopcao;
  const [opcionais, opcionaisDispatcher] = useReducer(reducer, opcoes);
  const [valorGrupoOpcao, setValorGrupoOpcao] = useState(0);

  useEffect(() => {
    onAtualizarGrupo({ ...grupoopcao, opcoes: opcionais });
    setValorGrupoOpcao(
      obterValorOpcionais({ ...grupoopcao, opcoes: opcionais }, valorAfetaTudo)
    );
  }, [opcionais]);

  const mensagemPrecoPraticado = (status) => {
    switch (status) {
      case STPRECOPRATICADO.MenorOpcao:
        return 'O menor preço entre as opções escolhidas vai ser somado ao preço do produto';
      case STPRECOPRATICADO.MediaOpcao:
        return 'A média dos preço das opções escolhidas vai ser somada ao preço do produto';
      case STPRECOPRATICADO.MaiorOpcao:
        return 'O maior preço entre as opções escolhidas vai ser somado ao preço do produto';
      default:
        return '';
    }
  };

  const msgPrecoPraticado = mensagemPrecoPraticado(stprecopraticado);

  const quantidade = obterQuantidadeOpcionais(opcionais);

  const GrupoOk = isGrupoOpcaoValido({
    qtdmultiplomax,
    qtdmultiplomin,
    opcoes: opcionais
  });

  const stprecopraticado_fracionado = LISTA_STPRECOPRATICADO_FRACIONADO.includes(
    stprecopraticado
  );

  const ParagraphAjudaQtdOpcao = ({ min, max }) => {
    const opcaoSingularPlural = (qtd) => (qtd === 1 ? 'opção' : 'opções');

    const minimo = max === 0 && qtdmultiplomin > 0;
    const maximo = min === 0 && max > 0;
    const minmax_mq_zero = min > 0 && max > 0;
    const intervalo = minmax_mq_zero && min !== max;
    const obrigatorio = minmax_mq_zero && min === max;

    let texto;
    if (minimo) {
      texto = `Escolha no mínimo ${min} ${opcaoSingularPlural(min)}.`;
    } else if (maximo) {
      texto = `Escolha no máximo ${max} ${opcaoSingularPlural(max)}.`;
    } else if (intervalo) {
      texto = `Escolha de ${min} a ${max} opções.`;
    } else if (obrigatorio) {
      texto = `Escolha ${min} ${opcaoSingularPlural(min)}.`;
    } else {
      return null;
    }

    return <Paragraph type="secondary">{texto}</Paragraph>;
  };

  if (stindisponivel > 0 && stindisponivel <= 2) {
    return null;
  }

  return (
    <GrupoOpcaoContext.Provider value={{ opcionais, opcionaisDispatcher }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title style={{ fontSize: 18 }} level={4}>
          {descricao}
        </Title>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Paragraph>
            {qtdmultiplomax > 0 && (
              <Tag>
                {quantidade}/{qtdmultiplomax}
              </Tag>
            )}
            {qtdmultiplomin > 0 && !GrupoOk && (
              <Tag color="#cd201f">OBRIGATÓRIO</Tag>
            )}
          </Paragraph>
          {GrupoOk ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: 24 }}
            />
          ) : null}
        </div>
      </div>

      {stprecopraticado_fracionado && (
        <Paragraph>
          <Text style={{ color: '#52c41a' }}>
            {valorGrupoOpcao > 0 && `+ ${obterValorMonetario(valorGrupoOpcao)}`}
          </Text>
        </Paragraph>
      )}

      <ParagraphAjudaQtdOpcao min={qtdmultiplomin} max={qtdmultiplomax} />

      {stprecopraticado_fracionado && (
        <Alert
          message={msgPrecoPraticado}
          type="success"
          style={{ marginBottom: 16 }}
        />
      )}

      {opcionais.map((opcao) => (
        <OpcaoGrupoOpcao
          hasMaximoOpcionais={qtdmultiplomax && qtdmultiplomax === quantidade}
          key={opcao.idopcao}
          opcao={opcao}
          stprecopraticado={stprecopraticado}
          valorAfetaTudo={valorAfetaTudo}
        />
      ))}
      {/* TODO: PODE SER NECESSÁRIO ADICIONAR O AJUSTE */}
      {stprecopraticado === STPRECOPRATICADO.Kit && (
        <span>+{obterValorMonetario(obterValor(produto))}</span>
      )}
    </GrupoOpcaoContext.Provider>
  );
};

export default GrupoOpcao;
