import React from 'react';
import { Card, Col, Tag, Avatar, Typography } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { capitalizeFLetter } from 'utils/normalize';
import { obterValorMonetario } from 'services/precos';
import { STPRECOPRATICADO_KIT } from 'services/precos/constants';

import PrecoProduto from './PrecoProduto';

import './CardapioProduto.css';

const propTypes = {
  produto: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

const { Meta } = Card;

const CardapioProduto = ({ produto, onClick }) => {
  const {
    descricao,
    preco,
    precooferta,
    stindisponivel,
    imagem,
    detalhe
  } = produto;

  if (stindisponivel === 2) {
    return null;
  }

  const possuiGrupoOpcaoKit = produto.grupoopcoes.some(
    (grupoopcao) => grupoopcao.stprecopraticado === STPRECOPRATICADO_KIT
  );

  return (
    <Col xs={24} sm={12} md={8}>
      <Card
        hoverable
        size="small"
        style={{
          marginBottom: 16
        }}
        onClick={() => onClick && onClick(produto)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textOverflow: 'ellipsis'
          }}
        >
          <Meta
            style={{ textOverflow: 'ellipsis' }}
            description={
              !possuiGrupoOpcaoKit ? (
                <PrecoProduto preco={preco} precooferta={precooferta} />
              ) : null
            }
            title={
              <div style={{ overflowWrap: 'break-word' }}>
                {capitalizeFLetter(descricao)}{' '}
                {stindisponivel === 1 && <Tag color="red">INDISPONÍVEL</Tag>}
                <Typography.Paragraph
                  style={{ maxWidth: 300 }}
                  type="secondary"
                  ellipsis={{ rows: 2 }}
                >
                  {detalhe}
                </Typography.Paragraph>
              </div>
            }
          />
          <div>
            <Avatar
              src={
                imagem
                  ? `${
                      process.env.REACT_APP_API_URL_EAR ||
                      'http://localhost:8005'
                    }${imagem}`
                  : null
              }
              icon={<CameraOutlined />}
              size={96}
              shape="square"
              style={{ margin: 0 }}
            />
          </div>
        </div>
      </Card>
    </Col>
  );
};

CardapioProduto.propTypes = propTypes;

export default CardapioProduto;
